import React from "react";
import Styled from "containers/Podcasts/styled";
import { FormattedMessage } from "react-intl";
import PodcastsItem from "containers/Podcasts/Podcasts.Item";

const PodcastsList = ({ playlists }) => {
  return (
    <div>
      <Styled.PlaylistsCaption>
        <FormattedMessage id="Podcasts.Title" />
      </Styled.PlaylistsCaption>
      <Styled.List>
        {playlists.map((playlist) => (
          <PodcastsItem key={playlist?.id} playlist={playlist} />
        ))}
      </Styled.List>
    </div>
  );
};

export default PodcastsList;
