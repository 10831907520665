import styled from "styled-components";

const FormRow = styled.div`
	display: flex;
	margin: 0 -15px;
	@media (max-width: 1023px) {
		 flex-wrap: wrap;
	}
`;
const FormCol50 = styled.div`
	
	width: 100%;
	margin: 0 15px;
	
	@media (min-width: 1024px) {
		 width: 48%; 
		 max-width: 384px;
	}
`;
const FormCol100 = styled.div`
	width: 100%;
	margin: 0 15px;
`;

const FormTxt = styled.div`
	font-size: 15px;
	line-height: 18px;
	color: var(--gray);
	padding-top: 75px;
`;

export default {
	FormCol50,
	FormCol100,
	FormRow,
	FormTxt
};
