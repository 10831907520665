import React from "react";
import { reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";

import InputField from "components/InputField/InputField";
import Button from "components/Button/Button";
import { urlLocations } from "routes/urlLocations";
import { loginAction } from "services/login/login.action";
import CheckboxField from "components/CheckboxField/CheckboxField";
import Styled from "./styled/StyledLogin";
import StyledAuth from "containers/styled/StyledAuth";
import { requiredFieldsValidate } from "utils/validation";

const requiredFields = ["identifier", "password"];

const LoginForm = React.memo(({ handleSubmit }) => {
  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputField
          greenborderfocus
          label="Login.Email"
          name="identifier"
          value="simonkarmy2004@gmail.com"
        />

        <InputField
          greenborderfocus
          label="Login.Password"
          name="password"
          type="password"
        />

        <StyledAuth.CenterLinkWrap>
          <StyledAuth.ForgotLink to={urlLocations.forgotPassword}>
            <FormattedMessage id="Login.ForgotPassword" />
          </StyledAuth.ForgotLink>
        </StyledAuth.CenterLinkWrap>

        <Button htmlType="submit">
          <FormattedMessage id="Login.Button" />
        </Button>

        <Styled.RememberMe>
          <CheckboxField name="rememberMe">
            <FormattedMessage id="Login.RememberMe" />
          </CheckboxField>
        </Styled.RememberMe>

        <StyledAuth.CenterLinkWrap>
          <StyledAuth.AuthLink to={urlLocations.registration}>
            <FormattedMessage id="Login.SignUp" />
          </StyledAuth.AuthLink>
        </StyledAuth.CenterLinkWrap>
      </form>
    </>
  );
});

export default reduxForm({
  form: "loginForm",
  validate: (v) => requiredFieldsValidate(v, requiredFields),
  onSubmit: (value, dispatch) => {
    dispatch(loginAction(value));
  },
})(LoginForm);
