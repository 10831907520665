import React from "react";
import {FormattedMessage} from "react-intl";
import {pathBuilder} from "routes/urlLocations";
import Styled from './styled/Experts';

const ExpertsItem = React.memo(({ dataKey, type }) => {
  return (
    <Styled.Item to={pathBuilder.expert(type)}>
        <Styled.ItemIconCol></Styled.ItemIconCol>
        <Styled.ItemRightCol>
            <Styled.ItemTitle>
                <FormattedMessage id={`Experts.${dataKey}.Title`} />
            </Styled.ItemTitle>
            <Styled.ItemTxt>
                <FormattedMessage id={`Experts.${dataKey}.Desc`} />
            </Styled.ItemTxt>
        </Styled.ItemRightCol>

    </Styled.Item>
  );
});

export default ExpertsItem;
