import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";

import { LOGOUT } from "services/logout/logout.action";
import { history } from "routes/urlLocations";
import initialState from "store/initialState";
import profileReducer from "services/profile/profile.reducer";
import libraryReducer from "services/library/library.reducer";
import auditoriumReducer from "services/auditorium/auditorium.reducer";
import trainTheTrainerReducer from "services/trainTheTrainers/trainTheTrainer.reducer";
import riskReducer from "services/risk/risk.reducer";
import appInfoReducer from "services/appInfo/appInfo.reducer";
import modalsReducer from "services/modals/modals.reducer";
import loaderReducer from "services/loader/loader.reducer";
import favoritesReducer from "services/favorites/favorites.reducer";
import podcastsReducer from "services/podcasts/podcasts.reducer";

const appReducer = combineReducers({
  form: formReducer,
  profile: profileReducer,
  favorites: favoritesReducer,
  modals: modalsReducer,
  library: libraryReducer,
  risk: riskReducer,
  appInfo: appInfoReducer,
  loader: loaderReducer,
  auditorium: auditoriumReducer,
  trainTheTrainer: trainTheTrainerReducer,
  podcasts: podcastsReducer,
  router: connectRouter(history),
});

const rootReducers = (state, action) => {
  if (action.type === LOGOUT) {
    state = initialState;
  }
  return appReducer(state, action);
};

export default rootReducers;
