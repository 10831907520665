export const podcastsPlaylistsSelector = (state) => state.podcasts.playlists;
export const podcastEpisodeSelector = (state) => state.podcasts.episodes;
export const podcastEpisodeProgress = (state) => state.podcasts.progress;
export const playableEpisodeSelector = (state) =>
  state.podcasts.playableEpisode;
export const playablePodcastSelector = (state) =>
  state.podcasts.playablePodcast;
export const isPlayerPlayingSelector = (state) => state.podcasts.isPlaying;
export const playableEpisodeProgressSelector = (state) =>
  state.podcasts.playableEpisodeProgress;
