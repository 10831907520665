import React, {useEffect, useState} from "react";
import {Field} from "redux-form";
import {injectIntl} from "react-intl";
import StyledSelect from "../Select/Select";
import ControlWrapper from "components/ControlWrapper/ControlWrapper";

const CustomSelect = ({
  input: { value, onBlur, name, ...restInput },
  label,
  onSelect,
  children,
  defaultValue,
  formatMessage,
  customTouched,
  placeholder,
  setTouched,
  ...meta
}) => {
  return (
    <ControlWrapper
      label={label}
      name={name}
      customTouched={customTouched}
      error={meta.meta.error}
      touched={meta.meta.touched}
    >
      <StyledSelect
        defaultValue={defaultValue}
        {...meta}
        {...restInput}
        onFocus={setTouched.bind(null, true)}
        onSelect={onSelect}
        {...value && { value }}
      >
        {children}
      </StyledSelect>
    </ControlWrapper>
  );
};

const SelectField = ({
  name,
  children,
  errors,
  intl: { formatMessage },
  placeholder,
  label,
  defaultValue,
  onSelect,
  ...res
}) => {
  const [customTouched, setTouched] = useState(false);
  useEffect(
    () => {
      setTouched(false);
    },
    [errors]
  );
  return (
    <Field
      name={name}
      component={CustomSelect}
      customTouched={customTouched}
      setTouched={setTouched}
      formatMessage={formatMessage}
      label={label}
      defaultValue={defaultValue}
      onSelect={onSelect}
      placeholder={placeholder}
      children={children}
      {...res}
    />
  );
};

export default injectIntl(SelectField);
