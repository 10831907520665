import React from "react";
import {FormattedMessage} from "react-intl";
import Styled from './styled/StyledFooter';
import {useLocation} from "react-router";

const Footer = React.memo(() => {

  const {pathname} = useLocation();
  return (
    <Styled.Footer>
      <Styled.FooterLogo isServier={pathname.includes('podcasts')}></Styled.FooterLogo>
      <Styled.FooterTxt>
        <FormattedMessage
          id={pathname.includes('podcasts') ? "Footer.Desc.Servier" : "Footer.Desc"}/>
      </Styled.FooterTxt>
    </Styled.Footer>
  );
});

export default Footer;
