import React from "react";
import {FormattedMessage} from "react-intl";
import {useLocation} from "react-router";
import StyledAuth from "../styled/StyledAuth";
import AuthFooter from "components/Footer/AuthFooter";
import SetNewPasswordForm from "containers/SetNewPassword/SetNewPassword.Form";

const SetNewPassword = React.memo(() => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const code = query.get("code");

  return (
    <StyledAuth.Page>
        <StyledAuth.Logo to={"/"}/>
      <StyledAuth.Title>
        <FormattedMessage id="NewPassword.Title" />
      </StyledAuth.Title>
      <StyledAuth.Subtitle>
        <FormattedMessage id="NewPassword.Desc" />
      </StyledAuth.Subtitle>
        <StyledAuth.WrapperForm>
            <SetNewPasswordForm
                initialValues={{
                    password: null,
                    passwordConfirmation: null
                }}
                code={code}
            />
        </StyledAuth.WrapperForm>

      <AuthFooter />
    </StyledAuth.Page>
  );
});

export default SetNewPassword;
