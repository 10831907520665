import styled, {css} from "styled-components";
import {Input} from "antd";

const StyledInput = styled(({ ...props }) => (
	<Input autoComplete='off' {...props} />
))`
  && {
	    background-color: var(--bgInput);
	    position: relative;
	    padding: 17px 14px 9px 14px;
	    color: var(--dark);
	    height: 48px;
	    border-radius: 8px;
	    border: 1px solid var(--inputBorder);
	    font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
	    font-stretch: normal;
	    letter-spacing: normal;
	    + label {
	        transition: all 0.2s ease-in;
	    }
	    
	      ${({value}) => {
			if (!!value) {
				return css`
			        & + label {
				        top: 6px !important;
				        font-size: 9px !important;
				    }
		      `;
			}
		}}}
	    &:hover {
	      border: 1px solid inherit;
	    }
	    &:focus,
	    &:active {
	      border: 1px solid ${({greenborderfocus}) => greenborderfocus ? 'var(--inputBorderFocus);' : "var(--gray);"};
	      
	      & + label {
	        top: 6px !important;
	        font-size: 9px !important;
	      }
	    }

	    .ant-input-focused {
	      border: 1px solid ${({greenborderfocus}) => greenborderfocus ? 'var(--gray);' : "var(--inputBorderFocus);"};     
	    }
	
	    &[disabled] {
	      border: 1px solid var(--inputBorder);
	      opacity: 0.9;
	    }
	    .ant-input-prefix {
	      width: 22px;
	      height: 22px;
	      display: flex;
	      justify-content: center;
	      align-items: center;
	      margin-right: 8px;
	    }
	
	    &.ant-input-sm {
	      height: 32px;
	      padding: 8px 16px;
	    }   
	}
`;


export default StyledInput;
