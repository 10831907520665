import React, {useEffect, useState} from "react";
import { FormattedMessage } from "react-intl";

import AuditoriumTabs from "./Auditorium.Tabs";
import AuditoriumContent from "./Auditorium.Content";
import StyledPage from "../styled/StyledPages";
import Search from "components/Search/Search";
import {useLocation} from "react-router";

const Auditorium = React.memo(() => {
  const [searchTerm, setSearchTerm] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
      setSearchTerm("");
  }, [pathname]);

  return (
    <>
      <Search
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value?.toLowerCase())}
      />

      <StyledPage.PageTitle>
        <FormattedMessage id="Auditorium.Title" />
      </StyledPage.PageTitle>

      <AuditoriumTabs />
      <AuditoriumContent searchTerm={searchTerm} />
    </>
  );
});

export default Auditorium;
