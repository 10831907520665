import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckOutlined,
  CaretRightOutlined,
  PauseOutlined,
} from "@ant-design/icons";

import MicrophoneImg from "assets/images/microphone.png";

import {
  playPlayerStatusAction,
  setPlayableEpisodeAction,
  setPlayablePodcastAction,
} from "services/podcasts/podcasts.actions";
import {
  isPlayerPlayingSelector,
  playableEpisodeProgressSelector,
  playableEpisodeSelector,
  podcastEpisodeProgress,
  podcastEpisodeSelector,
} from "services/podcasts/podcasts.selectors";
import { localizeText } from "utils/lang";
import Styled from "./styled";
import moment from "moment";
import { Progress } from "antd";

const PodcastEpisode = ({ episode, index }) => {
  const dispatch = useDispatch();
  const podcast = useSelector(podcastEpisodeSelector);
  const playableEpisode = useSelector(playableEpisodeSelector);
  const isPlaying = useSelector(isPlayerPlayingSelector);
  const progress = useSelector(podcastEpisodeProgress);
  const playableEpisodeProgress = useSelector(playableEpisodeProgressSelector);

  const isCurrentEpisodePlayable = playableEpisode?.id === episode?.id;

  const secondsDuration = moment
    .duration(`00:${episode.duration}`, "minutes")
    .asSeconds();

  const progressPercent = secondsDuration / 100;

  const episodeProgress = isCurrentEpisodePlayable
    ? playableEpisodeProgress
    : progress?.[episode?.id]?.progressSeconds / progressPercent;

  const secondsLeft = (100 - episodeProgress) * progressPercent || 0;

  const isLeftLessThenMinute = Math.floor(secondsLeft) / 60 < 1;

  return (
    <Styled.Episode>
      <Styled.EpisodeHeader>
        <Styled.EpisodeCount>
          {index + 1} <FormattedMessage id={"Podcasts.Episode"} />
        </Styled.EpisodeCount>
        {" • "}
        <Styled.EpisodeDuration>
          {progress?.[episode?.id]?.finished && (
            <>
              <FormattedMessage id={"Podcasts.Listened"} />
              <Styled.Checked>
                <CheckOutlined />
              </Styled.Checked>
            </>
          )}
          {!progress?.[episode?.id] && (
            <>
              {episode.duration} <FormattedMessage id={"Podcasts.Min"} />
            </>
          )}
          {progress?.[episode?.id] && !isLeftLessThenMinute && (
            <div>
              {moment.utc(Math.floor(secondsLeft) * 1000).format("m")}{" "}
              <FormattedMessage id={"Podcasts.MinLeft"} />
            </div>
          )}
          {progress?.[episode?.id] && isLeftLessThenMinute && (
            <>
              {moment.utc(Math.floor(secondsLeft) * 1000).format("s")}{" "}
              <FormattedMessage id={"Podcasts.SecLeft"} />
            </>
          )}
          {progress?.[episode?.id] && (
            <div>
              <Progress
                percent={episodeProgress}
                showInfo={false}
                size="small"
              />
            </div>
          )}
        </Styled.EpisodeDuration>
      </Styled.EpisodeHeader>
      <Styled.EpisodeBody>
        <Styled.EpisodeCover>
          {isCurrentEpisodePlayable && isPlaying ? (
            <Styled.ActionIcon>
              <PauseOutlined
                onClick={() => dispatch(playPlayerStatusAction(false))}
              />
            </Styled.ActionIcon>
          ) : (
            <Styled.ActionIcon>
              <CaretRightOutlined
                onClick={() => {
                  dispatch(setPlayableEpisodeAction(episode));
                  dispatch(setPlayablePodcastAction(podcast));
                  dispatch(playPlayerStatusAction(true));
                }}
              />
            </Styled.ActionIcon>
          )}
          <img src={MicrophoneImg} alt="" />
        </Styled.EpisodeCover>
        <Styled.EpisodeTitleTxt>
          <Styled.EpisodeTitle isPlaying={playableEpisode?.id === episode?.id}>
            {localizeText(episode?.title)}
          </Styled.EpisodeTitle>
          <Styled.EpisodeAuthor>
            {localizeText(episode?.speaker)}
          </Styled.EpisodeAuthor>
        </Styled.EpisodeTitleTxt>
      </Styled.EpisodeBody>
      <Styled.EpisodeDesc>
        {localizeText(episode?.description)}
      </Styled.EpisodeDesc>
    </Styled.Episode>
  );
};

export default PodcastEpisode;
