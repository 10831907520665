import React from "react";

import PodcastEpisode from "containers/Podcast/Podcast.Episode";
import Styled from "./styled";
import { FormattedMessage } from "react-intl";

const PodcastEpisodes = ({ episodes }) => {
  return (
    <Styled.Episodes>
      <Styled.Caption>
        <FormattedMessage id="Podcasts.AllEpisodes" />
      </Styled.Caption>
      <div>
        {episodes?.map((episode, index) => (
          <PodcastEpisode index={index} key={episode?.id} episode={episode} />
        ))}
      </div>
    </Styled.Episodes>
  );
};

export default PodcastEpisodes;
