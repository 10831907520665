import styled, { css } from "styled-components";

const LibraryPage = styled.div`
  width: 100%;
`;

const Tabs = styled.ul`
  display: flex;
  list-style: none;
  width: 300px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #1b728b;
  padding: 0;
  overflow: hidden;
`;

const TabItem = styled.li`
  display: inline-flex;
  width: 150px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  height: 32px;
  cursor: pointer;
  &:first-child {
    border-right: 1px solid #1b728b;
  }
  &:last-child {
  }

  a {
    color: #1b728b;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  ${({ isactive }) => {
    if (isactive) {
      return css`
        background: var(--gradientAside);
        color: #fff;
        a {
          color: #fff;
          &:hover {
            color: inherit;
          }
        }
      `;
    }
  }};
`;

const ListBlock = styled.div`
  width: 100%;
  @media (max-width: 767px) {
  }
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 920px) {
    flex-wrap: wrap;
  }
  @media (min-width: 921px) {
    margin: 0 -15px;
  }

  @media (min-width: 1024px) {
    margin: 0 -10px;
  }
  @media (min-width: 1440px) {
    margin: 0 -15px;
  }
  @media (min-width: 1430px) {
    width: 1080px;
  }
`;

const ListOfVideos = styled.div`
  width: 100%;
`;

const ListItem = styled.div`
  display: inline-flex;
  width: 100%;
  margin-bottom: 20px;
  @media (min-width: 920px) {
    width: 100%;
  }
  @media (min-width: 921px) {
    width: 43%;
    margin: 0 10px 20px 10px;
  }
  @media (min-width: 1340px) {
    width: 28%;
    margin: 0 15px 20px 15px;
  }

  @media (min-width: 1440px) {
    width: 328px;
    margin: 0 15px 20px 15px;
  }
`;

const ListTitle = styled.h4`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #1b728b;
  padding: 22px 0 20px 0;
  cursor: pointer;
`;

export default {
  ListTitle,
  LibraryPage,
  ListBlock,
  List,
  ListItem,
  Tabs,
  TabItem,
  ListOfVideos
};
