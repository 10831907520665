import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {ConnectedRouter} from "connected-react-router";
import {IntlProvider} from "react-intl";

import Routes from "routes/routes";
import store from "store";
import {history} from "routes/urlLocations";
import { APPDictionary } from 'locales';

import Loader from "components/Loader/Loader";
import "./index.css";



let lang = localStorage.getItem("lang");
if (!lang) {
  localStorage.setItem("lang", "en");
  lang = localStorage.getItem("lang");
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <IntlProvider locale={lang} messages={APPDictionary[lang]}>
        <Loader>
          <Routes />
        </Loader>
      </IntlProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
