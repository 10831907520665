import React from "react";
import {Link} from "react-router-dom";
import {useParams} from "react-router";

import {TABS} from "./Favorites.constants";
import {FormattedMessage} from "react-intl";
import {pathBuilder} from "routes/urlLocations";
import Styled from './styled/StyledFavorites';

const FavoritesTabs = React.memo(() => {
  const { section } = useParams();
  return (
    <div>
      <Styled.Tabs>
        {TABS.map(tab => (
          <Styled.TabItem
              isactive={section === tab.type}
            key={tab.type}
          >
            <Link to={pathBuilder.favorites(tab.type)}>
              <FormattedMessage id={`Favorites.Tabs.${tab.title}`} />
            </Link>
          </Styled.TabItem>
        ))}
      </Styled.Tabs>
    </div>
  );
});

export default FavoritesTabs;
