import { call, put, takeEvery } from "redux-saga/effects";

import * as registrationActions from "./registration.action";
import { registrationRequest } from "./registration.api";
import { history, urlLocations } from "routes/urlLocations";
import {
  finishLoaderAction,
  startLoaderAction,
} from "services/loader/loader.action";
import * as profileActions from "services/profile/profile.actions";
import openNotification, { prepareErrorMessages } from "utils/notifications";
import instance from "services/root.api";
import { STORAGE_TYPE } from "constants/constants";

function* registration({ payload }) {
  try {
    yield put(startLoaderAction());
    const lowerCaseEmail = payload.email?.trim()?.toLowerCase() || "";
    const {
      response: { data },
    } = yield call(registrationRequest, {
      ...payload,
      email: lowerCaseEmail,
    });

    const storage =
      localStorage.getItem("storageTyp") || STORAGE_TYPE.SESSION_STORAGE;
    !!data?.jwt && window[storage].setItem("jwt", data?.jwt);
    instance.defaults.headers.common["Authorization"] = `Bearer ${data.jwt}`;
    yield put({
      type: profileActions.GET_PROFILE_DATA_SUCCESS,
      payload: data.user,
    });
    yield put({
      type: registrationActions.REGISTRATION_SUCCESS,
    });
    yield history.push(urlLocations.dashboard);
    yield call(openNotification, {
      message: "Registration.Success.Title",
      description: "Registration.Success.Desc",
      type: "success",
    });
  } catch ({ response }) {
    yield put({ type: registrationActions.REGISTRATION_FAILURE });
    yield call(openNotification, {
      message: "Notifications.Error",
      description: prepareErrorMessages(response.data),
      type: "error",
    });
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* registrationSaga() {
  yield takeEvery(registrationActions.REGISTRATION, registration);
}
