import React from "react";
import {FormattedMessage} from "react-intl";
import RegistrationForm from "./Registration.Form";
import StyledAuth from "../styled/StyledAuth";
import AuthFooter from "components/Footer/AuthFooter";

const Registration = React.memo(() => {
  return (
    <StyledAuth.Page>
      <StyledAuth.Logo to={"/"}/>

      <StyledAuth.Title>
        <FormattedMessage id="Registration.Title" />
      </StyledAuth.Title>

      <StyledAuth.Subtitle>
        <FormattedMessage id="Registration.Desc" />
      </StyledAuth.Subtitle>

        <StyledAuth.WrapperForm width="928px" height="480px">
            <RegistrationForm
                initialValues={{
                    email: null,
                    password: null,
                    speciality: null,
                    country: null,
                    mobileNumber: null,
                    fullName: null,
                    confirmPassword: null,
                    accept: null,
                    placeOfWork: null,
                    city: null
                }}
            />
        </StyledAuth.WrapperForm>

      <AuthFooter type="big" />
    </StyledAuth.Page>
  );
});

export default Registration;
