import styled, {css} from "styled-components";
import Icon from 'assets/images/Star.svg';
import IconFilled from 'assets/images/filledStar.svg';

const FavoriteIcon = styled.i`
	position: absolute;
	right: 25px;
	top: 25px;
	width: 22px;
	height: 21px;
	background: url(${Icon}) 0 0 no-repeat;
	background-size: cover;
	cursor: pointer;
	z-index: 2;
	
	  ${({typetrain}) => {
			if(typetrain) {
				return css`
		                 	right: 11px;
		                 	top: 11px; 
		              `
			}
	}};
`;

const FavoriteIconFiled = styled(FavoriteIcon)`
	background: url(${IconFilled}) 0 0 no-repeat;
	background-size: cover;
`;

export default {
	FavoriteIcon,
	FavoriteIconFiled
};
