import React from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

import ProfileForm from "./Profile.Form";
import {profileSelector} from "services/profile/profile.selectors";

import ProfileModal from "./Profile.Modal";
import Styled from './styled/StyledProfile';

const Profile = React.memo(() => {
  const profile = useSelector(profileSelector);
  const dispatch = useDispatch();
  return (
    <Styled.Profile>
      <Styled.Title>
        <FormattedMessage id="Profile.Title" />
      </Styled.Title>

      <ProfileForm
        initialValues={{
          ...profile,
          speciality: profile?.speciality?.id,
          country: profile?.country?.id
        }}
      />



      <ProfileModal />

    </Styled.Profile>
  );
});

export default Profile;
