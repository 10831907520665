export const API_PATH = "https://api.daralliancehcp.org";

export const STORAGE_TYPE = {
  LOCAL_STORAGE: "localStorage",
  SESSION_STORAGE: "sessionStorage",
};

export const PLAYER = {
  EPISODE_ID: "EPISODE_ID",
  PODCAST_ID: "PODCAST_ID",
};
