import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";

import {FormattedMessage} from "react-intl";

import QuizOptions from "containers/Quiz/Quiz.Options";
import {pathBuilder} from "routes/urlLocations";
import * as trainTheTrainerSelectors from "services/trainTheTrainers/trainTheTrainer.selectors";
import {submitAnswerAction} from "services/trainTheTrainers/trainTheTrainer.actions";
import {localizeText} from "utils/lang";
import StyledPages from "containers/styled/StyledPages";
import Styled from "./styled/StyledQuiz";

const Quiz = React.memo(() => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const course = useSelector(state =>
    trainTheTrainerSelectors.courseSelector(state, id)
  );

  const questions = course?.exam?.questions;

  const [step, setStep] = useState(0);
  const [answers, setAnswer] = useState({});

  const onOptionSelect = useCallback(
    value => {
      setAnswer({ ...answers, [step]: value });
      if (questions?.length - 1 !== step) {
        setStep(step + 1);
      } else {
        dispatch(submitAnswerAction({ answers, id: course?.exam?.id }));
      }
    },
    [answers, step]
  );

  return (
    <div>
      <StyledPages.BackBtn to={pathBuilder.course(course?.id)}>
        <FormattedMessage id="TrainTheTrainer.Back" />
      </StyledPages.BackBtn>

      <Styled.QuizContent>
        <Styled.QuizHeader>
          <Styled.QuestionNumber>
            <FormattedMessage
              id="TrainTheTrainer.QuestionsCount"
              values={{ current: step + 1, count: questions?.length }}
            />
          </Styled.QuestionNumber>
          <Styled.QuizTitle>
            {localizeText(questions?.[step]?.title)}
          </Styled.QuizTitle>
        </Styled.QuizHeader>
        <QuizOptions
          onSkip={onOptionSelect.bind(null, "")}
          onOptionSelect={onOptionSelect}
          options={questions?.[step]?.options}
        />
      </Styled.QuizContent>
    </div>
  );
});

export default Quiz;
