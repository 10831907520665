import React from "react";
import { history, urlLocations } from "routes/urlLocations";
import { STORAGE_TYPE } from "constants/constants";

const Main = ({ children }) => {
  React.useEffect(() => {
    const storage =
      localStorage.getItem("storageTyp") || STORAGE_TYPE.LOCAL_STORAGE;
    const jwt = window[storage]?.getItem("jwt");
    jwt && history.push(urlLocations.dashboard);
  }, []);
  return <div>{children}</div>;
};

export default Main;
