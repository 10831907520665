import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import FavoriteIcon from "components/FavoriteIcon/FavoriteIcon";
import AuditoriumModal from "./Auditorium.Modal";
import { localizeText } from "utils/lang";
import { FAVORITES_TYPES } from "constants/favorites";
import { setModalStatusAction } from "services/modals/modals.action";
import { MODALS_ID } from "constants/modals";
import Styled from "./styled/StyledItem";

const AuditoriumVideo = React.memo(
  ({ title, speaker, thumbnail, id, video, youtubeVideoURL }) => {
    const [modalVideo, setVideo] = useState(null);
    const dispatch = useDispatch();

    return (
      <Styled.Item>
        <Styled.VideoContainer>
          <img
            src={thumbnail?.url}
            alt=""
            width={250}
            onClick={() => {
              setVideo({
                url: video || youtubeVideoURL,
                type: video ? "common" : "youtube",
              });
              dispatch(setModalStatusAction(`${MODALS_ID.VIDEO_MODAL}_${id}`));
            }}
          />
        </Styled.VideoContainer>
        <Styled.ItemTitle>
          {localizeText(title)} (<FormattedMessage id="Auditorium.By" />{" "}
          {localizeText(speaker)})
        </Styled.ItemTitle>

        <FavoriteIcon type={FAVORITES_TYPES.VIDEO} id={id} />

        <AuditoriumModal video={modalVideo} id={id} />
      </Styled.Item>
    );
  }
);

export default AuditoriumVideo;
