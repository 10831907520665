import React from "react";
import {FormattedMessage} from "react-intl";

import {localizeText} from "utils/lang";
import {pathBuilder} from "routes/urlLocations";
import FavoriteIcon from "components/FavoriteIcon/FavoriteIcon";
import {FAVORITES_TYPES} from "constants/favorites";
import Styled from './styled/StyledItem';

const TrainTheTrainersCourse = React.memo(
  ({ title, instructor, banner, id, paddingNone }) => {
    return (
      <Styled.Item paddingNone={paddingNone}>
          <Styled.ItemLeftCol>
              <img src={banner?.[0]?.url} alt="" width={150} />
          </Styled.ItemLeftCol>

          <Styled.ItemRightCol>
              <FavoriteIcon typetrain id={id} type={FAVORITES_TYPES.COURSES} />
              <Styled.ItemTitle>
                  {localizeText(title)}
              </Styled.ItemTitle>
              <Styled.AuthorTxt>
                  <FormattedMessage id="TrainTheTrainer.Author" /> :{" "}
                  {localizeText(instructor)}
              </Styled.AuthorTxt>
              <Styled.More to={pathBuilder.course(id)}>
                  <FormattedMessage id="TrainTheTrainer.Explore" />
              </Styled.More>
          </Styled.ItemRightCol>
      </Styled.Item>
    );
  }
);

export default TrainTheTrainersCourse;
