import { call, put, takeEvery } from "redux-saga/effects";
import * as passwordActions from "./password.action";
import {
  changePasswordRequest,
  forgotPasswordRequest,
  resetPasswordRequest
} from "./password.api";
import * as modalActions from "services/modals/modals.action";
import { history, urlLocations } from "routes/urlLocations";
import {
  finishLoaderAction,
  startLoaderAction
} from "services/loader/loader.action";
import openNotification, { prepareErrorMessages } from "utils/notifications";

function* forgotPassword({ payload: data }) {
  try {
    yield put(startLoaderAction());

    yield forgotPasswordRequest(data);
    yield put({
      type: passwordActions.FORGOT_PASSWORD_SUCCESS
    });
    yield call(openNotification, {
      message: "ForgotPassword.Success.Title",
      description: "ForgotPassword.Success.Desc",
      type: "success"
    });
  } catch ({ response }) {
    yield put({ type: passwordActions.FORGOT_PASSWORD_FAILURE });
    yield call(openNotification, {
      message: "Notifications.Error",
      description: prepareErrorMessages(response.data),
      type: "error"
    });
  } finally {
    yield put(finishLoaderAction());
  }
}

function* resetPassword({ payload: data }) {
  try {
    yield put(startLoaderAction());
    yield resetPasswordRequest(data);
    yield put({
      type: passwordActions.RESET_PASSWORD_SUCCESS
    });
    yield history.push(urlLocations.login);
    yield call(openNotification, {
      message: "SetNewPassword.Success.Title",
      description: "SetNewPassword.Success.Desc",
      type: "success"
    });
  } catch (error) {
    yield put({
      type: passwordActions.RESET_PASSWORD_FAILURE
    });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* changePassword({ payload: data }) {
  try {
    yield put(startLoaderAction());
    yield changePasswordRequest(data);
    yield put({
      type: passwordActions.CHANGE_PASSWORD_SUCCESS
    });
    yield put({
      type: modalActions.SET_MODAL_STATUS,
      payload: null
    });
    yield call(openNotification, {
      message: "ChangePassword.Success.Title",
      description: "ChangePassword.Success.Desc",
      type: "success"
    });
  } catch ({ response }) {
    yield put({
      type: passwordActions.CHANGE_PASSWORD_FAILURE
    });
    yield call(openNotification, {
      message: "Notifications.Error",
      description: prepareErrorMessages(response.data),
      type: "error"
    });
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* passwordSaga() {
  yield takeEvery(passwordActions.FORGOT_PASSWORD, forgotPassword);
  yield takeEvery(passwordActions.RESET_PASSWORD, resetPassword);
  yield takeEvery(passwordActions.CHANGE_PASSWORD, changePassword);
}
