import React from "react";
import {localizeText} from "utils/lang";
import {FormattedMessage} from "react-intl";
import Styled from "./styled/StyledItem";

import {FAVORITES_TYPES} from "constants/favorites";
import FavoriteIcon from "components/FavoriteIcon/FavoriteIcon";

const LibraryItem = ({ id, title, thumbnail, author, itemFile }) => {
  return (
    <Styled.Item key={id} href={itemFile?.url} target="__blank">
        <Styled.ItemLeftCol>
            <img width={50} src={thumbnail.url} alt="" />
        </Styled.ItemLeftCol>
      <FavoriteIcon id={id} type={FAVORITES_TYPES.LIBRARY} />

      <Styled.ItemRightCol>
          <Styled.ItemTitle>{localizeText(title)}</Styled.ItemTitle>
          <Styled.AuthorTxt>
              <FormattedMessage id="Library.Author" />:{" "}
              <span>{localizeText(author)}</span>
          </Styled.AuthorTxt>
          <Styled.More >
              <FormattedMessage id="Library.Explore" />
          </Styled.More>
      </Styled.ItemRightCol>

    </Styled.Item>
  );
};

export default LibraryItem;
