import { urlLocations } from "routes/urlLocations";

export const TABS = [
  {
    title: "Auditorium.Tabs.Videos",
    path: urlLocations.auditoriumVideos
  },
  {
    title: "Auditorium.Tabs.Lectures",
    path: urlLocations.auditoriumLectures
  }
];
