import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const urlLocations = {
  login: "/",
  registration: "/registration",
  forgotPassword: "/forgot-password",
  dashboard: "/dashboard",
  library: "/library",
  auditorium: "/auditorium/:type?",
  auditoriumVideos: "/auditorium/videos",
  auditoriumLectures: "/auditorium/lectures",
  experts: "/experts",
  expert: "/expert",
  expertInfo: "/expert/:section?",
  train: "/train-the-trainer",
  course: "/course",
  quiz: "/quiz",
  quizResult: "/quiz-result",
  quizInfo: "/quiz/:id",
  courseInfo: "/course/:id",
  lesson: "/lesson",
  setNewPassword: "/set-new-password",
  lessonInfo: "/lesson/:courseId/:lessonId",
  risk: "/risk",
  riskTest: "/risk-test",
  publicRisk: "/public/risk",
  publicRiskTest: "/public/risk-test",
  about: "/about",
  help: "/help",
  profile: "/profile",
  termsOfUse: "/terms-of-use",
  favorites: "/favorites",
  privacyPolicy: "/privacy-policy",
  favoritesSection: "/favorites/:section",
  podcasts: "/podcasts",
  podcastsInfo: "/podcasts/:folder",
  topPodcasts: "/podcasts-top",
  podcastInfo: "/podcasts/:folder",
  podcastEpisodes: "/podcasts/:id/episodes",
  podcastsFolders: "/podcasts-folders",
};

export const pathBuilder = {
  expert: (type) => `${urlLocations.expert}/${type}`,
  podcast: (id) => `${urlLocations.podcasts}/${id}/episodes`,
  podcasts: (folder) => `${urlLocations.podcasts}/${folder}`,
  course: (id) => `${urlLocations.course}/${id}`,
  quiz: (id) => `${urlLocations.quiz}/${id}`,
  favorites: (section) => `${urlLocations.favorites}/${section}`,
  lesson: (courseId, lessonId) =>
    `${urlLocations.lesson}/${courseId}/${lessonId}`,
};
