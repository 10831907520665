import {delay, put, takeEvery, takeLatest} from "redux-saga/effects";
import {FINISH_LOADER, SET_LOADER_STATUS, START_LOADER,} from "./loader.action";

function* startLoader() {
    yield put({type: SET_LOADER_STATUS, payload: true});
}

function* finishLoader() {
    yield delay(300);
    yield put({type: SET_LOADER_STATUS, payload: false});
}

export default function* loaderSaga() {
    yield takeEvery(START_LOADER, startLoader);
    yield takeLatest(FINISH_LOADER, finishLoader);
}
