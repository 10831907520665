import * as libraryActions from "./library.actions";
import initialState from "store/initialState";

export default (state = initialState.profile, action) => {
    switch (action.type) {
        case libraryActions.GET_LIBRARY_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};
