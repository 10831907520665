import styled, {css} from "styled-components";

const QuizHeader = styled.div`
	padding: 0 64px 0 64px;
	display: flex;
	flex-direction: column;
`;

const AnswersList = styled.ul`
	list-style: none;
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	margin: 0 0 48px 0;
	padding: 0;
	justify-content: space-between ;
	
`;
const AnswerItem = styled.li`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 380px;
	height: 60px;
	background: #FFFFFF;
	border: 1px solid #4283C1;
	box-sizing: border-box;
	border-radius: 10px;
	color: var(--dark);
	margin: 8px 0;
	cursor: pointer;
	
	@media (min-width: 1024px) {
		 width: 48%; 
	}
	&:hover {
		background: var(--gradientAside); 
	    color: #fff;
	}
	
	${({isactive}) => {
	if(isactive && isactive === 'true') {
		return css`
	               background: var(--gradientAside); 
	               color: #fff; 
	                `
	}
}}
`;

const QuizContent = styled.div`
	width: 100%;
	max-width: 928px;
	position: relative;
	min-height: 400px;
	background: #FFFFFF;
	box-shadow: 0px 5px 10px rgba(127, 141, 163, 0.1);
	border-radius: 8px;
	padding: 32px 0 80px 0;
`;

const QuizItem = styled.div`
	width: 100%;
	padding: 0 64px;
`;

const QuizFooter = styled.div`
	height: 80px;
	width: 100%;
	position: absolute;
	bottom: 0;
	left:0;
	
	display: flex;
	justify-content: flex-end;
	border-radius: 0 0 8px 8px;
	padding: 30px 64px 0 64px;
	border-top: 1px solid var(--lightGray);
`;

const QuestionNumber = styled.p`
	color: var(--gray);
	font-size: 15px;
	margin: 5px 0 15px 0;
`;

const QuizTitle = styled.h2`
	color: var(--dark);
	margin: 5px 0 0 0;
`;

const SkipButton = styled.span`
	font-weight: 500;
	font-size: 15px;
	cursor: pointer;
	left: -15px;
	line-height: 18px;
	color: var(--dark);
	display: inline-block;
	padding-right: 20px;
	margin-bottom: 20px;
	position: relative;
	
	&:after {
		content:'';
		position: relative;
		top: 0px;
		right: -23px;
		border: solid var(--gray);
		border-width: 0 1px 1px 0;
		display: inline-block;
		padding: 4px;
		transform: rotate(-45deg);	
		cursor: pointer;
	}
`;


export default {
	QuizHeader,
	QuestionNumber,
	AnswerItem,
	QuizFooter,
	QuizContent,
	QuizTitle,
	AnswersList,
	SkipButton,
	QuizItem
};
