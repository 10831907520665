import styled from "styled-components";
import { Slider } from "antd";

import { SoundOutlined, RetweetOutlined } from "@ant-design/icons";

const Player = styled.div`
  padding: 16px 55px;
  @media (min-width: 768px) and (max-width: 1050px) {
    padding: 16px 20px;
  }
  @media (max-width: 767px) {
    padding: 15px 16px 5px 16px;
  }
  background: #ffffff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 118px;
  @media (max-width: 767px) {
    height: 175px;
  }

`;

const PlayerTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  
  @media (max-width: 767px) {
    position: absolute;
    right: 16px;
    top: 29px;
  }
`;

const PlayerWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  & > * {
    flex: 1;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .ant-spin {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -8px;
    margin-top: -8px;
  }
`;

const PlayerCover = styled.div`
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 8px;
  margin-right: 8px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
    img {
      object-fit: cover;
    }
  }
`;

const PlayerTitle = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: calc(100% - 64px);
  @media (max-width: 767px) {
    //width: calc(100% - 200px);
  }
`;

const EpisodeInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (min-width: 768px) and (max-width: 1999px) {
    flex: 0 1 auto;
    max-width: 320px;
  }

  @media (max-width: 767px) {
    order: 1;
    margin-bottom: 5px;
  }
`;

const EpisodeTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #424e6f;
  width: 100%;
  
  @media (min-width: 768px) {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    max-height: 46px;
  }
  @media (max-width: 767px) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const EpisodeAuthor = styled.div`
  font-size: 12px;
  color: #9a9fad;
  width: 100%;
  @media (max-width: 767px) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
`;

const PlayerControlsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    order:2;
    padding-top: 12px;
  }
`;

const PlayerControls = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  img,
  .anticon {
    margin: 0 15px;
    cursor: pointer;
    color: #9a9fad;
    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
  .anticon-step-backward,
  .anticon-step-forward {
    font-size: 25px;
  }
  @media (max-width: 767px) {
    order: 3;
    margin-bottom: 7px;
  }
`;


const Play = styled.div`
  cursor: pointer;
  display: flex;
  width: 32px;
  height: 32px;
  background: linear-gradient(267.93deg, #1b728b 13.19%, #07546d 80.29%);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  .anticon {
    color: #ffffff;
  }
`;

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: #9a9fad;
  align-items: center;
  div:last-child {
    top: -2px;
    position: relative;
  }
  @media (max-width: 767px) {
    order: 1;
    padding-bottom: 10px;
    & > div:first-child {
      width: 38px;
    }
    & > div:last-child {
      width: 38px;
    }
  }
  
`;

const StyledSlider = styled(Slider)`
  width: 300px;
  @media (max-width: 480px) {
    width: 200px
  }
  margin: 0 20px;
  .ant-slider-track {
    background: #2189a7;
  }
  .ant-slider-rail {
    background: #d2d8df;
  }
  .ant-slider-handle {
    background: #2189a7;
    border-color: #2189a7;
  }
  &&&:hover .ant-slider-track {
    background: #2189a7;
    .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: #2189a7;
    }
  }
`;

const Volume = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  .anticon {
    margin-right: 10px;
    color: #424e6f;
  }
  @media (min-width: 1200px) {
    max-width: 320px;
  }
  @media (max-width: 767px) {
    order: 2;
    width: 100%;
    max-width: 398px;
    justify-content: center;
    display: inline-flex;
    margin: 0 auto;
    & > div:last-child {
      width: 38px;
    }
  }
 
`;

const VolumeSlider = styled(Slider)`
  width: 80px;
  @media (max-width: 767px) {
    width: 100%;
  }
  .ant-slider-track {
    background: #2189a7;
  }
  .ant-slider-rail {
    background: #d2d8df;
  }
  .ant-slider-handle {
    background: #2189a7;
    border-color: #2189a7;
  }
  &&&:hover .ant-slider-track {
    background: #2189a7;
    .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: #2189a7;
    }
  }
`;

const VolumeIcon = styled(SoundOutlined)`
  color: ${({ isMuted }) => (isMuted ? "#9a9fad" : "#2189a7")} !important;
  width: 38px;
`;

const CircleIcon = styled(RetweetOutlined)`
  color: ${({ isCircle }) => (isCircle ? "#2189a7" : "#9a9fad")} !important;
`;

export default {
  Player,
  PlayerWrapper,
  PlayerCover,
  EpisodeInfo,
  EpisodeTitle,
  EpisodeAuthor,
  PlayerControls,
  Play,
  PlayerControlsWrapper,
  SliderWrapper,
  Slider: StyledSlider,
  Volume,
  VolumeSlider,
  PlayerTop,
  VolumeIcon,
  CircleIcon,
  PlayerTitle
};
