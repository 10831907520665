import React from "react";

import Input from "components/InputSearch/Input";
import Styled from "./styled/StyledSearch";
import StyledSearchIcon from "components/Search/styled/StyledSearchIcon";

const Search = React.memo(({ onChange, value }) => {

  return (
    <Styled.SearchBlock>
      <Input
        placeholder="Search"
        onChange={onChange}
        value={value}
        prefix={<StyledSearchIcon />}
      />
    </Styled.SearchBlock>
  );
});

export default Search;
