import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isPlayerPlayingSelector,
  playableEpisodeProgressSelector,
  playableEpisodeSelector,
  playablePodcastSelector,
  podcastEpisodeProgress,
} from "services/podcasts/podcasts.selectors";
import {
  getPlayableEpisodeAction,
  playPlayerStatusAction,
  setPlayableEpisodeAction,
  setPlayableEpisodeProgressAction,
  setPlayerStatusAction,
  updateEpisodeProgressAction,
} from "services/podcasts/podcasts.actions";
import { PLAYER } from "constants/constants";

const PlayerHooks = () => {
  const dispatch = useDispatch();
  const playableEpisode = useSelector(playableEpisodeSelector);
  const playablePodcast = useSelector(playablePodcastSelector);
  const episodesProgress = useSelector(podcastEpisodeProgress);
  const progress = useSelector(playableEpisodeProgressSelector);
  const isPlay = useSelector(isPlayerPlayingSelector);

  const [audioRef, setAudioRef] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isMuted, setMuteStatus] = useState(false);
  const [isCircle, setCircle] = useState(false);
  const [loop, setLoop] = useState();

  const progressPercent = audioRef?.current?.duration / 100;

  const setProgress = (p) => {
    dispatch(setPlayableEpisodeProgressAction(p));
  };

  const updateProgress = () => {
    dispatch(
      updateEpisodeProgressAction({
        progressSeconds: Math.floor(audioRef?.current?.currentTime) || 0,
        id: playableEpisode?.id,
      })
    );
  };

  const episodeIndex = playablePodcast?.episodes?.findIndex(
    (episode) => episode?.id === playableEpisode?.id
  );

  useEffect(() => {
    setLoaded(false);
    if (audioRef?.current && playableEpisode) {
      audioRef.current.load();
      audioRef.current.onloadeddata = () => {
        isPlay && audioRef.current.play();
        setLoaded(true);
      };
    }
  }, [playableEpisode]);

  useEffect(() => {
    !isPlay ? audioRef?.current?.pause() : audioRef?.current?.play();
  }, [isPlay]);

  useEffect(() => {
    if (isPlay) {
      setTimeout(() => {
        const currentProgress =
          audioRef?.current?.currentTime / progressPercent;
        setProgress(currentProgress);
      }, 1000);
    }
  }, [isPlay, progress]);

  useEffect(() => {
    clearInterval(loop);
    if (isPlay && isLoaded) {
      setLoop(setInterval(updateProgress, 5000));
      return;
    }
    clearInterval(loop);
    return () => {
      clearInterval(loop);
    };
  }, [isPlay, playableEpisode]);

  useEffect(() => {
    if (audioRef?.current) {
      audioRef.current.onloadeddata = () => {
        setLoaded(true);
        isPlay && onPlay();
      };
    }
  }, [audioRef]);

  const onProgressChange = (v) => {
    const value = v * progressPercent || 0;
    audioRef.current.currentTime = value;

    setProgress(v);
  };

  const onRefChange = (node) => {
    if (node && !audioRef) {
      setAudioRef({ current: node });
    }
  };

  const currentTime = audioRef?.current?.currentTime;

  const setData = () => {
    const savedProgress = episodesProgress?.[playableEpisode?.id] || 0;
    const pp = audioRef?.current?.duration / 100;
    const isStartFromTheBegin = savedProgress?.progressSeconds / pp >= 90;
    const p =
      isStartFromTheBegin && savedProgress?.finished
        ? 0
        : savedProgress?.progressSeconds / pp;
    setProgress(p);
    if (audioRef.current) {
      audioRef.current.currentTime = p * pp || 0;
    }
  };

  const onPlay = () => {
    audioRef?.current?.play();
    dispatch(setPlayerStatusAction(true));
  };

  const onPause = () => {
    audioRef?.current?.pause();
    dispatch(setPlayerStatusAction(false));
  };

  const clearLocalStorageData = () => {
    localStorage.setItem(PLAYER.EPISODE_ID, "");
    localStorage.setItem(PLAYER.PODCAST_ID, "");
  };

  useEffect(() => {
    if (audioRef?.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    const episodeId = localStorage.getItem(PLAYER.EPISODE_ID);
    const podcastId = localStorage.getItem(PLAYER.PODCAST_ID);

    if (!!episodeId && !!podcastId && !playableEpisode) {
      dispatch(
        getPlayableEpisodeAction({
          podcastId,
          episodeId,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (playableEpisode) {
      localStorage.setItem(PLAYER.EPISODE_ID, playableEpisode.id);
      localStorage.setItem(PLAYER.PODCAST_ID, playableEpisode.playlist);
      return;
    }

    clearLocalStorageData();
  }, [playableEpisode]);

  useEffect(() => {
    if (isMuted && !!audioRef?.current) {
      audioRef.current.volume = 0;
      return;
    }

    if (audioRef?.current) {
      audioRef.current.volume = volume;
    }
  }, [isMuted]);

  const onClose = () => {
    dispatch(setPlayableEpisodeAction(null));
    onPause();
  };

  const onNextEpisode = () => {
    const index =
      episodeIndex === playablePodcast.episodes?.length - 1 && isCircle
        ? 0
        : episodeIndex + 1;

    if (episodeIndex === playablePodcast.episodes?.length - 1 && !isCircle) {
      dispatch(playPlayerStatusAction(false));
      return;
    }
    const episode = playablePodcast?.episodes[index];
    dispatch(setPlayableEpisodeAction(episode));
    localStorage.setItem(PLAYER.PODCAST_ID, episode?.id);
  };

  const onPrevEpisode = () => {
    const index =
      episodeIndex === 0 && isCircle
        ? playablePodcast.episodes?.length - 1
        : episodeIndex - 1;
    const episode = playablePodcast?.episodes[index];
    dispatch(setPlayableEpisodeAction(episode));
    localStorage.setItem(PLAYER.PODCAST_ID, episode?.id);
  };

  const onEpisodeEnds = () => {
    onNextEpisode();
    updateProgress();
    onPause();
  };

  return {
    playableEpisode,
    onClose,
    isLoaded,
    isCircle,
    setCircle,
    onPrevEpisode,
    episodeIndex,
    isPlay,
    onPause,
    onPlay,
    onNextEpisode,
    currentTime,
    onProgressChange,
    audioRef,
    progress,
    isMuted,
    setMuteStatus,
    volume,
    setVolume,
    onRefChange,
    setData,
    playablePodcast,
    onEpisodeEnds,
  };
};

export default PlayerHooks;
