import styled from "styled-components";

const VideoContainer = styled.div`
	width: 100%;
	height: 180px;
	//border-radius: 8px 8px 0 0;
	overflow: hidden;
	position: relative;
	margin-bottom: 16px;
	cursor: pointer;
	
  	
	img {
		width: 101%;
		height: 101%;
		object-fit: cover;
	}
`;


const ItemTitle = styled.h3`
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	padding: 0 16px 0 16px;	
`;

const Item = styled.div`
	background: #FFFFFF;
	border-radius: 8px;
	width: 100%;
	height: 100%;
	min-height: 264px;
	flex-direction: column;
	margin-bottom: 20px;
	position: relative;
	overflow: hidden;
	border: 1px solid #c4c5ca;
`;

export default {
	Item,
	VideoContainer,
	ItemTitle,
};
