import React from "react";
import {FormattedMessage} from "react-intl";

import {EXPERTS} from "constants/experts";
import ExpertsItem from "./Experts.Item";
import StyledPage from '../styled/StyledPages';
import Styled from './styled/Experts';

const Experts = React.memo(() => {
  return (
    <div>
      <StyledPage.PageTitle>
        <FormattedMessage id="Experts.Title" />
      </StyledPage.PageTitle>
      <StyledPage.SubTitle>
        <FormattedMessage id="Experts.Desc" />
      </StyledPage.SubTitle>
      <Styled.List>
        {EXPERTS.map(expert => (
          <Styled.ListItem key={expert.type}>
            <ExpertsItem type={expert.type} dataKey={expert.dataKey} />
          </Styled.ListItem>
        ))}
      </Styled.List>
    </div>
  );
});

export default Experts;
