import React from "react";

import RichHtmlWrapper from "components/RichHTMLWrapper/RichHTMLWrapper";
import Styled from '../styled/StyledTextPage';
import StyledHelp from "containers/Help/styled/StyledHelp";
import {FormattedMessage} from "react-intl";

const About = React.memo(() => {
  return <Styled.PageTextContainer><StyledHelp.HelpBlock>
    <StyledHelp.HelpText>
      <FormattedMessage id="Help.Text" />
    </StyledHelp.HelpText>

    <StyledHelp.HelpBlockImg>
      <StyledHelp.LogoOtb/>
      <StyledHelp.LogoMain/>
    </StyledHelp.HelpBlockImg>

  </StyledHelp.HelpBlock></Styled.PageTextContainer>;
});

export default About;
