import styled from "styled-components";
import Bg from "../../../assets/images/Vectorbg.svg";
import LogoAuth from "assets/images/logo-auth.svg";
import { Link } from "react-router-dom";

const Page = styled.div`
  background-image: var(--gradientAside);
  background-position: center;
  position: relative;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  padding-top: 56px;

  @media (max-width: 930px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: url(${Bg}) center center no-repeat;
    background-size: cover;
    pointer-events: none;
  }
`;

const Logo = styled(Link)`
  width: 116px;
  height: 84px;
  cursor: pointer;
  display: block;
  background: url(${LogoAuth}) center center no-repeat;
  background-size: contain;
  margin: 0 auto 40px auto;
  padding-top: 0;
`;

const PageContent = styled.div`
  background: #ffffff;
  max-width: 928px;
  position: relative;
  z-index: 3;
  width: 100%;
  box-shadow: 0px 5px 10px rgba(127, 141, 163, 0.1);
  border-radius: 8px;
  padding-bottom: 30px;
  margin: 0 auto;
  min-height: 300px;
  position: relative;
  top: 40px;
`;

export default {
  PageContent,
  Page,
  Logo,
};
