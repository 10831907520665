export default {
  "About.Content": "<h1> دار اکادمی کے بارے میں </h1> <h2> لائبریری </h2> <p> ویڈیوز، ہدایات، مضامین اور مطبوعات براؤز کرنے اور دیکھنے کے لئے کھولیں لائبری\nآپ پسندیدہ میں آئٹمز شامل کر سکتے ہیں اور بعد میں پسندیدہ سیکشن سے دیکھ سکتے ہیں </p> <h2> آڈیٹوریم۔ <h2> <p> محفوظ شدہ لیکچرز، ویب کاسٹ اور لائیو سٹریمنگ ویڈیوز دیکھنے </p> <h2> ماہرین </h2> <p> ہمارے ماہرین آپ کی مدد کر سکتے ہیں، صرف زمرہ منتخب کریں، فارم بھریں اور اپنا سوال بھیج سکتے ہیں، اور آپ کو ای میل کے ذریعے جوابات موصول کرنا چاہئے </br> ہر قسم کے ماہرین کے بارے میں مزید جاننے کے لئے، زمرہ کھولیں اور اپنا سوال ٹائپ کریں </p> <h2> میرا اکاؤنٹ </h2> <p> آپ اپنے اکاؤنٹ پروفائل کا انتظام کرسکتے ہیں اور پاس ورڈ تبدیل کرسکتے ہیں </p> </br> </br> <p> اس ویب پورٹل کی تیاری Sanofi خلیج ممالک کی طرف سے فراہم کردہ ایک غیر محدود تعلیمی گرانٹ کے ذریعے ممکن بنایا گیا تھا </br> © 2018 ڈار اتحاد. تمام حقوق محفوظ </p>",
  "Aside.About": "کے بارے میں",
  "Aside.AdditionalInfo": "اضافی معلومات",
  "Aside.Auditorium": "لیکچر ہال",
  "Aside.Dashboard": "ڈیش بورڈ",
  "Aside.Experts": "ماہرین",
  "Aside.Podcasts": "پوڈ کاسٹ",
  "Aside.Help": "مدد",
  "Aside.Library": "لائبریری",
  "Aside.RiskCalculator": "خطرے کیلکولیٹر",
  "Aside.TrainTheTrainer": "ٹرینر کو تربیت دیں",
  "Aside.Version": "ورژن 3.5.10",
  "Auditorium.By": "کی طرف سے",
  "Auditorium.Tabs.Lectures": "لیکچرز",
  "Auditorium.Tabs.Videos": "ویڈیوز",
  "Auditorium.Title": "لیکچر ہال",
  "Auth.form.error.email.format": "ایک دروت ای میل پتا ڈالیں",
  "Auth.form.error.email.taken": "ای میل پہلے ہی لیا گیا ہے",
  "Auth.form.error.invalid": "ای میل یا پاس ورڈ غلط",
  "Auth.form.error.user.not-exist": "یہ ای میل موجود نہیں ہے",
  "ChangePassword.Success.Desc": "کامیابی",
  "ChangePassword.Success.Title": "کامیابی",
  "Dashboard.Auditorium.Desc": "ذخیرہ شدہ لیکچرز، ویب کاسٹ اور زندہ دیکھیں.",
  "Dashboard.Auditorium.Title": "لیکچر ہال",
  "Dashboard.Desc": "رمضان میں آپ کے ذاتی ذیابیطس کا معاون",
  "Dashboard.Experts.Desc": "ہمارے ماہرین آپ کی مدد کر سکتے ہیں، صرف آپ کا سوال بھیجیں.",
  "Dashboard.Experts.Title": "ماہرین",
  "Dashboard.Library.Desc": "ہدایات، مضامین اور مطبوعات کو براؤز کریں.",
  "Dashboard.Library.Title": "لائبریری",
  "Dashboard.Title": "ڈار اکیڈمی میں خوش آ",
  "Dashboard.TrainTheTrainer.Desc": "بہت زیادہ علم سے بے نقاب ہو جاؤ.",
  "Dashboard.TrainTheTrainer.Title": "ٹرینر کو تربیت دیں",
  "Dashboard.Podcasts.Title": "پوڈ کاسٹ",
  "Dashboard.Podcasts.Desc": "پوڈ کاسٹ",
  "Expert.Back": "تمام زمروں پر واپس",
  "Expert.Desc": "رمضان سے متعلقہ سوال کے دوران غذائی ماہرین کا پینل ای میل کے ذریعے آپ کے کھانے کا جواب دے گا",
  "Expert.Email": "ای میل",
  "Expert.Question": "براہ کرم سوال کی تفصیلات درج کریں",
  "Expert.Send": "درخواست بہیجیں",
  "Expert.Subject": "موضوع:",
  "Expert.Success.Desc": "کامیابی",
  "Expert.Success.Title": "کامیابی",
  "Experts.Desc": "ہمارے ماہرین آپ کی مدد کر سکتے ہیں, صرف زمرے کو منتخب کریں اور ہمارے ماہرین سے پوچھیں",
  "Experts.DiabetesConsultant.Desc": "ذیابیطس کے مشیر کے ہمارے پینل ای میل کے ذریعے آپ کے طبی متعلقہ سوالات کا جواب دیں گے.",
  "Experts.DiabetesConsultant.Title": "ذیابیطس مشیر",
  "Experts.DiabetesNurseEducator.Desc": "ذیابیطس نرس اساتذہ کے ہمارے گروپ ای میل کے ذریعے آپ کے سوالات کا جواب دیں",
  "Experts.DiabetesNurseEducator.Title": "ذیابیطس نرس استاد",
  "Experts.Dietician.Desc": "ہمارے غذائی ماہرین کا پینل رمضان کے دوران آپ کی خوراک کا ای میل کے ذریعے جواب دے گا",
  "Experts.Dietician.Title": "غذائیت",
  "Experts.ReligiousScholar.Desc": "معزز علمائے دین کا پینل ای میل کے ذریعے آپ کے روزے سے متعلقہ سوالات کا جواب دے گا",
  "Experts.ReligiousScholar.Title": "عالم دین",
  "Experts.Title": "ماہرین کا راہداری",
  "Favorites.Tabs.Auditorium": "لیکچر ہال",
  "Favorites.Tabs.Library": "لائبریری",
  "Favorites.Tabs.TrainTheTrainer": "ٹرینر کو تربیت دیں",
  "Footer.Desc": "یہ پورٹل غیر معطل تعلیمی تعلیم کے ذریعہ تیار کیا گیا ہے سانفی خلی ممالک\n© 2018 DAR alliance. تمام حقوق محفوظ ہیں",
  "Footer.Desc.Servier":
    "The preparation of this PODCAST was made possible through an unrestricted education support provided by SERVIER GCC\n© 2020 DAR alliance. All rights reserved",
  "ForgotPassword.Button": "پاس ورڈ ری سیٹ کیجئے",
  "ForgotPassword.Desc": "اپنا ای میل ایڈریس درج کریں اور ہم آپ کو اپنا پاس ورڈ دوبارہ ترتیب دینے کے لئے ایک لنک بھیج دیں گے",
  "ForgotPassword.Email": "ای میل",
  "ForgotPassword.Success.Desc": "کامیابی",
  "ForgotPassword.Success.Title": "کامیابی",
  "ForgotPassword.Title": "پاس ورڈ بھول گئے؟",
  "Header.ItemLogout": "لاگ آوٹ",
  "Header.ItemProfile": "پروفائل کی ترتیب",
  "Help.Content": "<h1> مدد </h1> <h2> لائبریری </h2> <p> ویڈیوز، ہدایات، مضامین اور مطبوعات براؤز کرنے اور دیکھنے کے لئے کھولیں لائبری\nآپ پسندیدہ میں آئٹمز شامل کر سکتے ہیں اور بعد میں پسندیدہ سیکشن سے دیکھ سکتے ہیں </p> <h2> آڈیٹوریم۔ <h2> <p> محفوظ شدہ لیکچرز، ویب کاسٹ اور لائیو سٹریمنگ ویڈیوز دیکھنے </p> <h2> ماہرین </h2> <p> ہمارے ماہرین آپ کی مدد کر سکتے ہیں، صرف زمرہ منتخب کریں، فارم بھریں اور اپنا سوال بھیج سکتے ہیں، اور آپ کو ای میل کے ذریعے جوابات موصول کرنا چاہئے </br> ہر قسم کے ماہرین کے بارے میں مزید جاننے کے لئے، زمرہ کھولیں اور اپنا سوال ٹائپ کریں </p> <h2> میرا اکاؤنٹ </h2> <p> آپ اپنے اکاؤنٹ پروفائل کا انتظام کرسکتے ہیں اور پاس ورڈ تبدیل کرسکتے ہیں </p> </br> </br> <p> اس ویب پورٹل کی تیاری Sanofi خلیج ممالک کی طرف سے فراہم کردہ ایک غیر محدود تعلیمی گرانٹ کے ذریعے ممکن بنایا گیا تھا </br> © 2018 ڈار اتحاد. تمام حقوق محفوظ </p>",
  "Help.Text": 'Develop by OTB Life Science & Technology Consultancy FZ-LLC.',
  "Lang.Ar": "العربية",
  "Lang.Eng": "English",
  "Lang.Fr": "Français",
  "Lang.Tr": "Türk",
  "Lang.Ur": "اردو",
  "Library.Author": "مصنف",
  "Library.Explore": "مزید کی جستجو کریں",
  "Library.Title": "لائبریری اور حوالہ جات",
  "Login.Button": "لاگ ان",
  "Login.Desc": "آپ کو واپس دیکھنے کے لئے بہت اچھا ہے",
  "Login.Email": "ای میل",
  "Login.ForgotPassword": "پاس ورڈ بھول گئے؟",
  "Login.Info": " { terms } & { privacy }مجھے قبول ہے",
  "Login.Password": "پاس ورڈ",
  "Login.PrivacyPolicy": "رازداری کی پالیسی",
  "Login.RememberMe": " یاد رکھیں",
  "Login.SignUp": "اکاؤنٹ نہیں ہے؟ سائن اپ کریں",
  "Login.Success.Desc": "کامیابی",
  "Login.Success.Title": "کامیابی",
  "Login.TermsOfUse": "استعمال کی شرائط",
  "Login.Title": "اپنے اکاؤنٹ میں لاگ ان کریں",
  "NewPassword.Button": "پاس ورڈ ری سیٹ کیجئے",
  "NewPassword.ConfirmPassword": "پاس ورڈ کی تصدیق",
  "NewPassword.Desc": "ایک نیا پاس ورڈ بنائیں اور اس کی تصدیق کریں",
  "NewPassword.NewPassword": "نیا پاس ورڈ",
  "NewPassword.Title": "پاس ورڈ بھول گئے؟",
  "Notifications.Error": "خرابی",
  "Notifications.SomethingWentWrong": "اوہ! کچھ غلط ہو گیا!.\nبراہ کرم بعد میں دوبارہ کوشش کریں، یا نظام کے منتظم سے رابطہ کریں اگر آپ اب بھی اس مسئلہ کا سامنا کر رہے",
  "Profile.AdditionalInformation": "اضافی معلومات",
  "Profile.Button": "معلومات اپ ڈیٹ کریں؟",
  "Profile.ChangePassword": "پاس ورڈ تبدیل کریں",
  "Profile.ChangePassword.Button": "تبدیل کریں",
  "Profile.ChangePassword.Title": "پاس ورڈ تبدیل کریں",
  "Profile.ConfirmPassword": "نیا پاس ورڈ تصدیق کریں",
  "Profile.Country": "ملک",
  "Profile.CurrentPassword": "موجودہ پاس ورڈ",
  "Profile.Email": "ای میل",
  "Profile.FullName": "مکمل نام",
  "Profile.Logout": "لاگ آوٹ",
  "Profile.MainInformation": "اہم معلومات",
  "Profile.NewPassword": "نیا پاس ورڈ",
  "Profile.Password": "پاس ورڈ",
  "Profile.Phone": "فون",
  "Profile.PlaceOfWork": "کام کی جگہ",
  "Profile.Speciality": "خاصیت",
  "Profile.Success.Desc": "کامیابی",
  "Profile.Success.Title": "کامیابی",
  "Profile.Title": "پروفائل",
  "Registration.Button": "سائن اپ کریں",
  "Registration.City": "شہر",
  "Registration.ConfirmPassword": "پاس ورڈ کی تصدیق",
  "Registration.Country": "ملک",
  "Registration.Desc": "اکاؤنٹ بنانے کے لئے براہ کرم فارم بھریں",
  "Registration.Email": "ای میل",
  "Registration.FullName": "مکمل نام",
  "Registration.Login": "کیا آپ کے پاس اکاؤنٹ ہے؟ لاگ ان",
  "Registration.Password": "پاس ورڈ",
  "Registration.Phone": "رابطہ نمبر/ فون *",
  "Registration.PlaceOfWork": "کام کی جگہ",
  "Registration.SignIn": "اکاؤنٹ نہیں ہے؟ سائن اپ کریں",
  "Registration.Speciality": "خاصیت",
  "Registration.Success.Desc": "کامیابی",
  "Registration.Success.Title": "کامیابی",
  "Registration.Title": "اپنے اکاؤنٹ میں لاگ ان کریں",
  "RiskCalculator.Button": "خطرے کا نتیجہ دکھائیں",
  "RiskCalculator.Desc": "روزہ رکھنے سے پہلے ذیابیطس کے ساتھ رہنے والے شخص کے خطرے کا حساب لگائیں۔",
  "RiskCalculator.Result": "آپ کا نتیجہ",
  "RiskCalculator.Result.High.Desc": "ایسے افراد کو جو زیادہ خطرہ زمرے میں ہوں، روزہ نہیں رکھتے۔\nیہ افراد رمضان کے دوران روزہ رکھنے کے دوران پیچیدگیوں کا بہت زیادہ خطرہ رکھتے ہیں۔ ہم سفارش کرتے ہیں کہ یہ افراد روزہ نہ رکھیں۔ اگر وہ روزہ رکھنے پر اصرار کرتے ہیں تو اوپر بیان کردہ حکمت عملی اور سفارشات کے ساتھ اور ان ہدایات کے دیگر ابواب میں انتہائی احتیاط اور نگرانی فراہم کی جانی چاہیے۔",
  "RiskCalculator.Result.High.Title": "اعلی خطرے والی",
  "RiskCalculator.Result.Low.Desc": "کم خطرے کی سطح پر ان لوگوں کو روزہ کرنے کے قابل ہونا چاہئے.\nرمضان میں روزے کی حالت میں پیدا ہونے والی پیچیدگیوں کے لحاظ سے یہ افراد کم خطرہ رکھتے ہیں۔ تاہم جیسا کہ ذکر کیا گیا ہے، حالات خطرے کے اسکورنگ میں تبدیلی کی وجہ سے تبدیل ہوسکتے ہیں. لہذا، رمضان سے پہلے خطرے کی سطح کا جائزہ لینے کے لئے سالانہ طور پر خطرے کا استحکام کیا جانا چاہئے.",
  "RiskCalculator.Result.Low.Title": "کم خطرہ",
  "RiskCalculator.Result.Medium.Desc": "اعتدال پسند خطرے کی سطح پر رکھنے والوں کو روزہ نہ رکھنے کا مشورہ دیا جاتا ہے۔\nجیسا کہ پہلے ذکر کیا گیا ہے، ان میں سے بہت سے مریضوں کو ویسے بھی روزہ رکھنا ہوگا HCPs کے ساتھ مشاورت میں منسلک خطرات پر غور کرنے کے بعد یہ اہم ذاتی فیصلہ کیا جانا چاہئے. انہیں اس خطرے کو کم کرنے کے لئے تکنیک یا حکمت عملی سے آگاہ ہونا بھی ضروری ہے. اگر کوئی شخص روزہ رکھنے کا انتخاب کرے تو انہیں محتاط رہنے کی ضرورت ہو گی اور اگر کوئی مشکلات پیدا ہو تو روزہ چھوڑنا پڑے گا۔",
  "RiskCalculator.Result.Medium.Title": "متوسط خطرے والی",
  "RiskCalculator.Title": "خطرے کیلکولیٹر",
  "RiskCalculator.TryAgain": "دوبارہ کوشش کریں",
  "RiskInfo.Button": "ابھی شروع کریں",
  "RiskInfo.Desc": "<p> ذیابیطس کے ساتھ رہنے والے شخص کے خطرے کا حساب لگائیں <a target='_blank' href='https://www.idf.org/our-activities/education/diabetes-and-ramadan/healthcare-professionals.html'> 2021, IDF-Dار خطرے ستریکرن الگورتھم IDF-Dار عملی ہدایات میں شائع </a> باب 5: رمضان سے پہلے ذیابیطس والے افراد کے خطرے کا استحکام </p>",
  "RiskInfo.High.Desc": "اعلی خطرے والی",
  "RiskInfo.High.Title": "اسکور > 6",
  "RiskInfo.List": "<ul> <li> نئے آئی ڈی ایف ڈار خطرے کی ستریکرن تین خطرے کے زمرے کی وضاحت کرتا ہے اور ایک خطرہ اسکور فراہم کرتا ہے جس میں ایک سے زیادہ عوامل شامل ہیں جو ہر قسم کے لئے روزہ کی سفارش میں اہم کردار ادا کرتے ہیں یعنی کم خطرہ، اعتدال پسند خطرہ اور </li>  <li> اسکورنگ کا نظام مختلف عوامل پر غور کیا گیا تھا جو روزے پر اثر انداز ہوتا ہے۔ </li> <li> ایک دیئے گئے فرد کے لئے، ہر خطرے کے عنصر کا اندازہ لگایا جانا چاہئے، اور سکور مجموعی طور پر ہونا چاہئے. </li> <li> اس کے نتیجے میں ذیابیطس والے شخص کے لئے مجموعی طور پر خطرے کی سطح کا تعین کیا جائے گا جو رمضان کے دوران روزہ رکھنے کی کوشش کر رہا ہے۔ </li> </ul>",
  "RiskInfo.Low.Desc": "کم خطرہ",
  "RiskInfo.Low.Title": "اسکور 0 سے 3",
  "RiskInfo.Medium.Desc": "اعتدال پسند خطرے",
  "RiskInfo.Medium.Title": "اسکور 3.5 سے 6",
  "RiskInfo.Title": "IDF — ڈار رسک کیلکولیٹر:\nصرف صحت کے پیشہ ور افراد کے لئے",
  Search: "تلاش کریں…",
  "SetNewPassword.Success.Desc": "کامیابی",
  "SetNewPassword.Success.Title": "کامیابی",
  "TrainTheTrainer.AdditionalMaterial": "اضافی مواد",
  "TrainTheTrainer.Author": "مصنف",
  "TrainTheTrainer.Back": "پیچھے",
  "TrainTheTrainer.Congratulation": "مبارک ہو",
  "TrainTheTrainer.Explore": "مزید کی جستجو کریں",
  "TrainTheTrainer.Lesson": "سبق",
  "TrainTheTrainer.Modal.Button": "پیچھے",
  "TrainTheTrainer.Modal.Desc": "آپ کو اس سیکشن کا دورہ کرنے کی دعوت نہیں دی جاتی ہے",
  "TrainTheTrainer.Modal.Title": "مدعو نہیں",
  "TrainTheTrainer.QuestionsCount": "سوال {current} / {count}",
  "TrainTheTrainer.Quiz": "کوئز لیں",
  "TrainTheTrainer.Result.Desc": "آپ کوئز پاس",
  "TrainTheTrainer.Skip": "نظر انداز کریں",
  "TrainTheTrainer.Title": "ٹرینر کو تربیت دیں",
  "Validation.FieldIsRequired": "فیلڈ درکار ہے",
  "Podcasts.folder.FRENCH": "فرانسیسی",
  "Podcasts.folder.OTHER": "دوسرے"
} ;
