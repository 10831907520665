import React from "react";
import {FormattedMessage} from "react-intl";

import RiskTest from "containers/Risk/Risk.Test";
import StyledPages from "containers/styled/StyledPages";

const Risk = React.memo(() => {
  return (
    <div>
      <StyledPages.PageTitle>
        <FormattedMessage id="RiskCalculator.Title" />
      </StyledPages.PageTitle>
      <StyledPages.SubTitle>
        <FormattedMessage id="RiskCalculator.Desc" />
      </StyledPages.SubTitle>

        <StyledPages.PageBgContainer>
            <RiskTest />
        </StyledPages.PageBgContainer>
    </div>
  );
});

export default Risk;
