import styled, { css } from "styled-components";

const Cover = styled.div`
  width: 330px;
  height: 330px;
  box-shadow: 0px 5px 10px rgba(127, 141, 163, 0.1);
  border-radius: 8px;
  margin-right: 32px;
  overflow: hidden;
  display: inline-flex;

  @media (min-width: 975px) and (max-width: 1150px) {
    width: 180px;
    height: 180px;
    margin-right: 12px;
  }
  @media (min-width: 768px) and (max-width: 974px) {
    margin: 0 auto 20px auto;
  }
  @media (max-width: 767px) {
    display: none;
  }
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const CoverMobile = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: inline-block;
    width: 120px;
    height: 120px;
    display: inline-block;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
`;

const WrapperMobile = styled.div`
  position: relative;
  @media (max-width: 767px) {
    display: flex;
  }
`;
const MobileLeft = styled.div`
  @media (max-width: 767px) {
    display: inline-flex;
    width: calc(100% - 120px);
    padding-left: 16px;
    flex-direction: column;
  }
`;
const Panel = styled.div`
  position: absolute;
  top: 7px;
  right: 0;
  @media (max-width: 1399px) {
    position: static;
    display: flex;
    justify-content: right;
  }
  .anticon {
    font-size: 21px;
    padding-right: 30px;
    color: #1b728b;
  }
`;
const More = styled.i`
  cursor: pointer;
`;
const Favorite = styled.i`
  cursor: pointer;
`;
const Add = styled.i`
  cursor: pointer;
`;
const Title = styled.div`
  color: #424e6f;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 2px;
  line-height: 1.3;

  @media (min-width: 1400px) {
    max-width: 80%;
  }
  @media (max-width: 767px) {
    font-size: 18px;
    margin-bottom: 8px;
  }
`;

const Speaker = styled.div`
  color: #9a9fad;
  font-weight: bold;
  margin-bottom: 14px;
  font-size: 20px;
  @media (max-width: 767px) {
    font-size: 10px;
  }
`;

const Desc = styled.div`
  overflow: hidden;
  font-size: 12px;
  color: #424e6f;
  margin-bottom: 15px;
  button {
    background: initial;
    border: none;
    padding: 0;
    font-weight: bold;
    color: #424e6f;
  }
  @media (max-width: 768px) {
    padding-top: 16px;
  }
`;

const Tag = styled.div`
  display: inline-block;
  font-size: 12px;
  color: #424e6f;
  background: #d2d8df;
  border-radius: 50px;
  padding: 3px 10px;
  margin-right: 15px;
  margin-bottom: 5px;
`;

const Tags = styled.div`
  margin-bottom: 16px;
`;

const Clearfix = styled.div`
  display: table;
  clear: both;
`;

const PlayButton = styled.div`
  display: inline-block;
  cursor: pointer;
  background: linear-gradient(16.13deg, #003e59 7.3%, #288da4 100%);
  border-radius: 5px;
  padding: 13px 85px;
  margin-top: 20px;
  @media (min-width: 975px) and (max-width: 1150px) {
    padding: 13px 45px;
  }
  @media (max-width: 974px) {
    width: 100%;
    padding: 13px 15px;
    text-align: center;
  }
  color: #ffffff;
  .anticon {
    margin-right: 8px;
  }
`;

const Episodes = styled.div`
  padding: 30px 0 0;
`;

const Caption = styled.div`
  color: #9a9fad;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const Episode = styled.div`
  padding: 16px;
  background: #ffffff;
  margin-bottom: 32px;
  box-shadow: 0px 5px 10px rgba(127, 141, 163, 0.1);
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    .anticon,
    i {
      display: block;
    }
  }
`;

const EpisodeHeader = styled.div`
  display: flex;
  flex-direction: row;
  color: #9a9fad;
  font-size: 12px;
  margin-bottom: 8px;
`;

const EpisodeCount = styled.div`
  display: inline-block;
  margin-right: 8px;
`;
const EpisodeDuration = styled.div`
  display: inline-flex;
  flex-direction: row;
  margin-left: 8px;
  @media (min-width: 640px) {
    min-width: 280px;
  }

  .ant-progress-line {
    width: 100px;
    margin-top: -3px;
  }
  .ant-progress {
    margin-left: 10px;
  }
  .ant-progress-bg {
    background-color: #2189a7;
    height: 4px !important;
  }
  .ant-progress-inner {
    background-color: #d2d8df;
  }
`;

const EpisodeBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

const EpisodeTitleTxt = styled.div`
  flex: 1 0;
`;

const EpisodeCover = styled.div`
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 4px;
  margin-right: 16px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media (max-width: 640px) {
      object-fit: contain;
    }
  }
  i {
    display: none;
    @media (hover: none) and (pointer: coarse) {
      display: block;
    }
  }
  .anticon {
    display: none;
    @media (hover: none) and (pointer: coarse) {
      display: block;
    }
    position: absolute;
    left: 50%;
    font-size: 40px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ActionIcon = styled.i`
  width: 32px;
  height: 32px;
  background: linear-gradient(267.93deg, #1b728b 13.19%, #07546d 80.29%);
  border-radius: 50%;
  position: absolute;
  bottom: 24px;
  left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  .anticon {
    position: absolute;
    top: 50%;
    left: 50% !important;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 19px !important;
    margin-left: 1px;
    &.anticon-pause {
      font-size: 18px !important;
      margin-left: 0;
      margin-top: 1px;
    }
  }
`;

const EpisodeDesc = styled.div`
  font-size: 12px;
  color: #9a9fad;
`;

const EpisodeTitle = styled.div`
  color: ${({ isPlaying }) => (isPlaying ? "#2189A7" : "#424e6f")};
  font-size: 18px;
  font-weight: bold;
`;

const EpisodeAuthor = styled.div`
  font-size: 12px;
  color: #9a9fad;
`;

const PodcastInfoWrapper = styled.div`
  display: inline-block;
  width: calc(100% - 330px);
  padding-left: 0;
  margin-top: -7px;

  @media (min-width: 975px) and (max-width: 1150px) {
    width: calc(100% - 180px);
  }

  @media (min-width: 768px) and (max-width: 974px) {
    width: 100%;
    padding-left: 0;
    margin-top: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-left: 0;
    margin-top: 0;
  }
`;

const Search = styled.div`
  margin-top: 20px;
  max-width: 928px;
  width: 100%;
  height: 72px;
  background: #fff;
`;

const Follow = styled.div`
  font-size: 16px;
  color: #000000;

  @media (max-width: 767px) {
    font-size: 15px;
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  @media (min-width: 768px) and (max-width: 974px) {
    flex-wrap: wrap;
  }
`;

const Checked = styled.div`
  position: relative;
  border-radius: 50%;
  vertical-align: middle;
  margin-left: 8px;
  display: inline-block;
  width: 10px;
  height: 10px;
  top: 3px;
  margin-right: 8px;
  background: linear-gradient(16.13deg, #003e59 7.3%, #288da4 100%);
  .anticon {
    position: absolute;
    font-size: 7px;
    color: #ffffff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default {
  Cover,
  Title,
  Speaker,
  Desc,
  Add,
  Header,
  Search,
  Tag,
  Tags,
  PlayButton,
  Clearfix,
  Episodes,
  Caption,
  Follow,
  Episode,
  EpisodeHeader,
  EpisodeCount,
  EpisodeDuration,
  EpisodeBody,
  EpisodeCover,
  EpisodeDesc,
  EpisodeTitle,
  EpisodeAuthor,
  PodcastInfoWrapper,
  Checked,
  WrapperMobile,
  CoverMobile,
  MobileLeft,
  Panel,
  More,
  Favorite,
  EpisodeTitleTxt,
  ActionIcon,
};
