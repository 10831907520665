import React from "react";
import moment from "moment";
import { Spin } from "antd";
import {
  CaretRightFilled,
  StepBackwardOutlined,
  StepForwardOutlined,
  CloseOutlined,
  PauseOutlined,
} from "@ant-design/icons";

import ShuffleIcon from "assets/images/shuffleIcon.svg";
import Styled from "./styled";
import { localizeText } from "utils/lang";
import usePlayerHook from "./Player.hooks";

const Player = () => {
  const {
    playableEpisode,
    onClose,
    isLoaded,
    isCircle,
    setCircle,
    onPrevEpisode,
    episodeIndex,
    isPlay,
    onPause,
    onPlay,
    onNextEpisode,
    currentTime,
    onProgressChange,
    audioRef,
    progress,
    isMuted,
    setMuteStatus,
    volume,
    setVolume,
    onRefChange,
    setData,
    playablePodcast,
    onEpisodeEnds,
  } = usePlayerHook();
  return (
    <>
      {playableEpisode && (
        <Styled.Player>
          <Styled.PlayerTop>
            <CloseOutlined onClick={onClose} />
          </Styled.PlayerTop>
          <Styled.PlayerWrapper>
            <Styled.EpisodeInfo>
              <Styled.PlayerCover>
                <img src={playablePodcast?.image?.url} alt="" />
              </Styled.PlayerCover>
              <Styled.PlayerTitle>
                <Styled.EpisodeTitle>
                  {localizeText(playableEpisode?.title)}
                </Styled.EpisodeTitle>
                <Styled.EpisodeAuthor>
                  {localizeText(playableEpisode?.speaker)}
                </Styled.EpisodeAuthor>
              </Styled.PlayerTitle>
            </Styled.EpisodeInfo>
            {isLoaded ? (
              <Styled.PlayerControlsWrapper>
                <Styled.PlayerControls>
                  <Styled.CircleIcon
                    isCircle={isCircle}
                    onClick={() => setCircle(!isCircle)}
                  />
                  <StepBackwardOutlined
                    onClick={onPrevEpisode}
                    disabled={episodeIndex === 0 && !isCircle}
                  />
                  <Styled.Play
                    onClick={() => {
                      isPlay ? onPause() : onPlay();
                    }}
                  >
                    {isPlay ? <PauseOutlined /> : <CaretRightFilled />}
                  </Styled.Play>
                  <StepForwardOutlined
                    onClick={onNextEpisode}
                    disabled={
                      episodeIndex === playablePodcast?.episodes?.length - 1 &&
                      !isCircle
                    }
                  />
                  <img src={ShuffleIcon} style={{ visibility: "hidden" }} />
                </Styled.PlayerControls>

                <Styled.SliderWrapper>
                  <div>
                    {moment.utc(currentTime * 1000).format("mm:ss") || 0}
                  </div>
                  <Styled.Slider
                    onChange={onProgressChange}
                    tipFormatter={() =>
                      moment.utc(currentTime * 1000).format("mm:ss")
                    }
                    value={progress}
                  />
                  <div>
                    {moment
                      .utc(audioRef?.current?.duration * 1000)
                      .format("mm:ss")}
                  </div>
                </Styled.SliderWrapper>
              </Styled.PlayerControlsWrapper>
            ) : (
              <Spin size="small" />
            )}
            <Styled.Volume>
              <Styled.VolumeIcon
                isMuted={isMuted}
                onClick={() => setMuteStatus(!isMuted)}
              />
              <Styled.VolumeSlider
                onChange={(v) => {
                  setVolume(v / 100);
                }}
                value={isMuted ? 0 : volume * 100}
              />
              <div></div>
            </Styled.Volume>
          </Styled.PlayerWrapper>
          <audio
            preload={"true"}
            ref={onRefChange}
            style={{ display: "none" }}
            controls
            onEnded={onEpisodeEnds}
            onLoadedData={() => {
              setData();
            }}
          >
            <source
              src={playableEpisode?.media?.url}
              type={playableEpisode?.media?.mime}
            />
          </audio>
        </Styled.Player>
      )}
    </>
  );
};

export default Player;
