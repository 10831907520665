import styled from "styled-components";
import Icon from 'assets/images/Search.svg';

const StyledSearchIcon = styled.i`
	width: 20px;
	height: 20px;
	background: url(${Icon}) 0 0 no-repeat;
	background-size: cover;
	cursor: pointer;
`;
export default StyledSearchIcon;
