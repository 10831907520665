import styled, {css} from "styled-components";
import LogoImg from '../../../assets/images/sanofi-new.png';

const Footer = styled.footer`
	background: #F2F4F6;
	border-radius: 10px 10px 0 0;
	height: 160px;
	padding-top: 75px;
	padding-left: 20px;
	position: absolute;
	left: 0;
    width: 100%;
    top: 100%;
	 @media (max-width: 767px) {
		padding-left: 10px;
		padding-right: 10px;
  	}
	${({type}) => {
		if (type && type === 'big') {
			return css`
	               height: 260px;
	               margin-top: -110px;
	               padding-top: 180px;
	               `
		}
	}}
`;

const FooterTxt = styled.p`
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	text-align: center;
	color: var(--gray);	
	max-width: 560px;
	margin: 0 auto;
	opacity: 0.9;
`;

const FooterLogo = styled.div`
	width: 59px;
	height: 13px;
	background: url(${LogoImg}) 0 0 no-repeat;
	background-size: contain;
	margin: 15px auto 32px auto;
	opacity: 0.9;
`;

export default {
	Footer,
	FooterTxt,
	FooterLogo
};
