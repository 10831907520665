import React, { useState } from "react";
import PodcastsTopPodcasts from "./Podcasts.TopPodcasts";
import PodcastsList from "containers/Podcasts/Podcasts.List";
import Styled from "containers/Podcasts/styled";
import Input from "components/InputSearch/Input";
import StyledSearchIcon from "components/Search/styled/StyledSearchIcon";
import { useIntl } from "react-intl";
import { localizeText } from "utils/lang";

const PodcastsPlaylists = ({ playlists }) => {
  const { formatMessage } = useIntl();
  const topPodcasts = playlists.filter((playlist) => !!playlist.featured);
  const podcasts = playlists.filter((playlist) => !playlist.featured);
  const [filter, setFilter] = useState("");

  const filteredPodcasts = !!filter
    ? podcasts.filter((e) =>
        localizeText(e?.title).toLowerCase().includes(filter)
      )
    : podcasts;

  const filteredTopPodcasts = !!filter
    ? topPodcasts.filter((e) =>
        localizeText(e?.title).toLowerCase().includes(filter)
      )
    : topPodcasts;

  const onFilter = ({ target }) => {
    setFilter(target.value?.toLowerCase());
  };
  return (
    <>
      <Styled.Search>
        <Input
          placeholder={formatMessage({ id: "Podcasts.Search" })}
          onChange={onFilter}
          value={filter}
          prefix={<StyledSearchIcon />}
          allowClear
        />
      </Styled.Search>
      {!!filteredTopPodcasts.length && (
        <PodcastsTopPodcasts topPodcasts={filteredTopPodcasts} />
      )}
      {!!filteredPodcasts.length && (
        <PodcastsList playlists={filteredPodcasts} />
      )}
    </>
  );
};

export default PodcastsPlaylists;
