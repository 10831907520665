import React, { useState } from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

import { localizeText } from "utils/lang";
import AuditoriumVideo from "components/FavoriteItems/Auditorium.Item/Auditorium.Item";
import Styled from "./styled/StyledAuditorium";

const AuditoriumItem = React.memo(
  ({ title, videoItems, searchTerm, ...res }) => {
    const lang = localStorage.getItem("lang") || "en";
    const filteredByLang = videoItems?.filter(
      (video) => video?.lang && video?.lang === lang
    );

    const filteredVideos = filteredByLang?.filter((video) =>
      localizeText(video.title)?.toLowerCase().includes(searchTerm)
    );
    const [isCollapsed, toggleCollapse] = useState(true);
    return (
      <>
        {!!filteredVideos.length && (
          <div>
            <Styled.ListTitle onClick={() => toggleCollapse(!isCollapsed)}>
              {localizeText(title)}{" "}
              {isCollapsed ? <CaretDownOutlined /> : <CaretUpOutlined />}
            </Styled.ListTitle>
            {!isCollapsed && (
              <Styled.List>
                {filteredVideos?.map((video) => (
                  <Styled.ListItem key={video.id}>
                    <AuditoriumVideo {...video} />
                  </Styled.ListItem>
                ))}
              </Styled.List>
            )}
          </div>
        )}
      </>
    );
  }
);

export default AuditoriumItem;
