import React from "react";
import { Link } from "react-router-dom";

import ModalWrapper from "components/ModalWrapper/ModalWrapper";
import { MODALS_ID } from "constants/modals";
import Button from "components/Button/Button";
import { urlLocations } from "routes/urlLocations";
import {FormattedMessage} from "react-intl";
import ModalContentCenter from "components/ModalWrapper/styled/StyledModal";

const TrainTheTrainersModalContent = () => {
  return (
    <div>
        <ModalContentCenter>
            <h2>
                <FormattedMessage id="TrainTheTrainer.Modal.Title" />
            </h2>
            <p>
                <FormattedMessage id="TrainTheTrainer.Modal.Desc" />
            </p>
            <Link component={Button} to={urlLocations.dashboard}>
                <FormattedMessage id="TrainTheTrainer.Modal.Button" />
            </Link>
        </ModalContentCenter>
    </div>
  );
};

const TrainTheTrainersModal = () => {
  return (
    <ModalWrapper
      closable={false}
      maskClosable={false}
      component={TrainTheTrainersModalContent}
      modalId={MODALS_ID.TRAIN_THE_TRAINER_MODAL}
    />
  );
};

export default TrainTheTrainersModal;
