import React from "react";
import { useSelector } from "react-redux";
import groupBy from "lodash/groupBy";
import { podcastsPlaylistsSelector } from "services/podcasts/podcasts.selectors";
import FolderImg from "assets/folder.png";
import DiaSVGImg from "assets/images/DiaVoiceSVG.svg";
import Styled from "./styled";
import { pathBuilder } from "routes/urlLocations";
import {localizeText} from "utils/lang";

const PodcastsFolders = () => {
  const playlists = useSelector(podcastsPlaylistsSelector);
  const groupedPlaylists = groupBy(playlists, "folder");

  return (
    <>
      <Styled.TitleLogo src={DiaSVGImg}/>
      <Styled.Row>
        {Object.keys(groupedPlaylists)?.map((folder) => (
          <Styled.Col to={pathBuilder.podcasts(folder)} key={folder}>
            <img src={FolderImg} />
            <Styled.FolderName>{localizeText(groupedPlaylists[folder][0].folderName)}</Styled.FolderName>
          </Styled.Col>
        ))}
      </Styled.Row>
    </>
  );
};

export default PodcastsFolders;
