import React from "react";
import { Switch } from "react-router";

import { urlLocations } from "./urlLocations";
import RouteWrapper from "./RouterWrapper";
import Containers from "containers";
import MainLayout from "layouts/Main/Main";
import AuthorizedLayout from "layouts/AuthorizedLayout/AuthorizedLayout";
import StaticLayout from "layouts/StaticLayout/StaticLayout";

export default () => (
  <>
    <Switch>
      <RouteWrapper
        exact
        path={urlLocations.dashboard}
        layout={AuthorizedLayout}
        component={Containers.Dashboard}
      />
      <RouteWrapper
        exact
        path={urlLocations.podcastsFolders}
        layout={AuthorizedLayout}
        component={Containers.PodcastsFolders}
      />
      <RouteWrapper
        exact
        path={urlLocations.podcastInfo}
        layout={AuthorizedLayout}
        component={Containers.Podcasts}
      />
      <RouteWrapper
        exact
        path={urlLocations.podcastEpisodes}
        layout={AuthorizedLayout}
        component={Containers.Podcast}
      />
      <RouteWrapper
        exact
        path={urlLocations.topPodcasts}
        layout={AuthorizedLayout}
        component={Containers.TopPodcasts}
      />
      <RouteWrapper
        exact
        path={urlLocations.termsOfUse}
        layout={StaticLayout}
        component={Containers.TermsOfUse}
      />
      <RouteWrapper
        exact
        path={urlLocations.privacyPolicy}
        layout={StaticLayout}
        component={Containers.PrivacyPolicy}
      />
      <RouteWrapper
        exact
        path={urlLocations.favoritesSection}
        layout={AuthorizedLayout}
        component={Containers.Favorites}
      />
      <RouteWrapper
        exact
        path={urlLocations.lessonInfo}
        layout={AuthorizedLayout}
        component={Containers.Lesson}
      />
      <RouteWrapper
        exact
        path={urlLocations.profile}
        layout={AuthorizedLayout}
        component={Containers.Profile}
      />
      <RouteWrapper
        exact
        path={urlLocations.about}
        layout={AuthorizedLayout}
        component={Containers.About}
      />
      <RouteWrapper
        exact
        path={urlLocations.help}
        layout={AuthorizedLayout}
        component={Containers.Help}
      />
      <RouteWrapper
        exact
        path={urlLocations.setNewPassword}
        layout={MainLayout}
        component={Containers.SetNewPassword}
      />
      <RouteWrapper
        exact
        path={urlLocations.risk}
        layout={AuthorizedLayout}
        component={Containers.RiskInfo}
      />
      <RouteWrapper
        exact
        path={urlLocations.riskTest}
        layout={AuthorizedLayout}
        component={Containers.Risk}
      />
      <RouteWrapper
        exact
        path={urlLocations.publicRisk}
        layout={StaticLayout}
        component={Containers.RiskInfo}
      />
      <RouteWrapper
        exact
        path={urlLocations.publicRiskTest}
        layout={StaticLayout}
        component={Containers.Risk}
      />
      <RouteWrapper
        exact
        path={urlLocations.quizResult}
        layout={AuthorizedLayout}
        component={Containers.QuizResult}
      />
      <RouteWrapper
        exact
        path={urlLocations.quizInfo}
        layout={AuthorizedLayout}
        component={Containers.Quiz}
      />
      <RouteWrapper
        exact
        path={urlLocations.forgotPassword}
        layout={MainLayout}
        component={Containers.ForgotPassword}
      />
      <RouteWrapper
        exact
        path={urlLocations.courseInfo}
        layout={AuthorizedLayout}
        component={Containers.Course}
      />
      <RouteWrapper
        exact
        path={urlLocations.train}
        layout={AuthorizedLayout}
        component={Containers.TrainTheTrainer}
      />
      <RouteWrapper
        exact
        path={urlLocations.expertInfo}
        layout={AuthorizedLayout}
        component={Containers.Expert}
      />
      <RouteWrapper
        exact
        path={urlLocations.experts}
        layout={AuthorizedLayout}
        component={Containers.Experts}
      />
      <RouteWrapper
        exact
        path={urlLocations.auditorium}
        layout={AuthorizedLayout}
        component={Containers.Auditorium}
      />
      <RouteWrapper
        exact
        path={urlLocations.library}
        layout={AuthorizedLayout}
        component={Containers.Library}
      />
      <RouteWrapper
        exact
        path={urlLocations.registration}
        layout={MainLayout}
        component={Containers.Registration}
      />
      <RouteWrapper
        exact
        path={urlLocations.login}
        layout={MainLayout}
        component={Containers.Login}
      />
    </Switch>
  </>
);
