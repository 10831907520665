import styled from 'styled-components';
import React from 'react';
import {Modal} from 'antd';

const StyledModal = styled(
	({ ...props }) => <Modal {...props} />
)`
	&& {
		
		@media (min-width: 920px) {
  		}
  
		
		.ant-modal-content {
			background: #FFFFFF;
			width: 700px !important;
			border-radius: 8px;
			padding: 10px;
			min-height:  300px;
			max-width: 800px;	
		}

	}
`
export default StyledModal;