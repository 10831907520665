import EnFlag from "assets/images/lang/uk.svg";
import ArFlag from "assets/images/lang/ae.svg";
import TrFlag from "assets/images/lang/tr.png";
import FrFlag from "assets/images/lang/fr.png";
import UrFlag from "assets/images/lang/pk.png";

export const OPTIONS = [
  { title: "Lang.Eng", value: "en" ,  img: EnFlag},
  { title: "Lang.Ar", value: "ar" , img: ArFlag},
  { title: "Lang.Tr", value: "tr" , img: TrFlag},
  { title: "Lang.Fr", value: "fr" , img: FrFlag},
  { title: "Lang.Ur", value: "ur" , img: UrFlag}
];
