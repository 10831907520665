import React from "react";
import {Field} from "redux-form";
import {useIntl} from "react-intl";
import Input from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";

import ControlWrapper from "components/ControlWrapper/ControlWrapper";

const CustomPhoneInput = ({
  input: { name },
  label,
  input,
  country,
  placeholder,
  customTouched,
  setTouched,
  destination,
  preferredCountries = "",
  greenborderfocus,

  ...meta
}) => {
  const { formatMessage } = useIntl();
  return (
    <ControlWrapper
      label={""}
      name={name}
      error={meta.meta.error}
      touched={meta.meta.touched}
    >
      <Input
        autoComplete="off"
        {...input}
        {...meta}
        placeholder={''}
        containerClass={greenborderfocus ? 'green-focus': 'gray-focus'}
        hasError={meta.meta.error && meta.meta.touched}
        inputClass={meta.meta.error && meta.meta.touched && "error"}
        country={country || "eg"}
        preferredCountries={[...preferredCountries, "eg"]}
      />
    </ControlWrapper>
  );
};

const InputPhoneField = ({ name, type, label, placeholder, country ,greenborderfocus }) => {
  return (
    <Field
      greenborderfocus={greenborderfocus}
      component={CustomPhoneInput}
      name={name}
      type={type}
      label={""}
      country={country}
      placeholder={''}
      preferredCountries={["ae"]}
    />
  );
};

export default InputPhoneField;
