import styled from "styled-components";

const Description = styled.div`
	padding: 0 0 24px 0;
	
`;

const VideoBlock = styled.div`
	width: 100%;	
	video {
	       max-width: 800px;
    	 	width: 100%;
	}
`;

const AddImg = styled.div`
	margin-right: 10px;
	max-width: 50px;
	display: inline-block;
	img {
		object-fit: cover;
	}	
`;

const AddName = styled.span`
	font-weight: 400;
	font-size: 15px;	
`;


const LessonNumber = styled.p`
	font-size: 15px;
	line-height: 18px;
	color: var(--gray);
	padding: 0;
	margin: 0 0 6px 0;
`;

const AdditionalLink = styled.a`
	color: var(--blue);
	font-weight: bold;
	padding: 5px 0;
	display: block;
	&:hover{
		text-decoration: none;
		color: var(--blue);
	}
`;

const Author = styled.p`
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
	color: var(--gray);
	position: relative;
	top: -5px;
	margin-bottom: 0;
`;

const Title = styled.h2`
	font-weight: bold;
	font-size: 32px;
	line-height: 40px;
	padding-bottom: 24px;
	padding-top: 16px;
	
`;

export default {
	LessonNumber,
	Author,
	Title,
	Description,
	VideoBlock,
	AdditionalLink,
	AddImg,
	AddName
};
