import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { FormattedMessage } from "react-intl";

import { localizeText } from "utils/lang";
import * as trainTheTrainerSelectors from "services/trainTheTrainers/trainTheTrainer.selectors";
import { pathBuilder } from "routes/urlLocations";
import { MATERIAL_TYPES } from "./Lesson.constants";
import TrainTheTrainers from "components/FavoriteItems/TrainTheTrainers.Item";
import StyledItem from "../../components/FavoriteItems/styled/StyledItem";
import StyledPages from "containers/styled/StyledPages";
import Styled from "./styled/StyledLesson";

const renderMaterial = ({ material, thumbnail }) => {
  if (material?.mime === MATERIAL_TYPES.video) {
    return (
      <Styled.VideoBlock>
        <video controls poster={material?.thumbnail?.url} src={material?.url} />
      </Styled.VideoBlock>
    );
  }
  return (
    <StyledItem.ItemInner>
      <StyledItem.Item href={material?.url} target="__blank">
        <StyledItem.ItemLeftCol>
          <img width={50} src={thumbnail.url} alt="" />
        </StyledItem.ItemLeftCol>

        <StyledItem.ItemRightCol>
          <StyledItem.AuthorTxt>
            <span>{material.name}</span>
          </StyledItem.AuthorTxt>
        </StyledItem.ItemRightCol>
      </StyledItem.Item>
    </StyledItem.ItemInner>
  );
};

const Lesson = React.memo(() => {
  const { courseId, lessonId } = useParams();
  const lesson = useSelector((state) =>
    trainTheTrainerSelectors.lessonSelector(state, courseId, lessonId)
  );
  const course = useSelector((state) =>
    trainTheTrainerSelectors.courseSelector(state, courseId)
  );
  const index = course?.courseLessons?.indexOf(lesson) + 1;

  return (
    <div>
      <StyledPages.BackBtn to={pathBuilder.course(courseId)}>
        <FormattedMessage id="TrainTheTrainer.Back" />
      </StyledPages.BackBtn>

      <StyledPages.PageBgContainer>
        <Styled.LessonNumber>
          <FormattedMessage id="TrainTheTrainer.Lesson" /> : {index}
        </Styled.LessonNumber>

        <Styled.Title>{localizeText(lesson?.title)}</Styled.Title>

        <Styled.Description>
          <p>{localizeText(lesson?.description)}</p>
          <p>{!!lesson?.material && renderMaterial(lesson)}</p>
        </Styled.Description>
      </StyledPages.PageBgContainer>
    </div>
  );
});

export default Lesson;
