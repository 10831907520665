import styled from "styled-components";
import Success from "../../../assets/images/Success.svg"

const Result  = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 928px;
	height: 496px;
	background: #FFFFFF;
	box-shadow: 0px 5px 10px rgba(127, 141, 163, 0.1);
	border-radius: 8px;
	flex-direction: column;
`;

const ResultContent  = styled.div`
	display: flex;
	width: 350px;
`;

const ResultTitle  = styled.h2`
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 40px;
	text-align: center;
	margin-bottom: 16px;
`;

const ResultSubTitle  = styled.div`
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
	color: var(--gray);
`;

const IconSuccess  = styled.i`
	width: 80px;
	height: 80px;
	margin-bottom: 20px;
	display: inline-block;
	background: url(${Success}) 0 0 no-repeat;
	background-size: contain;
`;


export default {
	Result,
	ResultSubTitle,
	ResultTitle,
	IconSuccess,
	ResultContent
};
