import React from 'react';
import {FormattedMessage} from "react-intl";
import {Dropdown, Menu} from 'antd';
import {useDispatch} from "react-redux";
import Styled from './styled/StyledUserBlock';
import DropDownIcon from 'assets/images/dropdown.svg';
import {logoutAction} from 'services/logout/logout.action';
import {urlLocations} from "routes/urlLocations";

const DropdownMenu = ({}) => {
    const dispatch = useDispatch();
    return (
        <Menu>
            <Menu.Item key="1">
                <Styled.SettingIcon/>
                <Styled.ProfileLink to={urlLocations.profile}>
                    <FormattedMessage id="Header.ItemProfile"/>
                </Styled.ProfileLink>
            </Menu.Item>

            <Menu.Item key="0" onClick={() => dispatch(logoutAction())}>
                <Styled.LogoutIcon/>
                <Styled.LogoutLink>
                    <FormattedMessage id="Header.ItemLogout"/>
                </Styled.LogoutLink>
            </Menu.Item>
        </Menu>
    )
};


const UserBlock = ({fullName, username}) => {
    return (
        <Styled.UserBlock>
            <Dropdown trigger={['click']} getPopupContainer={trigger => trigger.parentNode} placement="bottomLeft"
                      overlayClassName="UserBlock" overlay={<DropdownMenu/>}>
	                <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
		                <Styled.UserIcon/>
		                <Styled.UserName>{fullName || username}</Styled.UserName>
                        <img src={DropDownIcon} alt=""/>
	                </span>
            </Dropdown>
        </Styled.UserBlock>
    );
};

export default UserBlock;