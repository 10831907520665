import React from "react";
import {reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";

import InputField from "components/InputField/InputField";
import {forgotPasswordAction} from "services/password/password.action";
import Styled from "./styled/StyledForgot";
import StyledAuth from "containers/styled/StyledAuth";
import {urlLocations} from "routes/urlLocations";
import {requiredFieldsValidate} from "utils/validation";

const requiredFields = ["email"];

const ForgotPasswordForm = React.memo(({ handleSubmit }) => {
  return (
    <Styled.ForgotContent>
      <form onSubmit={handleSubmit}>
        <InputField greenborderfocus name="email" label="ForgotPassword.Email" />

        <Styled.AuthButton htmlType="submit">
          <FormattedMessage id="ForgotPassword.Button" />
        </Styled.AuthButton>

        <StyledAuth.CenterLinkWrap>
          <StyledAuth.AuthLink to={urlLocations.registration}>
            <FormattedMessage id="Login.SignUp" />
          </StyledAuth.AuthLink>
        </StyledAuth.CenterLinkWrap>
      </form>
    </Styled.ForgotContent>
  );
});

export default reduxForm({
  form: "forgotPassword",
  validate: v => requiredFieldsValidate(v, requiredFields),
  onSubmit: (value, dispatch) => dispatch(forgotPasswordAction(value))
})(ForgotPasswordForm);
