import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import isEmpty from "lodash/isEmpty";

import * as trainTheTrainerSelectors from "services/trainTheTrainers/trainTheTrainer.selectors";
import {history} from "routes/urlLocations";
import {FormattedMessage} from "react-intl";
import Styled from './styled/StyledQuizResult';

const QuizResult = React.memo(() => {
  const result = useSelector(trainTheTrainerSelectors.resultSelector);

  useEffect(
    () => {
      isEmpty(result) && history.goBack();
    },
    [result]
  );

  return (
    <Styled.Result>

            <Styled.IconSuccess />
            <Styled.ResultTitle>
                <FormattedMessage id="TrainTheTrainer.Congratulation" />
            </Styled.ResultTitle>
            <Styled.ResultSubTitle>
                <FormattedMessage id="TrainTheTrainer.Result.Desc" />
            </Styled.ResultSubTitle>

    </Styled.Result>
  );
});

export default QuizResult;
