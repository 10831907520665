import React, {useEffect, useState} from "react";
import {Field} from "redux-form";
import {injectIntl} from "react-intl";

import Textarea from "components/Textarea/Textarea";
import ControlWrapper from "components/ControlWrapper/ControlWrapper";

const CustomTextarea = ({
  input: { name },
  input,
  formatMessage,
  setTouched,
  placeholder,
  type,
  errors,
  label,
  customTouched,
  ...meta
}) => (
  <>
    <ControlWrapper
      label={label}
      name={name}
      customTouched={customTouched}
      error={meta.meta.error}
      touched={meta.meta.touched}
    >
      <Textarea
        {...input}
        {...meta}
        onFocus={setTouched.bind(true)}
        type={type}
        placeholder={placeholder && formatMessage({ id: placeholder })}
      />
    </ControlWrapper>
  </>
);

const TextareaField = ({
  name,
  errors,
  intl: { formatMessage },
  placeholder,
  type,
  label
}) => {
  const [customTouched, setTouched] = useState(false);

  useEffect(
    () => {
      setTouched(false);
    },
    [errors]
  );

  return (
    <Field
      component={CustomTextarea}
      name={name}
      placeholder={placeholder}
      type={type}
      label={label}
      formatMessage={formatMessage}
      customTouched={customTouched}
      setTouched={setTouched}
      errors={errors}
    />
  );
};

export default injectIntl(TextareaField);
