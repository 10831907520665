import styled, {css} from "styled-components";
import React from "react";

const QuestionTxt = styled.h3`
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	padding-bottom: 16px;
`;

const QuestionList = styled.div`
	list-style: none;
	display: flex;
	flex-direction: column;
	padding: 0;
`;
const QuestionItem = styled.div`
	
`;

const AnswersList = styled.div`
	list-style: none;
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	margin: 0 0 48px 0;
	padding: 0;
	justify-content: space-between ;
	
`;
const Answer = styled.div`
	&& { display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	@media (min-width: 1024px) {
		 width: 48%; margin-bottom: 0;	 
	}
	max-width: 380px;
	min-height: 40px;
	background: #FFFFFF;
	border: 1px solid #4283C1;
	box-sizing: border-box;
	border-radius: 5px;
	color: var(--dark);
	margin: 8px 0;
	cursor: pointer;
	padding: 10px;
	transition: all 0.2s ease-in;
	${({isactive}) => {
	if (isactive) {
		return css`
		               background: var(--blue);
		               border: 1px solid #4283C1; 
		               color: #fff; 
		                `
	}
}}
	&:hover {
	 	background: var(--blue);
		border: 1px solid #4283C1; 
		color: #fff; 
	}
	}
`;
const Result = styled.div`
	display: flex;
	width: 100%;
	min-height: 158px;
	flex-direction: column;
	padding: 2px;
	margin-top: 16px;
	border-radius: 5px;	
	
	${({  bgColor }) => {
		if( bgColor) {
			return css`
		           background: ${bgColor}; 
`
		}
	}}	
`;

const ResultTextBlock = styled.div`
	min-height: 80px;
	background: #FFFFFF;
	border-radius: 5px;
	padding: 10px 20px ;
`;
const ResultBlock  = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 48px;
`;
const ResultHeader = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 20px 21px 15px  21px;
	
`;
const ResultTitle = styled.h5`
	font-weight: bold;
	font-size: 18px;
	line-height: 24px;
	padding: 0;
	display: inline-block;
	width: 100%;
	color: #fff;	
`;
const ResultIcon = styled.div`
	width: 20px;
	height: 20px;
	display: inline-block;
	margin-right: 10px;
	background-size: cover;
	position: relative;
    top: -3px;
    	
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;
const ResultLabel = styled.h4`
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	padding: 0;
`;

const TryAgain = styled.div`
	color: var(--blue);
	padding-top: 16px;
	padding-bottom: 16px;
	text-decoration: underline;
	width: 100%;
	display: inline-block;
	cursor: pointer;
	&:hover {
		color: var(--blue);
		text-decoration: underline;
	}
`;

export default {
	QuestionTxt,
	AnswersList,
	Answer,
	QuestionList,
	QuestionItem,
	Result,
	ResultTextBlock,
	TryAgain,
	ResultTitle,
	ResultLabel,
	ResultBlock,
	ResultHeader,
	ResultIcon
};
