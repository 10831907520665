import React from "react";
import {FormattedMessage} from "react-intl";

import ForgotPasswordForm from "./ForgotPassword.Form";
import Styled from "../styled/StyledAuth";
import AuthFooter from "components/Footer/AuthFooter";

const ForgotPassword = React.memo(() => {
  return (
    <Styled.Page>
      <Styled.Logo to={"/"} />
      <Styled.Title>
        <FormattedMessage id="ForgotPassword.Title" />
      </Styled.Title>
      <Styled.Subtitle>
        <FormattedMessage id="ForgotPassword.Desc" />
      </Styled.Subtitle>
      <ForgotPasswordForm initialValues={{ email: null }} />
      <AuthFooter />
    </Styled.Page>
  );
});

export default ForgotPassword;
