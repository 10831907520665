import React from "react";
import {useLocation} from "react-router-dom";
import {FormattedMessage} from "react-intl";

import {NAVIGATION} from "./Aside.constants";
import {isUrlMatch} from "services/router/router.utils";
import Styled from './styled/StyledAside';

const Aside = React.memo(({visibilityAside, onChangeVisibility}) => {
    const location = useLocation();

    return (
        <Styled.Aside visibilityAside={visibilityAside}>
            <Styled.CloseIcon visibilityAside={visibilityAside} onClick={() => onChangeVisibility(visibilityAside)}/>
            <Styled.LogoAside></Styled.LogoAside>
            <Styled.Nav>
                {NAVIGATION.map(item => (
                    <Styled.ItemNav disabled={!item.path}
                                    isactive={(!!isUrlMatch({location}, item.location || item.path))} key={item.title}>
                        <Styled.LinkNav disabled={!item.path}
                                        to={item.path}
                        ><Styled.Icon><img src={item.img} alt=""/></Styled.Icon>

                          {item.logo ? <Styled.Logo src={item.logo} alt=""/> : <FormattedMessage id={item.title}/>}
                        </Styled.LinkNav>
                    </Styled.ItemNav>
                ))}
                <Styled.Version>
                    <FormattedMessage id="Aside.Version"/>
                </Styled.Version>
            </Styled.Nav>
        </Styled.Aside>
    );
});

export default Aside;
