import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";

import RichHtmlWrapper from "components/RichHTMLWrapper/RichHTMLWrapper";
import Button from "components/Button/Button";
import { urlLocations } from "routes/urlLocations";
import StyledPages from "containers/styled/StyledPages";
import Styled from  "./styled/StyledRiskInfo";

const testPath = {
  [urlLocations.risk]: urlLocations.riskTest,
  [urlLocations.publicRisk]: urlLocations.publicRiskTest,
};

const HISTORY = [
  {
    key: "Low",
    color:"var(--gradientGreen)"
  },
  {
    key: "Medium",
    color: "var(--gradientOrange)",
  },
  {
    key: "High",
    color: "var(--red)",
  },
];

const RiskInfo = React.memo(() => {
  const { pathname } = useLocation();

  return (
    <div>
      <StyledPages.PageTitle>
        <FormattedMessage id="RiskInfo.Title" />
      </StyledPages.PageTitle>
      <p>
         <RichHtmlWrapper id="RiskInfo.Desc" />
      </p>
      <p>
        <RichHtmlWrapper id="RiskInfo.List" />
      </p>

        <Styled.RiskInfo>
              {HISTORY.map((item) => (
                  <Styled.RiskInfoRow>
                      <Styled.RiskInfoColLeft>
                          <Styled.RiskInfoItem bgColor={item.color}>
                              <FormattedMessage id={`RiskInfo.${item.key}.Title`} />
                          </Styled.RiskInfoItem>
                      </Styled.RiskInfoColLeft>

                      <Styled.RiskInfoColRight>
                          <Styled.RiskInfoItem bgColor={item.color}>
                              <FormattedMessage id={`RiskInfo.${item.key}.Desc`} />
                          </Styled.RiskInfoItem>
                      </Styled.RiskInfoColRight>
                  </Styled.RiskInfoRow>
              ))}
        </Styled.RiskInfo>
        <Styled.ButtonsRow>
            <Link to={testPath[pathname]} component={Button}>
                <FormattedMessage id="RiskInfo.Button" />
            </Link>
        </Styled.ButtonsRow>

    </div>
  );
});

export default RiskInfo;
