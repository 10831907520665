export default {
  profile: {},
  library: [],
  auditorium: [],
  modals: null,
  loader: false,
  favorites: {
    list: [],
    ids: [],
  },
  trainTheTrainer: {
    courses: [],
    result: null,
  },
  appInfo: {},
  risk: {
    test: [],
    result: null,
  },
  podcasts: {
    playlists: null,
    progress: null,
    episodes: null,
    playableEpisode: null,
    playablePodcast: null,
    isPlaying: false,
    playableEpisodeProgress: 0,
  },
};
