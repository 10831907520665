import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const Playlist = styled(Link)`
  @media (max-width: 767px) {
    width: 100%;
    margin-left: 10px;
  }
  margin-bottom: 15px;
  &:hover {
    text-decoration: none;
  }
  ${({ iscarousel }) => {
    if (iscarousel) {
      return css`
        //max-width: 300px;
        padding-right: 15px;
        padding-left: 15px;
        @media (max-width: 580px) {
          padding-right: 10px;
          padding-left: 10px;
        }
        @media (max-width: 999px) {
          margin-left: 0;
          width: 50%;
        }
        @media (min-width: 1000px) and (max-width: 1349px) {
          min-height: 415px;
        }

        @media (min-width: 1000px) and (max-width: 1349px) {
          width: 33%;
        }
      `;
    }
  }}
`;

const Tile = styled.div`
  width: 100%;
  box-shadow: 0px 5px 10px rgba(127, 141, 163, 0.1);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  margin-bottom: 16px;
  max-width: 288px;
  img {
    object-fit: cover;
    height: 100%;
    max-width: 100%;
  }
  ${({ iscarousel }) => {
    if (iscarousel) {
      return css`
        width: 288px;
        height: 288px;
        max-height: 288px;
        @media (max-width: 768px) {
          width: 100%;
          height: 166px;
          object-fit: contain;
          img {
            width: 100%;
          }
        }
        @media (min-width: 768px) and (max-width: 1000px) {
          width: 100%;
          height: 100%;
          object-fit: contain;
          img {
            min-height: 170px;
          }
        }
        @media (min-width: 1001px) and (max-width: 1349px) {
          width: 100%;
          height: 100%;
        }
        @media (min-width: 1350px) {
          width: 288px;
        }
      `;
    } else {
      return css`
        height: 288px;
      `;
    }
  }}
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #424e6f;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  max-height: 54px;
  max-width: 288px;

  @media (max-width: 767px) {
    width: 100%;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    white-space: nowrap;
    overflow: hidden;
    max-height: 24px;
    font-size: 16px;
  }
`;

const Tags = styled.div`
  margin: 5px 0;
`;

const Tag = styled.span`
  display: inline-block;
  color: #9a9fad;
  margin-right: 15px;
`;

const EpisodesCount = styled.div`
  color: #9a9fad;
  @media (max-width: 767px) {
    font-size: 15px;
  }
`;
const Status = styled.div`
  width: 20px;
  height: 20px;
  border: 0px solid #fff;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  top: 16px;
  .anticon {
    position: absolute;
    font-size: 10px !important;
    color: #fff;
    left: 50% !important;
    top: 25%;
    transform: translateX(-50%);
    visibility: hidden;
  }
  ${(props) =>
    props.isFinished &&
    css`
      background: linear-gradient(267.93deg, #1b728b 13.19%, #07546d 80.29%);
      border: none;
      .anticon {
        visibility: initial;
      }
    `}
`;

const ActionIcon = styled.div`
  width: 32px;
  height: 32px;
  background: linear-gradient(267.93deg, #1b728b 13.19%, #07546d 80.29%);
  border-radius: 50%;
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .anticon {
    position: absolute;
    top: 50%;
    left: 50% !important;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 19px !important;
    margin-left: 1px;
  }
`;

export default {
  Playlist,
  Tile,
  Tag,
  Tags,
  Title,
  EpisodesCount,
  Status,
  ActionIcon,
};
