export default {
  //Lang
  "Lang.Eng": "English",
  "Lang.Ar": "العربية",
  "Lang.Tr": "Türk",
  "Lang.Fr": "Français",
  "Lang.Ur": "اردو",

  Search: "Search",

  //Header
  "Header.ItemLogout": "Logout",
  "Header.ItemProfile": "Profile Setting",

  //Aside
  "Aside.Dashboard": "Dashboard",
  "Aside.Podcasts": "Podcasts",
  "Aside.Library": "Library",
  "Aside.Auditorium": "Auditorium",
  "Aside.Experts": "Experts",
  "Aside.TrainTheTrainer": "Train the Trainer",
  "Aside.About": "About",
  "Aside.Help": "Help",
  "Aside.AdditionalInfo": "Additional Info",
  "Aside.RiskCalculator": "Risk Calculator",
  "Aside.Version": "Version 3.5.10",

  //Footer
  "Footer.Desc":
    "The preparation of this app was made possible through an unrestricted education grant provided by Sanofi Gulf Countries\n© 2020 DAR alliance. All rights reserved",
  "Footer.Desc.Servier":
    "The preparation of this PODCAST was made possible through an unrestricted education support provided by SERVIER GCC\n© 2020 DAR alliance. All rights reserved",

  //Login
  "Login.Title": "Log in to your account",
  "Login.Desc": "It’s great to see you back",
  "Login.Button": "Login",
  "Login.SignUp": "Don’t have an account? Sign Up",
  "Login.Email": "Email",
  "Login.Password": "Password",
  "Login.RememberMe": "Remember Me",
  "Login.ForgotPassword": "Forgot a password?",
  "Login.TermsOfUse": "Terms Of Use",
  "Login.PrivacyPolicy": "Privacy Policy",
  "Login.Info": "I accept the { terms } & { privacy }",

  //Forgot password
  "ForgotPassword.Title": "Forgot a password?",
  "ForgotPassword.Button": "Reset password",
  "ForgotPassword.Email": "Email",
  "ForgotPassword.Desc":
    "Enter your email address and we will send you a link to reset your password",

  //Set new password

  "NewPassword.Title": "Forgot a password?",
  "NewPassword.Desc": "Create a new password and confirm it",
  "NewPassword.NewPassword": "New Password",
  "NewPassword.ConfirmPassword": "Confirm Password",
  "NewPassword.Button": "Reset Password",

  //Registration
  "Registration.Title": "Log in to your account",
  "Registration.Desc": "Please fill in the form to create an account",
  "Registration.Email": "Email",
  "Registration.FullName": "Full Name",
  "Registration.Password": "Password",
  "Registration.ConfirmPassword": "Confirm Password",
  "Registration.Speciality": "Speciality",
  "Registration.PlaceOfWork": "Place of work",
  "Registration.Country": "Country",
  "Registration.City": "City",
  "Registration.Phone": "Contact number / Phone",
  "Registration.Button": "Register",
  "Registration.SignIn": "Don’t have an account? Sign Up",
  "Registration.Login": "Do you have an account? Login",

  //Dashboard
  "Dashboard.Title": "Welcome to Dar Academy",
  "Dashboard.Desc": "Your personal diabetes assistant in Ramadan",
  "Dashboard.Library.Title": "Library",
  "Dashboard.Library.Desc": "Browse guidelines, articles and publications.",
  "Dashboard.Auditorium.Title": "Auditorium",
  "Dashboard.Auditorium.Desc": "Watch archived lectures, web-casts and live.",
  "Dashboard.Experts.Title": "Experts",
  "Dashboard.Experts.Desc":
    "Our experts can help you, just send your question.",
  "Dashboard.TrainTheTrainer.Title": "Train the Trainer",
  "Dashboard.TrainTheTrainer.Desc": "Get exposed to much more knowledge.",

  "Dashboard.Podcasts.Title": "Dia Voice Podcasts",
  "Dashboard.Podcasts.Desc": "The Voice of Diabetes",

  //Library
  "Library.Title": "Library & References",
  "Library.Explore": "Explore more",
  "Library.Author": "Author",

  //Auditorium
  "Auditorium.Title": "Auditorium",
  "Auditorium.Tabs.Videos": "Videos",
  "Auditorium.Tabs.Lectures": "Lectures",
  "Auditorium.By": "By",

  //Profile
  "Profile.Title": "Profile",
  "Profile.MainInformation": "Main Information",
  "Profile.FullName": "Full Name",
  "Profile.Email": "Email",
  "Profile.Phone": "Phone",
  "Profile.Password": "Password",
  "Profile.AdditionalInformation": "Additional Information",
  "Profile.Speciality": "Speciality",
  "Profile.Country": "Country",
  "Profile.PlaceOfWork": "Place of work",
  "Profile.Button": "Update information",
  "Profile.Logout": "Logout",
  "Profile.ChangePassword": "Change password",
  "Profile.ChangePassword.Title": "Change password",
  "Profile.CurrentPassword": "Current Password",
  "Profile.NewPassword": "New Password",
  "Profile.ConfirmPassword": "Confirm New Password",
  "Profile.ChangePassword.Button": "Change",

  //Experts
  "Experts.Title": "Experts’ Corridor",
  "Experts.Desc":
    "Our Experts can help you, Just select the category and ask our experts.",
  "Experts.DiabetesConsultant.Title": "Diabetes Consultant",
  "Experts.DiabetesConsultant.Desc":
    "Our panel of diabetes consultants will answer your medical related questions via email.",
  "Experts.DiabetesNurseEducator.Title": "Diabetes Nurse Educator",
  "Experts.DiabetesNurseEducator.Desc":
    "Our group of diabetes nurse educators will answer your questions via email.",
  "Experts.Dietician.Title": "Dietician",
  "Experts.Dietician.Desc":
    "Our panel of dietitians will answer your food during Ramadan related questions via email.",
  "Experts.ReligiousScholar.Title": "Religious Scholar",
  "Experts.ReligiousScholar.Desc":
    "Our panel of esteemed religious scholars will answer your fasting related questions via email.",

  //Expert
  "Expert.Back": "Back to all categories",
  "Expert.Subject": "Subject",
  "Expert.Email": "Email",
  "Expert.Question": "Please enter question details",
  "Expert.Send": "Send request",
  "Expert.Desc":
    "Our panel of dietitians will answer yourfood during Ramadan related question via email",

  //Train The Trainer
  "TrainTheTrainer.Title": "Train the Trainer",
  "TrainTheTrainer.Author": "Author",
  "TrainTheTrainer.Explore": "Explore more",
  "TrainTheTrainer.Lesson": "Lesson",
  "TrainTheTrainer.Quiz": "Take a quiz",
  "TrainTheTrainer.Back": "Back",
  "TrainTheTrainer.Skip": "Skip",
  "TrainTheTrainer.QuestionsCount": "Question { current } of { count }",
  "TrainTheTrainer.Congratulation": "Congratulation!",
  "TrainTheTrainer.Result.Desc": "You pass the quiz",
  "TrainTheTrainer.AdditionalMaterial": "Additional material",
  "TrainTheTrainer.Modal.Title": "Not Invited",
  "TrainTheTrainer.Modal.Desc": "You are not invited to visit this section.",
  "TrainTheTrainer.Modal.Button": "Back",

  //Risk Calculator
  "RiskCalculator.Title": "Risk Calculator",
  "RiskCalculator.Button": "Show result of risk",
  "RiskCalculator.Result": "Your result",
  "RiskCalculator.TryAgain": "Try Again",
  "RiskCalculator.Desc":
    "Calculate risk of a person living with diabetes before opting to fast.",
  "RiskCalculator.Result.Low.Title": "Low Risk",
  "RiskCalculator.Result.Medium.Title": "Medium Risk",
  "RiskCalculator.Result.High.Title": "High Risk",
  "RiskCalculator.Result.Low.Desc":
    "Those at the low risk level should be able to fast.\nThese individuals are at a lower risk of in terms of complications arising when fasting during Ramadan. However as mentioned, circumstances can change leading to a change in the risk scoring. Therefore, risk stratification should be conducted annually to review the level of risk in advance of Ramadan.",
  "RiskCalculator.Result.Medium.Desc":
    "Those at the moderate risk level are advised not to fast.\nAs previously mentioned, many of these patients will choose to fast anyway. This important personal decision should be made after consideration of the associated risks in consultation with HCPs. They also need to be aware of the techniques or strategies to decrease this risk. If individuals choose to fast, then they would need to be cautious and discontinue fasting if any problems arise.",
  "RiskCalculator.Result.High.Desc":
    "Individuals who are in the high risk category should not fast.\nThese individuals are of high-very high risk of developing complications when fasting during Ramadan. We recommend that these individuals do not fast. If they do still insist on fasting the utmost care and monitoring should be provided alongside the strategies and recommendations mentioned above and in the other chapters of these guidelines.",

  //Risk info
  "RiskInfo.Title":
    "IDF – DAR Risk Calculator:\nFor Healthcare Professionals Only",
  "RiskInfo.Desc":
    "<p>Calculate risk of a person living with diabetes before opting to fast using the <a target='_blank' href='https://www.idf.org/our-activities/education/diabetes-and-ramadan/healthcare-professionals.html'>2021, IDF-DAR risk stratification algorithm published in the IDF-DAR Practical Guidelines</a>, chapter 5: Risk stratification of people with diabetes before Ramadan.</p>",
  "RiskInfo.List":
    "<ul><li>The new IDF-DAR risk stratification defines three risk categories and provides a risk score that includes multiple factors that play an important role in the fasting recommendation for each category i.e. low risk, moderate risk and high risk</li> <li>The scoring system was designed considering different factors which influence fasting. </li><li> For a given individual, each risk element should be assessed, and the score should be totaled.</li><li>The resulting score will determine the overall risk level for an individual with diabetes that is seeking to fast during Ramadan.</li></ul>",
  "RiskInfo.Low.Title": "Score 0 to 3",
  "RiskInfo.Low.Desc": "Low risk",
  "RiskInfo.Medium.Title": "Score 3.5 to 6",
  "RiskInfo.Medium.Desc": "Moderate risk",
  "RiskInfo.High.Title": "Score > 6",
  "RiskInfo.High.Desc": "High risk",
  "RiskInfo.Button": "Start now",

  //Favorites
  "Favorites.Tabs.Auditorium": "Auditorium",
  "Favorites.Tabs.Library": "Library",
  "Favorites.Tabs.TrainTheTrainer": "Train The Trainer",

  //About
  "About.Content":
    "<h1>About DaR Academy</h1><h2>Library</h2><p>Open library to browse and view videos, guidelines, articles and publications\nYou can add items to favorites and watch later from the favorites section</p><h2>Auditorium<h2><p>To watch archived lectures, webcasts and live streaming videos</p><h2>Experts</h2><p>Our experts can help you, just select category, fill the form and send your question, and you should receive answers by email</br>To know more about experts in each category, open the category and type your question</p><h2>My Account</h2><p>You can manage your account profile and change password</p></br></br><p>The preparation of this Web Portal was made possible through an unrestricted educational grant provided by Sanofi Gulf Countries</br>© 2018 DAR alliance. All rights reserved</p>",

  "Help.Content":
    "<h1>Help</h1><h2>Library</h2><p>Open library to browse and view videos, guidelines, articles and publications\nYou can add items to favorites and watch later from the favorites section</p><h2>Auditorium<h2><p>To watch archived lectures, webcasts and live streaming videos</p><h2>Experts</h2><p>Our experts can help you, just select category, fill the form and send your question, and you should receive answers by email</br>To know more about experts in each category, open the category and type your question</p><h2>My Account</h2><p>You can manage your account profile and change password</p></br></br><p>The preparation of this Web Portal was made possible through an unrestricted educational grant provided by Sanofi Gulf Countries</br>© 2018 DAR alliance. All rights reserved</p>",
  "Help.Text": "Develop by OTB Life Science & Technology Consultancy FZ-LLC.",

  //Validation
  "Validation.FieldIsRequired": "Field Is Required",

  //Notifications
  "Notifications.Error": "Error",
  "Notifications.SomethingWentWrong":
    "Oops! Something went wrong!.\nPlease try again later, or contact the system admin if you are still facing this problem",
  "Login.Success.Title": "Success",
  "Login.Success.Desc": "Success",
  "Registration.Success.Title": "Success",
  "Registration.Success.Desc": "Success",

  "ForgotPassword.Success.Title": "Success",
  "ForgotPassword.Success.Desc": "Success",

  "SetNewPassword.Success.Title": "Success",
  "SetNewPassword.Success.Desc": "Success",

  "Profile.Success.Title": "Success",
  "Profile.Success.Desc": "Success",

  "ChangePassword.Success.Title": "Success",
  "ChangePassword.Success.Desc": "Success",

  "Expert.Success.Title": "Success",
  "Expert.Success.Desc": "Success",

  "Auth.form.error.email.taken": "Email is already taken",
  "Auth.form.error.email.format": "Please provide valid email address",
  "Auth.form.error.invalid": "Email or password invalid",
  "Auth.form.error.user.not-exist": "This email does not exist",

  "Podcasts.Title": "Podcasts",
  "Podcasts.TopPodcasts": "Top podcasts",
  "Podcasts.ViewAll": "View all",
  "Podcasts.Episodes": "Episodes",
  "Podcasts.Back": "Back",
  "Podcasts.More": "more",
  "Podcasts.Less": "Less",
  "Podcasts.Episode": "episode",
  "Podcasts.Min": "min",
  "Podcasts.Listened": "Listened",
  "Podcasts.Follow": "Follow",
  "Podcasts.AllEpisodes": "All episodes",

  "Podcasts.PlayFirstEpisode": "Play first episode",
  "Podcasts.Search": "Search",
  "Podcasts.MinLeft": "minutes left",
  "Podcasts.SecLeft": "seconds left",
  "Podcasts.folder.FRENCH": "French",
  "Podcasts.folder.OTHER": "Others"
};
