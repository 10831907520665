import * as trainTheTrainer from "./trainTheTrainer.actions";
import initialState from "store/initialState";

export default (state = initialState.trainTheTrainer, action) => {
  switch (action.type) {
    case trainTheTrainer.GET_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.payload,
      };
    case trainTheTrainer.SUBMIT_ANSWER_SUCCESS:
      return {
        ...state,
        result: action.payload,
      };
    default:
      return state;
  }
};
