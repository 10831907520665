export default {
  "About.Content": "<h1> نبذة عن أكاديمية دار </h1> <h2> مكتبة </h2> <p> فتح مكتبة لتصفح وعرض مقاطع الفيديو والمبادئ التوجيهية والمقالات والمنشورات\nيمكنك إضافة عناصر إلى المفضلة ومشاهدتها لاحقًا من قسم المفضلة </p> <h2> قاعة المحاضرات <h2> <p> لمشاهدة المحاضرات المؤرشفة والبث الشبكي ومقاطع الفيديو المباشرة </p> <h2> خبراء </h2> <p> يمكن لخبرائنا مساعدتك، ما عليك سوى اختيار الفئة، وملء النموذج وإرسال سؤالك، ويجب أن تتلقى إجابات عن طريق البريد الإلكتروني </br> لمعرفة المزيد عن الخبراء في كل فئة، افتح الفئة واكتب سؤالك </p> <h2> حسابي </h2> <p> يمكنك إدارة الملف الشخصي لحسابك وتغيير كلمة المرور </p> </br> </br> <p> وقد أمكن إعداد هذه البوابة الإلكترونية من خلال منحة تعليمية غير مقيدة قدمتها دول الخليج </br> © 2018 تحالف دار. جميع الحقوق محفوظة </p>",
  "Aside.About": "حول التطبيق",
  "Aside.AdditionalInfo": "معلومات إضافية:",
  "Aside.Auditorium": "قاعة المحاضرات",
  "Aside.Dashboard": "القائمة الرئيسية",
  "Aside.Experts": "مكاتب الخبراء",
  "Aside.Help": "مساعدة",
  "Aside.Library": "المكتبة",
  "Aside.RiskCalculator": "حاسبة المخاطر",
  "Aside.TrainTheTrainer": "برنامج التدريب",
  "Aside.Podcasts": "المدونة الصوتية",
  "Aside.Version": "الإصدار 3.5.10",
  "Auditorium.By": "بواسطة",
  "Auditorium.Tabs.Lectures": "محاضرات",
  "Auditorium.Tabs.Videos": "مقاطع الفيديو",
  "Auditorium.Title": "قاعة المحاضرات",
  "Auth.form.error.email.format": "يرجى إدخال عنوان بريد إلكتروني صالح.",
  "Auth.form.error.email.taken": "البريد الإلكتروني مأخوذ بالفعل",
  "Auth.form.error.invalid": "البريد الالكتروني أو كلمة المرور غير صالحة",
  "Auth.form.error.user.not-exist": "هذا البريد الإلكتروني غير موجود",
  "ChangePassword.Success.Desc": "تمت العملية بنجاح",
  "ChangePassword.Success.Title": "نجاح",
  "Dashboard.Auditorium.Desc": "مشاهدة المحاضرات المسجلة وفيديوهات النصائح السريعة",
  "Dashboard.Auditorium.Title": "قاعة المحاضرات",
  "Dashboard.Desc": "مساعدك الشخصي لمرض السكري في رمضان",
  "Dashboard.Experts.Desc": "يمكن لخبرائنا مساعدتك، فقط أرسل سؤالك.",
  "Dashboard.Experts.Title": "مكاتب الخبراء",
  "Dashboard.Library.Desc": "تصفح المبادئ التوجيهية والمقالات والمنشورات.",
  "Dashboard.Library.Title": "المكتبة",
  "Dashboard.Title": "أهلاً بكم في أكاديمية دار",
  "Dashboard.TrainTheTrainer.Desc": "الحصول على معرفه أكثر من خلال برامج تدريب متكاملة.",
  "Dashboard.TrainTheTrainer.Title": "برنامج التدريب",
  "Dashboard.Podcasts.Title": "المدونة الصوتية",
  "Dashboard.Podcasts.Desc": "المدونة الصوتية",

  "Expert.Back": "العودة إلى جميع الفئات",
  "Expert.Desc": "لدينا فريق من أخصائيي التغذية سوف يجيب على طعامك خلال السؤال المتعلق بشهر رمضان عبر البريد الإلكتروني",
  "Expert.Email": "البريد الإلكتروني",
  "Expert.Question": "الرجاء إدخال تفاصيل السؤال",
  "Expert.Send": "ارسل طلب",
  "Expert.Subject": "الموضوع",
  "Expert.Success.Desc": "تمت العملية بنجاح",
  "Expert.Success.Title": "نجاح",
  "Experts.Desc": "خبرائنا يمكنهم مساعدتك، فقط قم باختيار المجال التي تريد السؤال",
  "Experts.DiabetesConsultant.Desc": "ستجيب فريقنا من مستشاري مرض السكري على أسئلتك الطبية ذات الصلة عبر البريد الإلكتروني.",
  "Experts.DiabetesConsultant.Title": "استشاري السكري",
  "Experts.DiabetesNurseEducator.Desc": "ستجيب مجموعتنا من معلمي مرض السكري على أسئلتك عبر البريد الإلكتروني.",
  "Experts.DiabetesNurseEducator.Title": "تثقيفي السكري",
  "Experts.Dietician.Desc": "سيقوم فريق خبراء التغذية لدينا بالإجابة على الأسئلة المتعلقة بطعامك في رمضان عبر البريد الإلكتروني",
  "Experts.Dietician.Title": "أخصائي التغذية",
  "Experts.ReligiousScholar.Desc": "سيجيب فريق من الباحثين الديننين المحترمين على أسئلة المتعلقة بالصيام عبر البريد الإلكتروني",
  "Experts.ReligiousScholar.Title": "الباحث الديني",
  "Experts.Title": "مكاتب الخبراء",
  "Favorites.Tabs.Auditorium": "قاعة المحاضرات",
  "Favorites.Tabs.Library": "المكتبة",
  "Favorites.Tabs.TrainTheTrainer": "برنامج التدريب",
  "Footer.Desc": "Sanofi gulf countries تم إعداد هذه البوابة الإلكترونية من خلال منحة تعليمية غير مقيدة مقدمة من\nDAR alliance. جميع الحقوق محفوظة © 2018",
  "Footer.Desc.Servier":
    "The preparation of this PODCAST was made possible through an unrestricted education support provided by SERVIER GCC\n© 2020 DAR alliance. All rights reserved",
  "ForgotPassword.Button": "اعادة ضبط كلمة المرور",
  "ForgotPassword.Desc": "أدخل عنوان بريدك الإلكتروني وسوف نرسل لك رابطًا لتغيير كلمة المرور الخاصة بك",
  "ForgotPassword.Email": "البريد الإلكتروني",
  "ForgotPassword.Success.Desc": "نجاح",
  "ForgotPassword.Success.Title": "تمت العملية بنجاح",
  "ForgotPassword.Title": "هل نسيت كلمة المرور؟",
  "Header.ItemLogout": "تسجيل الخروج",
  "Header.ItemProfile": "الملف الشخصي",
  "Help.Content": "<h1> مساعدة </h1> <h2> مكتبة </h2> <p> فتح مكتبة لتصفح وعرض مقاطع الفيديو والمبادئ التوجيهية والمقالات والمنشورات\nيمكنك إضافة عناصر إلى المفضلة ومشاهدتها لاحقًا من قسم المفضلة </p> <h2> قاعة المحاضرات <h2> <p> لمشاهدة المحاضرات المؤرشفة والبث الشبكي ومقاطع الفيديو المباشرة </p> <h2> خبراء </h2> <p> يمكن لخبرائنا مساعدتك، ما عليك سوى اختيار الفئة، وملء النموذج وإرسال سؤالك، ويجب أن تتلقى إجابات عن طريق البريد الإلكتروني </br> لمعرفة المزيد عن الخبراء في كل فئة، افتح الفئة واكتب سؤالك </p> <h2> حسابي </h2> <p> يمكنك إدارة الملف الشخصي لحسابك وتغيير كلمة المرور </p> </br> </br> <p> وقد أمكن إعداد هذه البوابة الإلكترونية من خلال منحة تعليمية غير مقيدة قدمتها دول الخليج </br> © 2018 تحالف دار. جميع الحقوق محفوظة </p>",
  "Help.Text": 'Develop by OTB Life Science & Technology Consultancy FZ-LLC.',
  "Lang.Ar": "العربية",
  "Lang.Eng": "English",
  "Lang.Fr": "Français",
  "Lang.Tr": "Türk",
  "Lang.Ur": "اردو",
  "Library.Author": "المصدر",
  "Library.Explore": "استكشف المزيد",
  "Library.Title": "المكتبة والمراجع",
  "Login.Button": "دخول",
  "Login.Desc": "شكرا لاستخدامك التطبيق مرة اخرى",
  "Login.Email": "البريد الإلكتروني",
  "Login.ForgotPassword": "هل نسيت كلمة المرور؟",
  "Login.Info": "أوافق { terms } & { privacy }",
  "Login.Password": "كلمة المرور",
  "Login.PrivacyPolicy": "سياسة الخصوصية",
  "Login.RememberMe": "تذكرني",
  "Login.SignUp": "ليس لديك حساب؟ سجل الآن",
  "Login.Success.Desc": "تمت العملية بنجاح",
  "Login.Success.Title": "نجاح",
  "Login.TermsOfUse": "شروط الاستخدام",
  "Login.Title": "تسجيل الدخول إلى حسابك",
  "NewPassword.Button": "اعادة ضبط كلمة المرور",
  "NewPassword.ConfirmPassword": "تأكيد كلمة المرور",
  "NewPassword.Desc": "إنشاء كلمة مرور جديدة وتأكيدها",
  "NewPassword.NewPassword": "كلمة المرور الجديدة",
  "NewPassword.Title": "هل نسيت كلمة المرور؟",
  "Notifications.Error": "خطأ",
  "Notifications.SomethingWentWrong": "عفوا! حدث خطأ ما!\nيرجى المحاولة مرة أخرى في وقت لاحق، أو الاتصال بمسؤول النظام إذا كنت لا تزال تواجه هذه المشكلة",
  "Profile.AdditionalInformation": "معلومة اضافية",
  "Profile.Button": "تحديث المعلومات",
  "Profile.ChangePassword": "تغيير كلمة المرور",
  "Profile.ChangePassword.Button": "تغيير",
  "Profile.ChangePassword.Title": "تغيير كلمة المرور",
  "Profile.ConfirmPassword": "تأكيد كلمة المرور الجديدة",
  "Profile.Country": "الدولة",
  "Profile.CurrentPassword": "كلمة السر الحالية",
  "Profile.Email": "البريد الإلكتروني",
  "Profile.FullName": "الاسم بالكامل",
  "Profile.Logout": "تسجيل الخروج",
  "Profile.MainInformation": "المعلومات الرئيسية",
  "Profile.NewPassword": "كلمة المرور الجديدة",
  "Profile.Password": "كلمة المرور",
  "Profile.Phone": "الهاتف",
  "Profile.PlaceOfWork": "مكان العمل",
  "Profile.Speciality": "التخصص",
  "Profile.Success.Desc": "تمت العملية بنجاح",
  "Profile.Success.Title": "نجاح",
  "Profile.Title": "الملف الشخصي",
  "Registration.Button": "تسجيل",
  "Registration.City": "المدينة",
  "Registration.ConfirmPassword": "تأكيد كلمة المرور",
  "Registration.Country": "الدولة",
  "Registration.Desc": "يرجى ملء البيانات لإنشاء حساب",
  "Registration.Email": "البريد الإلكتروني",
  "Registration.FullName": "الاسم بالكامل",
  "Registration.Login": "هل لديك حساب؟ تسجيل الدخول",
  "Registration.Password": "كلمة المرور",
  "Registration.Phone": "رقم الهاتف*",
  "Registration.PlaceOfWork": "مكان العمل",
  "Registration.SignIn": "ليس لديك حساب؟ سجل الآن",
  "Registration.Speciality": "التخصص",
  "Registration.Success.Desc": "تمت العملية بنجاح",
  "Registration.Success.Title": "تمت العملية بنجاح",
  "Registration.Title": "تسجيل الدخول إلى حسابك",
  "RiskCalculator.Button": "إظهار نتيجة المخاطر",
  "RiskCalculator.Desc": "حساب خطر شخص مصاب بمرض السكري قبل اختيار الصيام.",
  "RiskCalculator.Result": "نتيجتك",
  "RiskCalculator.Result.High.Desc": "الأفراد الذين هم في فئة عالية المخاطر لا ينبغي أن يصومون.\nهؤلاء الأفراد هم من مخاطر عالية جدا للإصابة بمضاعفات عند الصيام خلال شهر رمضان. نوصي بأن هؤلاء الأفراد لا يصومون. إذا كانوا لا يزالون يصرون على الصيام يجب توفير أقصى قدر من الرعاية والرصد جنبا إلى جنب مع الاستراتيجيات والتوصيات المذكورة أعلاه وفي الفصول الأخرى من هذه المبادئ التوجيهية.",
  "RiskCalculator.Result.High.Title": "مخاطرة عالية",
  "RiskCalculator.Result.Low.Desc": "أولئك الذين هم على مستوى منخفض المخاطر يمكن ان يكونو قادرين على الصيام.\nهؤلاء الأفراد هم في خطر أقل من حيث المضاعفات التي تنشأ عند الصيام خلال شهر رمضان. ولكن كما ذكر، يمكن أن تتغير الظروف مما يؤدي إلى تغيير في تسجيل المخاطر. ولذلك، ينبغي إجراء التقسيم الطبقي للمخاطر سنوياً لمراجعة مستوى المخاطر قبل حلول شهر رمضان المبارك.",
  "RiskCalculator.Result.Low.Title": "خطر منخفض",
  "RiskCalculator.Result.Medium.Desc": "ينصح أولئك الذين هم على مستوى المخاطر المعتدل بعدم الصيام.\nكما ذكر سابقا، فإن العديد من هؤلاء المرضى سوف يختارون الصيام على أي حال. وينبغي اتخاذ هذا القرار الشخصي الهام بعد النظر في المخاطر المرتبطة بها بالتشاور مع HCPs. كما أنها تحتاج إلى أن تكون على بينة من التقنيات أو الاستراتيجيات للحد من هذا الخطر. إذا اختار الأفراد الصيام، فسيحتاجون إلى توخي الحذر والتوقف عن الصيام إذا نشأت أي مشاكل.",
  "RiskCalculator.Result.Medium.Title": "مخاطرة متوسطة",
  "RiskCalculator.Title": "حاسبة المخاطر",
  "RiskCalculator.TryAgain": "إعادة المحاولة",
  "RiskInfo.Button": "ابدأ الآن",
  "RiskInfo.Desc": "<p> حساب خطر شخص مصاب بمرض السكري قبل اختيار الصيام باستخدام <a target='_blank' href='https://www.idf.org/our-activities/education/diabetes-and-ramadan/healthcare-professionals.html'> 2021، خوارزمية التقسيم الطبقي للمخاطر الصادرة عن IDF-DAR المنشورة في المبادئ التوجيهية العملية لـ IDF-DAR </a> ، الفصل الخامس: التقسيم الطبقي لمرضى السكري قبل شهر رمضان. </p>",
  "RiskInfo.High.Desc": "مخاطرة عالية",
  "RiskInfo.High.Title": "النتيجة > 6",
  "RiskInfo.List": "<ul> <li> يحدد التقسيم الطبقي الجديد للمخاطر في IDF-DAR ثلاث فئات من المخاطر ويوفر درجة خطر تشمل عوامل متعددة تلعب دورًا مهمًا في توصية الصيام لكل فئة، أي المخاطر المنخفضة والمخاطر المعتدلة والمخاطر العالية </li>  <li> تم تصميم نظام التسجيل مع مراعاة العوامل المختلفة التي تؤثر على الصيام. </li> <li> و بالنسبة لفرد معين, ينبغي تقييم كل عنصر من عناصر الخطر, و ينبغي أن تكون النتيجة الإجمالية. </li> <li> النتيجة الناتجة ستحدد مستوى الخطر الإجمالي للفرد المصاب بالسكري الذي يسعى إلى الصيام خلال شهر رمضان. </li> </ul>",
  "RiskInfo.Low.Desc": "خطر منخفض",
  "RiskInfo.Low.Title": "نقاط 0 إلى 3",
  "RiskInfo.Medium.Desc": "خطر متوسط",
  "RiskInfo.Medium.Title": "نقاط 3.5 إلى 6",
  "RiskInfo.Title": "IDF — حاسبة المخاطر من دار:\nلمهنيي الرعاية الصحية فقط",
  Search: "بحث…",
  "SetNewPassword.Success.Desc": "تمت العملية بنجاح",
  "SetNewPassword.Success.Title": "نجاح",
  "TrainTheTrainer.AdditionalMaterial": "مواد إضافية",
  "TrainTheTrainer.Author": "المصدر",
  "TrainTheTrainer.Back": "رجوع",
  "TrainTheTrainer.Congratulation": "تهانينا",
  "TrainTheTrainer.Explore": "استكشف المزيد",
  "TrainTheTrainer.Lesson": "درس",
  "TrainTheTrainer.Modal.Button": "رجوع",
  "TrainTheTrainer.Modal.Desc": "أنت غير مدعو لزيارة هذا القسم",
  "TrainTheTrainer.Modal.Title": "غير مفتوح",
  "TrainTheTrainer.QuestionsCount": "السؤال { current } / { count }",
  "TrainTheTrainer.Quiz": "ابدأ الاختبار",
  "TrainTheTrainer.Result.Desc": "لقد تجاوزت الاختبار بنجاح",
  "TrainTheTrainer.Skip": "تخطي",
  "TrainTheTrainer.Title": "برنامج التدريب",
  "Validation.FieldIsRequired": "الحقل مطلوب",
  "Podcasts.folder.FRENCH": "بالفرنسية",
  "Podcasts.folder.OTHER": "اللغات الأخرى"
} ;
