import React from "react";
import {FormattedMessage} from "react-intl";
import LoginForm from "./Login.Form";

import StyledAuth from "../styled/StyledAuth";
import AuthFooter from "components/Footer/AuthFooter";

const Login = React.memo(() => {
  return (
    <StyledAuth.Page>
      <StyledAuth.Logo to={"/"}/>
      <StyledAuth.Title>
        <FormattedMessage id="Login.Title" />
      </StyledAuth.Title>

      <StyledAuth.Subtitle>
        <FormattedMessage id="Login.Desc" />
      </StyledAuth.Subtitle>

        <StyledAuth.WrapperForm>
            <LoginForm initialValues={{ identifier: null, password: null }} />
        </StyledAuth.WrapperForm>

      {/*<AuthFooter />*/}
    </StyledAuth.Page>
  );
});

export default Login;
