import React from "react";
import {FormattedMessage} from "react-intl";

import {localizeText} from "utils/lang";
import {pathBuilder} from "routes/urlLocations";
import Styled from './styled/StyledCourse';

const CourseLesson = React.memo(({ lesson, number, courseId }) => {
  return (
    <Styled.Lesson to={pathBuilder.lesson(courseId, lesson.id)}>
      <Styled.LessonNumber>
        <FormattedMessage id="TrainTheTrainer.Lesson" /> : {number}
      </Styled.LessonNumber>
      <Styled.LessonTitle>{localizeText(lesson.title)}</Styled.LessonTitle>
      <Styled.LessonMore to={pathBuilder.lesson(courseId, lesson.id)}/>
    </Styled.Lesson>
  );
});

export default CourseLesson;
