import React from "react";
import {useSelector} from "react-redux";
import {Spin} from "antd";

import {loaderSelector} from "services/loader/loader.selector";

const Loader = ({ children }) => {
  const isLoading = useSelector(loaderSelector);
  return <Spin spinning={isLoading}>{children}</Spin>;
};

export default Loader;
