import styled from "styled-components";

const PageTextContainer = styled.div`
	max-width: 928px;
	background: #FFFFFF;
	border-radius: 8px;
	padding: 48px 64px 30px 64px;
	@media (max-width: 767px) {
		padding: 48px 20px 30px 20px; 	 
	}
	font-family: var(--fontRaleWay);
	p {
		font-style: normal;
		font-size: 15px;
		line-height: 24px;
		color: var(--dark);
		font-family: var(--fontRaleWay);
	}
	ul {
		padding: 20px 0 24px 20px;
	}
	li {
		padding:  0 0 16px;
		font-size: 15px;
		font-family: var(--fontRaleWay);
	}
	li::marker {
		color: var(--blue);
		width: 6px;
		height: 6px;
	}
	h1 {
		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		line-height: 40px;
		padding-bottom: 22px;
	}
	h2 {
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 24px;
		padding-bottom: 8px;
	}
	h3,h4,h5 {
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 24px;
		padding-bottom: 8px;
		font-family: var(--fontRaleWay);
	}
	a {
		color: var(--blue);
		&:hover {
			color: var(--blue);
			text-decoration: none;
		}
	}
`;

const PageTitle = styled.h2`
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 40px;	
	padding-bottom: 22px;
`;

const PageTxt = styled.div`
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 24px;
		color: var(--dark)	
`;

const PageList = styled.div`
	padding: 32px 0 ;	
`;

const ListItem = styled.div`
	padding:  0 0 16px;
`;

export default {
	PageTextContainer,
	PageTitle,
	PageTxt,
	PageList,
	ListItem
};
