import React from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import groupBy from "lodash/groupBy";

import { auditoriumSelector } from "services/auditorium/auditorium.selector";
import Styled from "./styled/StyledAuditorium";
import AuditoriumItem from "./Auditorium.Item";

const AuditoriumContent = React.memo(({ searchTerm }) => {
  const auditorium = useSelector(auditoriumSelector);
  const { type } = useParams();
  const isHeaderUsed = type === "lectures";

  const groupedAuditorium = groupBy(auditorium, "categoryType");

  return (
    <>
      {!isHeaderUsed && (
        <Styled.ListOfVideos>
          {groupedAuditorium?.[type]?.map((item) => (
            <AuditoriumItem searchTerm={searchTerm} key={item.id} {...item} />
          ))}
        </Styled.ListOfVideos>
      )}

      {isHeaderUsed &&
        groupBy(
          groupedAuditorium?.[type]?.map((item) => [...item.videoItems]),
          "header.default"
        ) &&
        Object.entries(
          groupBy(
            groupedAuditorium?.[type]?.map((item) => item.videoItems).flat(),
            "header.default"
          )
        )?.sort()?.map((item) => {
          return (
            <AuditoriumItem
              searchTerm={searchTerm}
              key={item[0]}
              videoItems={item[1]}
              title={item[1]?.[0]?.header || { default: "Generic" }}
            />
          );
        })}
    </>
  );
});

export default AuditoriumContent;
