import { call, put, takeEvery } from "redux-saga/effects";

import { questionExpertRequest } from "./expert.api";
import * as expertActions from "./expert.actions";
import {
  finishLoaderAction,
  startLoaderAction
} from "services/loader/loader.action";
import openNotification from "utils/notifications";
import { history, urlLocations } from "routes/urlLocations";

function* questionExpert({ payload: data }) {
  try {
    yield put(startLoaderAction());

    const { response } = yield call(questionExpertRequest, data);

    yield put({
      type: expertActions.QUESTION_EXPERT_SUCCESS,
      payload: response.data
    });
    yield call(history.push, urlLocations.experts);
    yield call(openNotification, {
      message: "Expert.Success.Title",
      description: "Expert.Success.Desc",
      type: "success"
    });
  } catch (error) {
    yield put({ type: expertActions.QUESTION_EXPERT_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* expertSaga() {
  yield takeEvery(expertActions.QUESTION_EXPERT, questionExpert);
}
