import {call, put, select, takeEvery} from "redux-saga/effects";
import {LOCATION_CHANGE} from "connected-react-router";
import isEmpty from "lodash/isEmpty";
import {finishLoaderAction, startLoaderAction,} from "services/loader/loader.action";

import {GET_APP_INFO, GET_APP_INFO_FAILURE, GET_APP_INFO_SUCCESS,} from "./appInfo.action";

import {appInfoRequest} from "./appInfo.api";

function* getAppInfoOnLocationChangeData() {
    try {
        const appInfo = yield select((state) => state.appInfo);
        if (isEmpty(appInfo)) yield put({type: GET_APP_INFO});
    } catch (error) {
        console.log(error);
    }
}

function* fetchAppInfo() {
    try {
        yield put(startLoaderAction());
        const {response} = yield call(appInfoRequest);
        yield put({
            type: GET_APP_INFO_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        yield put({type: GET_APP_INFO_FAILURE});
        console.log(error);
    } finally {
        yield put(finishLoaderAction());
    }
}

export default function* appInfoSaga() {
    yield takeEvery(LOCATION_CHANGE, getAppInfoOnLocationChangeData);
    yield takeEvery(GET_APP_INFO, fetchAppInfo);
}
