import React from "react";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {useLocation} from "react-router";
import Styled from './styled/StyledAuditorium';
import {TABS} from "./Auditorium.constants";

const AuditoriumTabs = React.memo(() => {
  const { pathname } = useLocation();
  return (
    <Styled.Tabs>
      {TABS.map(tab => (
        <Styled.TabItem
          key={tab.path}
          isactive={ pathname === tab.path }
        >
          <Link to={tab.path}>
            <FormattedMessage id={tab.title} />
          </Link>
        </Styled.TabItem>
      ))}
    </Styled.Tabs>
  );
});

export default AuditoriumTabs;
