import { urlLocations } from "routes/urlLocations";
import AsideDashboard from "../../../assets/images/dashboard/AsideDashboard.svg";
import AsideLibrary from "../../../assets/images/dashboard/AsideLibrary.svg";
import AsideAbout from "../../../assets/images/dashboard/AsideAbout.svg";
import AsideExperts from "../../../assets/images/dashboard/AsideExperts.svg";
import AsideInfo from "../../../assets/images/dashboard/AsideInfo.svg";
import AsideLectorium from "../../../assets/images/dashboard/AsideLectorium.svg";
import AsideRisk from "../../../assets/images/dashboard/AsideRisk.svg";
import AsideTrain from "../../../assets/images/dashboard/AsideTrain.svg";
import AsideHelp from "../../../assets/images/dashboard/AsideHelp.svg";
import Podcasts from "../../../assets/images/dashboard/IconPodcasts.svg";
import DiaVoice from "../../../assets/images/dashboard/diavoice.png";

export const NAVIGATION = [
  {
    title: "Aside.Dashboard",
    path: urlLocations.dashboard,
    img: AsideDashboard,
  },
  {
    title: "Aside.Library",
    path: urlLocations.library,
    img: AsideLibrary,
  },
  {
    title: "Aside.Auditorium",
    path: urlLocations.auditoriumVideos,
    location: urlLocations.auditorium,
    img: AsideLectorium,
  },
  {
    title: "Aside.Experts",
    path: urlLocations.experts,
    img: AsideExperts,
  },
  {
    title: "Aside.TrainTheTrainer",
    path: urlLocations.train,
    img: AsideTrain,
  },
  {
    title: "Aside.Podcasts",
    path: urlLocations.podcastsFolders,
    img: Podcasts,
    logo: DiaVoice,
  },
  {
    title: "Aside.About",
    path: urlLocations.about,
    img: AsideAbout,
  },
  {
    title: "Aside.Help",
    path: urlLocations.help,
    img: AsideHelp,
  },
  {
    title: "Aside.RiskCalculator",
    path: urlLocations.risk,
    img: AsideRisk,
  },
];
