import { call, put, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import { urlLocations } from "routes/urlLocations";
import { isUrlMatch } from "services/router/router.utils";
import pickBy from "lodash/pickBy";

import { getLibraryRequest } from "./library.api";
import * as libraryActions from "./library.actions";
import {
  finishLoaderAction,
  startLoaderAction
} from "services/loader/loader.action";

function* getLibraryOnLocationChangeData({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.library)) {
      yield put({ type: libraryActions.GET_LIBRARY });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchLibrary({ payload }) {
  try {
    const params = pickBy(payload);
    yield put(startLoaderAction());

    const { response } = yield call(getLibraryRequest, params);

    yield put({
      type: libraryActions.GET_LIBRARY_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: libraryActions.GET_LIBRARY_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* profileSaga() {
  yield takeEvery(LOCATION_CHANGE, getLibraryOnLocationChangeData);
  yield takeEvery(libraryActions.GET_LIBRARY, fetchLibrary);
}
