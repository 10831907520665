import React from "react";
import {FormattedMessage} from "react-intl";
import StyledLabel from './styled/StyledLabel';
import StyledError from './styled/StyledError';
import Styled from './styled/StyledControlWrapper';

const ControlWrapper = ({
  label,
  children,
  name,
  errors,
  error,
  customTouched,
  touched
}) => (
  <>
    <Styled.FieldCol>
      {children}
      {label && (
        <StyledLabel>
          <FormattedMessage id={label} />
        </StyledLabel>
      )}

        {errors?.[name] &&
        !customTouched &&
        errors?.[name].map(error => (
            <StyledError key={error}>
                <FormattedMessage key={error} id={error} />
            </StyledError>
        ))}
        {error &&
        touched && (
            <StyledError key={error}>
                <FormattedMessage key={error} id={error} />
            </StyledError>
        )}
    </Styled.FieldCol>

  </>
);

export default ControlWrapper;
