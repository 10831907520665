import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";

import {RISK_CATEGORY} from "./Risk.constants";
import {getRiskTestResultSelector} from "services/risk/risk.selectors";
import Styled from './styled/StyledRisk';

const RiskResult = React.memo(({ onTryAgainClick }) => {
  const result = useSelector(getRiskTestResultSelector);
  return (
    <>
      {!!result && (
        <Styled.ResultBlock
        >
          <Styled.ResultLabel>
            <FormattedMessage id="RiskCalculator.Result" /> : { result.riskScore }
          </Styled.ResultLabel>

          <Styled.Result
              bgColor={ RISK_CATEGORY[result.riskCategory].bg }
          >
            <Styled.ResultHeader>
              <Styled.ResultIcon><img src={RISK_CATEGORY[result.riskCategory].img} alt="" /></Styled.ResultIcon>
              <Styled.ResultTitle>
                <FormattedMessage
                    id={`RiskCalculator.Result.${
                        RISK_CATEGORY[result.riskCategory].type
                    }.Title`}
                />
              </Styled.ResultTitle>
            </Styled.ResultHeader>

            <Styled.ResultTextBlock>
              <p>
                <FormattedMessage
                    id={`RiskCalculator.Result.${
                        RISK_CATEGORY[result.riskCategory].type
                    }.Desc`}
                />
              </p>
              <Styled.TryAgain onClick={onTryAgainClick}>
                <FormattedMessage id="RiskCalculator.TryAgain" />
              </Styled.TryAgain>
            </Styled.ResultTextBlock>

          </Styled.Result>

        </Styled.ResultBlock>
      )}
    </>
  );
});

export default RiskResult;
