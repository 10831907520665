import * as auditoriumActions from "./auditorium.actions";
import initialState from "store/initialState";

export default (state = initialState.profile, action) => {
  switch (action.type) {
    case auditoriumActions.GET_AUDITORIUM_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
