import styled from "styled-components";

const VideoContainer = styled.div`
  width: 328px;
  height: 180px;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  position: relative;
  margin-bottom: 16px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const VideoPopup = styled.div`
  padding: 0 15px;
  margin-bottom: 10px;
  iframe,
  video {
    width: 100%;
    @media (min-width: 480px) {
      width: 100%;
      height: 420px;
    }
  }
`;

export default {
  VideoContainer,
  VideoPopup,
};
