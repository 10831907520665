import React from "react";
import {reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";

import InputField from "components/InputField/InputField";
import Button from "components/Button/Button";
import {changePasswordAction} from "services/password/password.action";
import {requiredFieldsValidate} from "utils/validation";
import Styled from './styled/StyledModal';

const requiredFields = ["currentPassword", "newPassword", "confirmNewPassword"];

const ProfileChangePassword = React.memo(({ handleSubmit }) => {
  return (
    <Styled.Content>
      <h2>
        <FormattedMessage id="Profile.ChangePassword.Title" />
      </h2>
      <form onSubmit={handleSubmit}>
        <InputField
          label="Profile.CurrentPassword"
          type="password"
          name="currentPassword"
        />
        <InputField
          label="Profile.NewPassword"
          type="password"
          name="newPassword"
        />
        <InputField
          label="Profile.ConfirmPassword"
          type="password"
          name="confirmNewPassword"
        />
        <Styled.WrapperCenter>
          <Button htmlType="submit">
            <FormattedMessage id="Profile.ChangePassword.Button" />
          </Button>
        </Styled.WrapperCenter>

      </form>
    </Styled.Content>
  );
});

export default reduxForm({
  form: "changePassword",
  validate: v => requiredFieldsValidate(v, requiredFields),
  destroyOnUnmount: true,
  onSubmit: (value, dispatch) => dispatch(changePasswordAction(value))
})(ProfileChangePassword);
