import React, { useState } from "react";
import { useLocation } from "react-router";

import { history, urlLocations } from "routes/urlLocations";
import Aside from "./Aside/Aside";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Styled from "./styled/StyledAutorizeLayout";
import { STORAGE_TYPE } from "constants/constants";
import Player from "layouts/AuthorizedLayout/Player/Player";
import { useSelector } from "react-redux";
import { playableEpisodeSelector } from "services/podcasts/podcasts.selectors";

const AuthorizedLayout = ({ children }) => {
  const MainContentRef = React.useRef(null);
  const { pathname } = useLocation();
  const playableEpisode = useSelector(playableEpisodeSelector);

  React.useEffect(() => {
    const storage =
      localStorage?.getItem("storageTyp") || STORAGE_TYPE.SESSION_STORAGE;
    const jwt = window[storage]?.getItem("jwt");
    !jwt && history.push(urlLocations.login);
  }, []);

  const [visibilityAside, setVisibilityAside] = useState(false);

  const onChangeVisibility = () => {
    setVisibilityAside(!visibilityAside);
  };

  React.useEffect(() => {
    MainContentRef.current?.scrollIntoView();
  }, [pathname]);

  return (
    <Styled.Layout>
      <Aside
        visibilityAside={visibilityAside}
        onChangeVisibility={onChangeVisibility}
      />
      <Styled.MainContent withPlayer={!!playableEpisode} ref={MainContentRef}>
        <Header
          visibilityAside={visibilityAside}
          onChangeVisibility={onChangeVisibility}
        />
        <Styled.PageContent>{children}</Styled.PageContent>
        <Footer />
      </Styled.MainContent>
      <Player />
    </Styled.Layout>
  );
};

export default AuthorizedLayout;
