export default {
  "About.Content": "<h1> À propos de DaR </h1> <h2> Bibliothèque </h2> <p> Ouvrez la bibliothèque pour parcourir et visualiser des vidéos, des directives, des articles et des publications\nVous pouvez ajouter des éléments aux favoris et regarder plus tard à partir de la section Favoris </p> <h2> Auditorium <h2> <p> Pour regarder des conférences archivées, des webdiffusions et des vidéos en direct </p> <h2> Experts </h2> <p> Nos experts peuvent vous aider, il suffit de sélectionner la catégorie, remplir le formulaire et envoyer votre question, et vous devriez recevoir des réponses par email </br> Pour en savoir plus sur les experts de chaque catégorie, ouvrez la catégorie et tapez votre question </p> <h2> Mon compte </h2> <p> Vous pouvez gérer votre profil de compte et modifier votre mot de passe </p> </br> </br> <p> La préparation de ce portail Web a été rendue possible grâce à une subvention d'éducation sans restriction accordée par les pays du Golfe de Sanofi </br> © 2018 Alliance DAR. Tous droits réservés </p>",
  "Aside.About": "À propos",
  "Aside.AdditionalInfo": "Info supplémentaire",
  "Aside.Auditorium": "La grande salle",
  "Aside.Dashboard": "Tableau de bord",
  "Aside.Podcasts": "Podcasts",
  "Aside.Experts": "Experts",
  "Aside.Help": "Aide",
  "Aside.Library": "Bibliothèque",
  "Aside.RiskCalculator": "Calculateur de risques",
  "Aside.TrainTheTrainer": "Former le formateur",
  "Aside.Version": "Version 3.5.10",
  "Auditorium.By": "Par",
  "Auditorium.Tabs.Lectures": "Conférences",
  "Auditorium.Tabs.Videos": "Vidéos",
  "Auditorium.Title": "La grande salle",
  "Auth.form.error.email.format": "Veuillez fournir une adresse email valide.",
  "Auth.form.error.email.taken": "Le courrier électronique est déjà pris",
  "Auth.form.error.invalid": "E-mail ou mot de passe non valide",
  "Auth.form.error.user.not-exist": "Cet e-mail n'existe pas",
  "ChangePassword.Success.Desc": "Succès",
  "ChangePassword.Success.Title": "Succès",
  "Dashboard.Auditorium.Desc": "Regardez des conférences archivées, des web-diffusions et en direct.",
  "Dashboard.Auditorium.Title": "La grande salle",
  "Dashboard.Desc": "Votre assistant au diabète personnel au Ramadan",
  "Dashboard.Experts.Desc": "Nos experts peuvent vous aider, il suffit d'envoyer votre question.",
  "Dashboard.Experts.Title": "Experts",
  "Dashboard.Library.Desc": "Parcourez les lignes directrices, les articles et les publications.",
  "Dashboard.Library.Title": "Bibliothèque",
  "Dashboard.Title": "Bienvenue à Dar Academy",
  "Dashboard.TrainTheTrainer.Desc": "Exposé à beaucoup plus de connaissances.",
  "Dashboard.TrainTheTrainer.Title": "Former le formateur",
  "Dashboard.Podcasts.Title": "Podcasts",
  "Dashboard.Podcasts.Desc": "Podcasts",
  "Expert.Back": "Retour à toutes les catégories",
  "Expert.Desc": "Notre panel de diététistes répondra à votre question alimentaire pendant le Ramadan par e-mail",
  "Expert.Email": "Adresse courriel",
  "Expert.Question": "Veuillez saisir les détails de la question",
  "Expert.Send": "Envoyer la demande",
  "Expert.Subject": "Sujet",
  "Expert.Success.Desc": "Succès",
  "Expert.Success.Title": "Succès",
  "Experts.Desc": "Nos experts peuvent vous aider, Il suffit de sélectionner la catégorie et demandez à nos experts",
  "Experts.DiabetesConsultant.Desc": "Notre panel de consultants en diabète répondra à vos questions médicales par courriel.",
  "Experts.DiabetesConsultant.Title": "Consultant en diabète",
  "Experts.DiabetesNurseEducator.Desc": "Notre groupe d'infirmières éducatrices en diabète répondra à vos questions par courriel.",
  "Experts.DiabetesNurseEducator.Title": "Infirmière éducatrice en diabète",
  "Experts.Dietician.Desc": "Notre panel de diététistes répondra à vos questions liées au Ramadan par e-mail",
  "Experts.Dietician.Title": "Diététicien",
  "Experts.ReligiousScholar.Desc": "Notre panel d'éminents savants religieux répondra à vos questions relatives au jeûne par courriel",
  "Experts.ReligiousScholar.Title": "Chercheur religieux",
  "Experts.Title": "Corridor des experts",
  "Favorites.Tabs.Auditorium": "La grande salle",
  "Favorites.Tabs.Library": "Bibliothèque",
  "Favorites.Tabs.TrainTheTrainer": "Former le formateur",
  "Footer.Desc": "La préparation de ce portail Web a été rendue possible grâce à une subvention éducative sans restriction fournie par les pays du Golfe Sanofi\n© 2018 DAR alliance. Tous les droits sont réservés",
  "Footer.Desc.Servier":
    "The preparation of this PODCAST was made possible through an unrestricted education support provided by SERVIER GCC\n© 2020 DAR alliance. All rights reserved",
  "ForgotPassword.Button": "Réinitialiser le mot de passe",
  "ForgotPassword.Desc": "Entrez votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe",
  "ForgotPassword.Email": "Adresse courriel",
  "ForgotPassword.Success.Desc": "Succès",
  "ForgotPassword.Success.Title": "Succès",
  "ForgotPassword.Title": "Mot de passe oublié ?",
  "Header.ItemLogout": "Déconnexion",
  "Header.ItemProfile": "Paramètre de Profil",
  "Help.Content": "<h1> Aide </h1> <h2> Bibliothèque </h2> <p> Ouvrez la bibliothèque pour parcourir et visualiser des vidéos, des directives, des articles et des publications\nVous pouvez ajouter des éléments aux favoris et regarder plus tard à partir de la section Favoris </p> <h2> Auditorium <h2> <p> Pour regarder des conférences archivées, des webdiffusions et des vidéos en direct </p> <h2> Experts </h2> <p> Nos experts peuvent vous aider, il suffit de sélectionner la catégorie, remplir le formulaire et envoyer votre question, et vous devriez recevoir des réponses par email </br> Pour en savoir plus sur les experts de chaque catégorie, ouvrez la catégorie et tapez votre question </p> <h2> Mon compte </h2> <p> Vous pouvez gérer votre profil de compte et modifier votre mot de passe </p> </br> </br> <p> La préparation de ce portail Web a été rendue possible grâce à une subvention d'éducation sans restriction accordée par les pays du Golfe de Sanofi </br> © 2018 Alliance DAR. Tous droits réservés </p>",
  "Help.Text": 'Develop by OTB Life Science & Technology Consultancy FZ-LLC.',
  "Lang.Ar": "العربية",
  "Lang.Eng": "English",
  "Lang.Fr": "Français",
  "Lang.Tr": "Türk",
  "Lang.Ur": "اردو",
  "Library.Author": "Auteur",
  "Library.Explore": "Voir plus",
  "Library.Title": "Bibliothèque & Références",
  "Login.Button": "Se connecter",
  "Login.Desc": "C'est génial de vous revoir",
  "Login.Email": "Adresse courriel",
  "Login.ForgotPassword": "Mot de passe oublié ?",
  "Login.Info": "J'accepte { terms } & { privacy }",
  "Login.Password": "Mot de passe",
  "Login.PrivacyPolicy": "Politique de confidentialité",
  "Login.RememberMe": "Se souvenir de moi",
  "Login.SignUp": "Vous n’avez pas de compte ? Inscrivez-vous.",
  "Login.Success.Desc": "Succès",
  "Login.Success.Title": "Succès",
  "Login.TermsOfUse": "Conditions d'utilisation",
  "Login.Title": "Connectez-vous à votre compte",
  "NewPassword.Button": "Réinitialiser le mot de passe",
  "NewPassword.ConfirmPassword": "Confirmer le mot de passe",
  "NewPassword.Desc": "Créez un nouveau mot de passe et confirmez-le",
  "NewPassword.NewPassword": "Nouveau mot de passe",
  "NewPassword.Title": "Mot de passe oublié ?",
  "Notifications.Error": "Erreur",
  "Notifications.SomethingWentWrong": "Oups ! Quelque chose s'est mal passé !\nVeuillez réessayer plus tard, ou contactez l'administrateur système si vous êtes toujours confronté à ce problème",
  "Profile.AdditionalInformation": "Informations supplémentaires",
  "Profile.Button": "Mettre à jour les informations ?",
  "Profile.ChangePassword": "Changer le mot de passe",
  "Profile.ChangePassword.Button": "Modifier",
  "Profile.ChangePassword.Title": "Changer le mot de passe",
  "Profile.ConfirmPassword": "Confirmer le nouveau mot de passe",
  "Profile.Country": "Pays",
  "Profile.CurrentPassword": "Mot de passe actuel",
  "Profile.Email": "Adresse courriel",
  "Profile.FullName": "Nom complet",
  "Profile.Logout": "Déconnexion",
  "Profile.MainInformation": "Informations principales",
  "Profile.NewPassword": "Nouveau mot de passe",
  "Profile.Password": "Mot de passe",
  "Profile.Phone": "Numéro de téléphone",
  "Profile.PlaceOfWork": "Lieu de travail",
  "Profile.Speciality": "Spécialité",
  "Profile.Success.Desc": "Succès",
  "Profile.Success.Title": "Succès",
  "Profile.Title": "Profil",
  "Registration.Button": "S'inscrire",
  "Registration.City": "Ville",
  "Registration.ConfirmPassword": "Confirmer le mot de passe",
  "Registration.Country": "Pays",
  "Registration.Desc": "Veuillez remplir le formulaire pour créer un compte",
  "Registration.Email": "Adresse courriel",
  "Registration.FullName": "Nom complet",
  "Registration.Login": "Vous avez un compte ? Connexion",
  "Registration.Password": "Mot de passe",
  "Registration.Phone": "Numéro de contact/Téléphone *",
  "Registration.PlaceOfWork": "Lieu de travail",
  "Registration.SignIn": "Vous n’avez pas de compte ? Inscrivez-vous.",
  "Registration.Speciality": "Spécialité",
  "Registration.Success.Desc": "Succès",
  "Registration.Success.Title": "Succès",
  "Registration.Title": "Connectez-vous à votre compte",
  "RiskCalculator.Button": "Afficher le résultat du risque",
  "RiskCalculator.Desc": "Calculez le risque d'une personne vivant avec le diabète avant d'opter pour le jeûne.",
  "RiskCalculator.Result": "Votre résultat",
  "RiskCalculator.Result.High.Desc": "Les personnes qui font partie de la catégorie à risque élevé ne devraient pas jeûner.\nCes personnes présentent un risque très élevé de développer des complications lors du jeûne pendant le Ramadan. Nous recommandons que ces personnes ne jeûnent pas. S'ils insistent encore sur le jeûne, le plus grand soin et le suivi doivent être fournis en même temps que les stratégies et recommandations mentionnées ci-dessus et dans les autres chapitres des présentes lignes directrices.",
  "RiskCalculator.Result.High.Title": "Risque élevé",
  "RiskCalculator.Result.Low.Desc": "Les personnes à faible risque devraient pouvoir rapidement.\nCes personnes courent un risque moindre de complications survenant lors du jeûne pendant le ramadan. Toutefois, comme nous l'avons mentionné, les circonstances peuvent changer, entraînant un changement dans la notation des risques. Par conséquent, la stratification des risques devrait être effectuée annuellement pour examiner le niveau de risque avant le ramadan.",
  "RiskCalculator.Result.Low.Title": "FAIBLE RISQUE",
  "RiskCalculator.Result.Medium.Desc": "Il est conseillé aux personnes à risque modéré de ne pas jeûner.\nComme mentionné précédemment, beaucoup de ces patients choisiront de jeûner de toute façon. Cette décision personnelle importante devrait être prise après avoir examiné les risques associés en consultation avec les PSC. Ils doivent également être conscients des techniques ou des stratégies visant à réduire ce risque. Si les individus choisissent de jeûner, ils devront être prudents et cesser de jeûner en cas de problème.",
  "RiskCalculator.Result.Medium.Title": "risque modéré",
  "RiskCalculator.Title": "Calculateur de risques",
  "RiskCalculator.TryAgain": "Réessayer",
  "RiskInfo.Button": "Commencer maintenant",
  "RiskInfo.Desc": "<p> Calculez le risque d'une personne vivant avec le diabète avant d'opter pour le jeûne en utilisant le <a target='_blank' href='https://www.idf.org/our-activities/education/diabetes-and-ramadan/healthcare-professionals.html'> 2021, algorithme de stratification des risques IDF-DAR publié dans les directives pratiques IDF-DAR </a> , chapitre 5 : Stratification du risque des personnes atteintes de diabète avant le Ramadan. </p>",
  "RiskInfo.High.Desc": "Risque élevé",
  "RiskInfo.High.Title": "Score > 6",
  "RiskInfo.List": "<ul> <li> La nouvelle stratification du risque FDI-DAR définit trois catégories de risque et fournit un score de risque qui comprend de multiples facteurs qui jouent un rôle important dans la recommandation de jeûne pour chaque catégorie, à savoir un risque faible, un risque modéré et un risque élevé </li>  <li> Le système de notation a été conçu en tenant compte de différents facteurs qui influencent le jeûne. </li> <li> Pour une personne donnée, chaque élément de risque devrait être évalué et la note devrait être totalisé. </li> <li> Le score obtenu déterminera le niveau de risque global d'une personne atteinte de diabète qui cherche à jeûner pendant le Ramadan. </li> </ul>",
  "RiskInfo.Low.Desc": "FAIBLE RISQUE",
  "RiskInfo.Low.Title": "Score 0 à 3",
  "RiskInfo.Medium.Desc": "RISQUE MODÉRÉ",
  "RiskInfo.Medium.Title": "Score 3,5 à 6",
  "RiskInfo.Title": "IDF — Calculateur de risque DAR :\nPour les professionnels de la santé",
  Search: "Rechercher…",
  "SetNewPassword.Success.Desc": "Succès",
  "SetNewPassword.Success.Title": "Succès",
  "TrainTheTrainer.AdditionalMaterial": "Matériel supplémentaire",
  "TrainTheTrainer.Author": "Auteur",
  "TrainTheTrainer.Back": "Retour",
  "TrainTheTrainer.Congratulation": "Félicitation",
  "TrainTheTrainer.Explore": "Voir plus",
  "TrainTheTrainer.Lesson": "La leçon",
  "TrainTheTrainer.Modal.Button": "Retour",
  "TrainTheTrainer.Modal.Desc": "Vous n'êtes pas invité à visiter cette section",
  "TrainTheTrainer.Modal.Title": "Non invité",
  "TrainTheTrainer.QuestionsCount": "Question { current } / { count }",
  "TrainTheTrainer.Quiz": "Nouveau quiz !",
  "TrainTheTrainer.Result.Desc": "Vous passez le quiz",
  "TrainTheTrainer.Skip": "Ignorer",
  "TrainTheTrainer.Title": "Former le formateur",
  "Validation.FieldIsRequired": "* Champ obligatoire",
  "Podcasts.folder.FRENCH": "Français",
  "Podcasts.folder.OTHER": "Les autres"
} ;
