import React from "react";
import Styled from "./styled";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { podcastsPlaylistsSelector } from "services/podcasts/podcasts.selectors";
import PodcastsPlaylists from "containers/Podcasts/PodcastsPlaylists";
import { useParams } from "react-router";

const Podcasts = () => {
  const playlists = useSelector(podcastsPlaylistsSelector);
  const { folder } = useParams();

  const filteredPlaylists = playlists?.filter((p) => p.folder === folder);

  return (
    <div>
      <Styled.Title>
        <FormattedMessage id={"Podcasts.Title"} />
      </Styled.Title>
      {filteredPlaylists && <PodcastsPlaylists playlists={filteredPlaylists} />}
    </div>
  );
};

export default Podcasts;
