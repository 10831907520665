import styled from "styled-components";

const StyledLabel = styled.label`
    top: 15px;
    left: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    color: #9A9FAD;
    pointer-events: none;
`;
export default StyledLabel;
