import React from "react";
import { reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Select as AntSelect } from "antd";
import sortBy from "lodash/sortBy";

import InputField from "components/InputField/InputField";
import SelectField from "components/SelectField/SelectField";
import Button from "components/Button/Button";
import { registrationAction } from "services/registration/registration.action";
import {
  appInfoCountriesSelector,
  appInfoSpecialitiesSelector,
} from "services/appInfo/appInfo.selector";
import { localizeText } from "utils/lang";
import CheckboxField from "components/CheckboxField/CheckboxField";
import { urlLocations } from "routes/urlLocations";
import InputPhoneField from "components/InputPhoneField/InputPhoneField";
import Styled from "./styled/StyledRegistration";
import StyledAuth from "containers/styled/StyledAuth";
import { requiredFieldsValidate } from "utils/validation";

const { Option } = AntSelect;

const requiredFields = [
  "email",
  "password",
  "speciality",
  "country",
  "mobileNumber",
  "fullName",
  "confirmPassword",
  "accept",
  "placeOfWork",
  "city",
];

const RegistrationForm = React.memo(({ handleSubmit }) => {
  const countries = useSelector(appInfoCountriesSelector);
  const specialities = useSelector(appInfoSpecialitiesSelector);

  return (
    <Styled.Wrapper>
      <form onSubmit={handleSubmit}>
        <Styled.FormRow>
          <Styled.FormCol50>
            <InputField
              greenborderfocus
              name="email"
              label="Registration.Email"
            />

            <InputField
              greenborderfocus
              name="password"
              label="Registration.Password"
              type="password"
            />

            <SelectField
              greenborderfocus
              label="Registration.Speciality"
              name="speciality"
            >
              {specialities?.map((speciality) => (
                <Option
                  key={speciality.id}
                  value={speciality.id}
                  children={localizeText(speciality.title)}
                />
              ))}
            </SelectField>

            <SelectField
              label="Registration.Country"
              name="country"
              greenborderfocus
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {sortBy(countries, (country) => localizeText(country.title))?.map(
                (country) => (
                  <Option
                    value={country.id}
                    children={localizeText(country.title)}
                    key={country.id}
                  />
                )
              )}
            </SelectField>

            <InputPhoneField
              greenborderfocus
              name="mobileNumber"
              // label="Registration.Phone"
            />
          </Styled.FormCol50>

          <Styled.FormCol50>
            <InputField
              greenborderfocus
              name="fullName"
              label="Registration.FullName"
            />

            <InputField
              greenborderfocus
              name="confirmPassword"
              label="Registration.ConfirmPassword"
              type="password"
            />

            <InputField
              greenborderfocus
              name="placeOfWork"
              label="Registration.PlaceOfWork"
            />

            <InputField
              greenborderfocus
              name="city"
              label="Registration.City"
            />

            <Styled.Terms>
              <CheckboxField name="accept">
                <FormattedMessage
                  id="Login.Info"
                  values={{
                    terms: (
                      <Styled.Links
                        target="_blank"
                        href="https://strapi-msd-be-bahrain.s3.me-south-1.amazonaws.com/otb_privacy.pdf"
                      >
                        <FormattedMessage id="Login.TermsOfUse" />
                      </Styled.Links>
                    ),
                    privacy: (
                      <Styled.Links
                        target="_blank"
                        href="https://strapi-msd-be-bahrain.s3.me-south-1.amazonaws.com/otb_privacy.pdf"
                      >
                        <FormattedMessage id="Login.PrivacyPolicy" />
                      </Styled.Links>
                    ),
                  }}
                />
              </CheckboxField>
            </Styled.Terms>
          </Styled.FormCol50>
        </Styled.FormRow>

        <Styled.ButtonRow>
          <Button aligntype="center" htmlType="submit">
            <FormattedMessage id="Registration.Button" />
          </Button>
        </Styled.ButtonRow>

        <StyledAuth.CenterLinkWrap>
          <StyledAuth.AuthLink to={urlLocations.login}>
            <FormattedMessage id="Registration.Login" />
          </StyledAuth.AuthLink>
        </StyledAuth.CenterLinkWrap>
      </form>
    </Styled.Wrapper>
  );
});

export default reduxForm({
  form: "registrationForm",
  validate: (v) => requiredFieldsValidate(v, requiredFields),
  onSubmit: (value, dispatch) => dispatch(registrationAction(value)),
})(RegistrationForm);
