import React from "react";

import ModalWrapper from "components/ModalWrapper/ModalWrapper";
import Styled from "./styled/StyledPopup";

import { MODALS_ID } from "constants/modals";

const AuditoriumModalContent = ({ video }) => {
  return (
    <div>
      <Styled.VideoPopup>
        {video.type === "youtube" ? (
          <iframe src={video?.url.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/')}></iframe>
        ) : (
          <video autoPlay controls>
            <source src={video?.url} />
          </video>
        )}
      </Styled.VideoPopup>
    </div>
  );
};

const AuditoriumModal = ({ video, id }) => {
  return (
    <>
      <ModalWrapper
        modalId={`${MODALS_ID.VIDEO_MODAL}_${id}`}
        video={video}
        component={AuditoriumModalContent}
      />
    </>
  );
};

export default AuditoriumModal;
