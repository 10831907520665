import * as riskActions from "./risk.actions";
import initialState from "store/initialState";

export default (state = initialState.risk, action) => {
    switch (action.type) {
        case riskActions.GET_RISK_SUCCESS:
            return {
                ...state,
                test: action.payload,
            };
        case riskActions.SUBMIT_RISK_SUCCESS:
            return {
                ...state,
                result: action.payload,
            };
        case riskActions.CLEAR_RESULTS:
            return {
                ...state,
                result: null,
            };
        default:
            return state;
    }
};
