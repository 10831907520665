import React from "react";
import {Select as AntSelect} from "antd";

import {OPTIONS} from "./LangSwitcher.constants";
import {FormattedMessage} from "react-intl";
import Styled from './styled/StyledLangSwitcher';

const { Option } = AntSelect;

const LangSwitcher = React.memo(() => {
  const lang = localStorage.getItem("lang");

  const onLanguageSelect = React.useCallback(value => {
    localStorage.setItem("lang", value);
    document.location.reload();
  }, []);

  return (
      <Styled.StyledLangControl>

        <Styled.OptionList value={lang} dropdownClassName="lang-dropdown" onSelect={onLanguageSelect}>
          {OPTIONS.map(lang => (
            <Styled.OptionItem key={lang.value} value={lang.value}>
                <Styled.Icon><img src={lang.img} alt=""/></Styled.Icon>
              <FormattedMessage id={lang.title} />
            </Styled.OptionItem>
          ))}
        </Styled.OptionList>
      </Styled.StyledLangControl>
  );
});

export default LangSwitcher;
