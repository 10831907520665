import React from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import {FormattedMessage} from "react-intl";

import {pathBuilder, urlLocations} from "routes/urlLocations";
import * as trainTheTrainerSelectors from "services/trainTheTrainers/trainTheTrainer.selectors";
import {localizeText} from "utils/lang";
import CourseLesson from "./Course.Lesson";
import StyledPages from "containers/styled/StyledPages";
import Styled from './styled/StyledCourse';

const Course = React.memo(() => {
  const { id } = useParams();
  const course = useSelector(state =>
    trainTheTrainerSelectors.courseSelector(state, id)
  );

  return (
    <div>
        <StyledPages.BackBtn to={urlLocations.train}>
            <FormattedMessage id="TrainTheTrainer.Back" />
        </StyledPages.BackBtn>

        <StyledPages.PageBgContainer>
          <StyledPages.PageTitle>{localizeText(course?.title)}</StyledPages.PageTitle>
          <Styled.Author>
            <FormattedMessage id="TrainTheTrainer.Author" /> :{" "}
            {localizeText(course?.instructor)}
          </Styled.Author>
          <Styled.LessonsList>
            {course?.courseLessons?.map((lesson, index) => (
              <Styled.LessonsItem key={lesson.id}>
                <CourseLesson courseId={id} lesson={lesson} number={index + 1} />
              </Styled.LessonsItem>
            ))}
          </Styled.LessonsList>

          <Styled.TakeQuiz to={pathBuilder.quiz(id)}>
            <FormattedMessage id="TrainTheTrainer.Quiz" />
          </Styled.TakeQuiz>

        </StyledPages.PageBgContainer>
    </div>
  );
});

export default Course;
