import { call, put, takeEvery, select } from "redux-saga/effects";
import { createMatchSelector, LOCATION_CHANGE } from "connected-react-router";
import { urlLocations } from "routes/urlLocations";
import { isUrlMatch } from "services/router/router.utils";
import get from "lodash/get";

import {
  getPodcastEpisodesProgressRequest,
  getPodcastEpisodesRequest,
  getPodcastPlaylistsRequest,
  updateEpisodeProgressRequest,
} from "./podcasts.api";
import * as podcastsActions from "./podcasts.actions";
import {
  finishLoaderAction,
  startLoaderAction,
} from "services/loader/loader.action";
import { podcastEpisodeProgress } from "services/podcasts/podcasts.selectors";

function* getPodcastsPlaylistsOnLocationChange({ payload }) {
  try {
    if (
      isUrlMatch(payload, urlLocations.podcasts) ||
      isUrlMatch(payload, urlLocations.topPodcasts) ||
      isUrlMatch(payload, urlLocations.podcastsFolders)
    ) {
      yield put({ type: podcastsActions.GET_PLAYLISTS });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getPodcastsProgressOnLocationChange() {
  try {
    const progress = yield select(podcastEpisodeProgress);
    if (!progress) {
      yield put({ type: podcastsActions.GET_EPISODE_PROGRESS });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getEpisodesOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.podcastEpisodes)) {
      const getMatch = createMatchSelector(urlLocations.podcastEpisodes);
      const state = yield select((state) => state);
      const id = get(getMatch(state), "params.id");
      yield !!id && put({ type: podcastsActions.GET_EPISODES, payload: id });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchPodcastsPlaylists() {
  try {
    yield put(startLoaderAction());

    const { response } = yield call(getPodcastPlaylistsRequest);

    yield put({
      type: podcastsActions.GET_PLAYLISTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: podcastsActions.GET_PLAYLISTS_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* updateEpisodeProgress({ payload }) {
  try {
    const { response } = yield call(updateEpisodeProgressRequest, payload);

    yield put({
      type: podcastsActions.UPDATE_EPISODE_PROGRESS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: podcastsActions.UPDATE_EPISODE_PROGRESS_FAILURE });
    console.log(error);
  }
}

function* fetchPlayableEpisodes({ payload: { podcastId, episodeId } }) {
  try {
    yield put(startLoaderAction());

    const { response } = yield call(getPodcastEpisodesRequest, podcastId);

    yield put({
      type: podcastsActions.GET_PLAYABLE_EPISODE_SUCCESS,
      payload: response.data?.episodes?.find((episode) => {
        return episode?.id === parseInt(episodeId);
      }),
    });
    yield put({
      type: podcastsActions.SET_PLAYABLE_PODCAST,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: podcastsActions.GET_PLAYABLE_EPISODE_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* fetchEpisodesProgress() {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(getPodcastEpisodesProgressRequest);

    yield put({
      type: podcastsActions.GET_EPISODE_PROGRESS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: podcastsActions.GET_EPISODE_PROGRESS_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}
function* fetchEpisodes({ payload: id }) {
  try {
    yield put(startLoaderAction());

    const { response } = yield call(getPodcastEpisodesRequest, id);

    yield put({
      type: podcastsActions.GET_EPISODES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: podcastsActions.GET_EPISODES_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* playSelectedPodcast({ payload: id }) {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(getPodcastEpisodesRequest, id);
    yield put({ type: podcastsActions.GET_EPISODE_PROGRESS });

    yield put({
      type: podcastsActions.SET_PLAYABLE_EPISODE,
      payload: response.data?.episodes?.[0],
    });
    yield put({
      type: podcastsActions.SET_PLAYABLE_PODCAST,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: podcastsActions.GET_EPISODES_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* podcastsSaga() {
  yield takeEvery(LOCATION_CHANGE, getPodcastsPlaylistsOnLocationChange);
  yield takeEvery(LOCATION_CHANGE, getEpisodesOnLocationChange);
  yield takeEvery(LOCATION_CHANGE, getPodcastsProgressOnLocationChange);
  yield takeEvery(podcastsActions.PLAY_SELECTED_PODCAST, playSelectedPodcast);
  yield takeEvery(podcastsActions.GET_PLAYABLE_EPISODE, fetchPlayableEpisodes);
  yield takeEvery(
    podcastsActions.UPDATE_EPISODE_PROGRESS,
    updateEpisodeProgress
  );
  yield takeEvery(podcastsActions.GET_PLAYLISTS, fetchPodcastsPlaylists);
  yield takeEvery(podcastsActions.GET_EPISODES, fetchEpisodes);
  yield takeEvery(podcastsActions.GET_EPISODE_PROGRESS, fetchEpisodesProgress);
}
