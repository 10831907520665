import styled from "styled-components";
import Logo1 from '../../../assets/images/otb.png';
import Logo from '../../../assets/images/Logo-dark.svg';
import {Link} from "react-router-dom";


const HelpBlock = styled.div`
	width: 100%;
	text-align: center;
	
`;
const HelpBlockImg = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-bottom: 50px;
	@media (max-width: 992px) {
   		flex-direction: column;
  }
	
`;
const HelpText = styled.p`
	&& {
		font-size: 20px;
		margin-top: 20px;
	}
	font-weight: 500;
	text-align: center;
	width: 100%;
	
`;

const LogoOtb = styled.div`
	font-size: 19px;
	font-weight: 500;
	width: 160px;
	display: inline-block;
	height: 160px;
	margin: 50px 10px 30px 10px;
	background: url(${Logo1}) 0 0 no-repeat;
	background-size: contain;
`;

const LogoMain = styled.div`
	width: 180px;
	height: 180px;
	margin: 30px 10px;
	position: relative;
	top: 12px;
	background: url(${Logo}) 0 0 no-repeat;
	background-size: contain;
`;

export default {
	HelpText,
	LogoMain,
	LogoOtb,
	HelpBlock,
	HelpBlockImg
};
