import React from "react";

import FavoritesTabs from "containers/Favorites/Favorites.Tabs";
import FavoritesContent from "containers/Favorites/Favorites.Content";

const Favorites = React.memo(() => {
  return (
    <>
      <FavoritesTabs />
      <FavoritesContent />
    </>
  );
});

export default Favorites;
