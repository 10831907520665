import styled from "styled-components";
import { Link } from "react-router-dom";

const AuthorTxt = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: var(--gray);
  padding-bottom: 24px;
`;

const ItemTitle = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--dark);
  width: calc(100% - 50px);
  padding-top: 6px;
  padding-bottom: 8px;

  @media (max-width: 640px) {
    font-size: 16px;
  }

  @media (max-width: 1023px) {
    padding-top: 10px;
  }
`;

const Item = styled.a`
  background: #ffffff;
  border-radius: 8px;
  min-height: 168px;
  display: flex;
  max-width: 928px;
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  padding: 16px;
  padding-bottom: ${({ paddingNone }) => (paddingNone ? "0px;" : "16px;")};
  &:hover {
    text-decoration: none;
  }
`;

const ItemInner = styled(Item)`
  background: #ffffff;
  display: flex;
  max-width: 800px;
  width: 100%;
  margin-bottom: 16px;
  padding: 0;
  border: 1px solid #f2f4f6;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(127, 141, 163, 0.1);
  border-radius: 8px;
`;

const ItemLeftCol = styled.div`
  width: 92px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
const ItemRightCol = styled.div`
  width: calc(100% - 92px);
  padding-left: 24px;
  height: 100%;
  position: relative;
  min-height: 156px;
`;

const More = styled(Link)`
  font-size: 15px;
  line-height: 18px;
  position: absolute;
  bottom: 14px;
  left: 24px;
  color: var(--blue);
  &:hover {
    text-decoration: none;
    color: var(--blue);
  }
`;

export default {
  Item,
  AuthorTxt,
  ItemTitle,
  ItemLeftCol,
  ItemRightCol,
  More,
  ItemInner
};
