import styled from "styled-components";
import {Link} from "react-router-dom";
import Library from '../../../assets/images/Library.svg';
import Lectorium from '../../../assets/images/Lectorium.svg';
import Experts from '../../../assets/images/Experts.svg';
import Train from '../../../assets/images/Train.svg';
import PodcastIcon from '../../../assets/images/Podcasts.png';

const DashboardPage = styled.div`
	width: 100%;
	@media (min-width: 1340px) {
		width: 928px;
	}
`;

const PageTitle = styled.h1`
	padding-bottom: 9px;
`;

const DashboardSubtitle = styled.p`
	font-size: 13px;
	line-height: 24px;
`;

const DashboardList = styled.ul`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	padding: 26px 0 0 0;
	
	@media (max-width: 1349px) {
		padding: 26px 0 20px 0;
	}
`;

const DashboardIcon = styled.i`
	width: 70px;
	height: 70px;
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: rgba(255,255,255,0.2);
	position: relative;
	&:before {
		position: relative;
		display: inline-block;
		content: ' ';	
	}
`;

const DashboardLogo = styled.img`
  width: 50%;
`;


const ItemTitle = styled.h2`
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 24px;
	color: var(--white);
	padding-bottom: 8px;	
`;

const Txt = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 24px;
	color: var(--white);
`;

const TxtBlock= styled.div`
	width: calc(100% - 70px);
	padding-left: 24px;
`;

const DashboardItem = styled.li`
	max-width: 100%;
	width: 100%;
	@media (min-width: 1340px) {
		width: 448px;
	}
	min-height: 170px;
	display: inline-flex;
	align-items: center;
	margin-bottom: 24px;
	transition: all 0.25s ease-in;
	border-radius: 8px;

	& > a {
		padding: 24px 32px 18px 24px;

		&:hover {
			text-decoration: none;
		}

		* {
			text-decoration: none;
		}
	}

	&:nth-child(2n+1) {

		@media (min-width: 1340px) {
			margin-right: 32px;
		}
	}

	&:nth-child(1) {
		background: var(--gradientGreen);
		box-shadow: 0px 10px 20px rgba(58, 182, 122, 0.3);

		&:hover {
			box-shadow: 0px 10px 20px rgba(58, 182, 122, 0.5);
		}

		${DashboardIcon}:before {
			width: 32px;
			height: 35px;
			background: url(${Library}) 0 0 no-repeat;
			background-size: contain;
		}
	}

	&:nth-child(2) {
		background: var(--gradientOrange);
		box-shadow: 0px 10px 20px rgba(254, 131, 62, 0.3);

		&:hover {
			box-shadow: 0px 10px 20px rgba(254, 131, 62, 0.5);
		}

		${DashboardIcon}:before {
			width: 40px;
			height: 40px;
			background: url(${Lectorium}) 0 0 no-repeat;
			background-size: contain;
		}
	}
}

&:nth-child(3) {
	background: var(--gradientPurple);
	box-shadow: 0px 10px 20px rgba(185, 92, 228, 0.3);

	&:hover {
		box-shadow: 0px 10px 20px rgba(185, 92, 228, 0.5);
	}

	${DashboardIcon}:before {
		width: 40px;
		height: 40px;
		background: url(${Experts}) 0 0 no-repeat;
		background-size: contain;
	}
}

&:nth-child(4) {
	background: var(--gradientBlue);
	box-shadow: 0px 10px 20px rgba(86, 198, 245, 0.3);

	&:hover {
		box-shadow: 0px 10px 20px rgba(86, 198, 245, 0.55);
	}

	${DashboardIcon}:before {
		width: 40px;
		height: 40px;
		background: url(${Train}) 0 0 no-repeat;
		background-size: cover;
	}
}

&:nth-child(5) {
	background: var(--gradientPodcast);
	box-shadow: 0px 10px 20px rgba(206, 216, 222, 0.3);

	&:hover {
		box-shadow: 0px 10px 20px rgba(201, 206, 213, 0.55);
	}

	${DashboardIcon}:before {
		width: 40px;
		height: 40px;
		background: url(${PodcastIcon}) 0 0 no-repeat;
		background-size: contain;
	}
}

`;

const LinkItem = styled(Link)`
  display: flex;
  
  &:hover {
   
  }  
`;

export default {
	DashboardPage,
	PageTitle,
	DashboardList,
	DashboardItem,
	DashboardIcon,
	DashboardLogo,
	ItemTitle,
	Txt,
	TxtBlock,
	LinkItem,
	DashboardSubtitle
};
