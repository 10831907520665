import LowIcon from "../../assets/images/lowrisk.svg";
import MediumIcon from "../../assets/images/moderaterisk.svg";
import HighIcon from "../../assets/images/highrisk.svg";

export const RISK_CATEGORY = {
  lowRisk: {
    color: "green",
    type: "Low",
    img: LowIcon,
    bg: 'var(--gradientGreen)'
  },
  mediumRisk: {
    color: "yellow",
    type: "Medium",
    img: MediumIcon,
    bg: 'var(--gradientOrange)'
  },
  highRisk: {
    color: "red",
    type: "High",
    img: HighIcon,
    bg: 'var(--red)'
  },
};
