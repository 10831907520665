import styled from "styled-components";
import {Link} from "react-router-dom";
import Img from "../../../assets/images/takequiz.svg"

const LessonsList = styled.ul`
	list-style: none;
	padding: 0;
	display: flex;
	flex-direction: column;
	margin-top: 8px;
	margin-left: -2px;
	margin-right: -1px;
`;

const LessonsItem = styled.li`
	margin-bottom: 16px;
	cursor: pointer;	
`;

const Lesson = styled(Link)`
	width: 100%;
	display: block;
	background: #FFFFFF;
	border: 1.5px solid #F2F4F6;
	box-sizing: border-box;
	box-shadow: 0px 5px 10px rgba(127, 141, 163, 0.1);
	border-radius: 8px;
	min-height: 66px;
	cursor: pointer;
	padding: 10px 16px 14px;
	position: relative;
	transition: all 0.25s ease-in;
	&:hover {
		box-shadow: 0px 5px 10px rgba(127, 141, 163, 0.2);
		text-decoration: none;
		p,h4,div, * {
			text-decoration: none;
		}
	}

`;

const LessonNumber = styled.p`
	font-size: 15px;
	line-height: 18px;
	color: var(--gray);
	padding: 0;
	margin: 0 0 6px 0;
`;
const LessonTitle = styled.h4`
	font-weight: bold;
	font-size: 15px;
	line-height: 18px;
	padding: 0;
`;

const LessonMore = styled(Link)`
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	color: var(--dark);
	display: block;
	width: 20px;
	height: 20px;
	position: absolute;
	right: 15px;
	top: 50%;
	margin-top: -10px;
	cursor: pointer;
	&:hover,
	&:active {
		color: var(--dark);
		text-decoration: none;
	}

	&:before {
		content:'';
		position: relative;
		top: 0;
		left: 0;
		border: solid var(--dark);
		border-width: 0 1px 1px 0;
		display: inline-block;
		padding: 4px;
		transform: rotate(-45deg);	
		cursor: pointer;
	}
`;

const Author = styled.p`
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
	color: var(--gray);
	position: relative;
	top: -5px;
	margin-bottom: 0;
`;

const TakeQuiz = styled(Link)`
	width: 264px;
	height: 48px;
	background: var(--gradientButton);
	border-radius: 8px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	color: #fff;
	font-size: 15px;
	
	&:hover,
	&:active {
		color: #fff;
		text-decoration: none;
	}
	&:before {
		content: '';
		position: relative;
		display: inline-block;
		width: 20px;
		height: 20px;
		background: url(${Img}) 0 center no-repeat;
		background-size: cover;
		margin-right: 10px;
	}
`;

export default {
	Lesson,
	LessonTitle,
	LessonNumber,
	LessonMore,
	Author,
	LessonsList,
	LessonsItem,TakeQuiz


};
