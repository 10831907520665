import styled from "styled-components";

const LibraryPage = styled.div`
	
`;
const PageTitle = styled.h1`
	padding-bottom: 32px;
	font-size: 32px;
`;

const LibraryList = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
`;

const LibraryListItems = styled.div`
	width: 100%;
`;

const LibraryListItem = styled.div`
	padding-bottom: 18px;
`;

const ListTitle = styled.h4`
	font-weight: 500;
    font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: var(--gray);
`;

export default {
	ListTitle,
	LibraryPage,
	LibraryList,
	PageTitle,
	LibraryListItem,
	LibraryListItems
};
