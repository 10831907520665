import React from "react";
import {reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import {Select as AntSelect} from "antd";
import {useDispatch, useSelector} from "react-redux";
import sortBy from "lodash/sortBy";

import SelectField from "components/SelectField/SelectField";
import InputField from "components/InputField/InputField";
import {localizeText} from "utils/lang";
import {appInfoCountriesSelector, appInfoSpecialitiesSelector} from "services/appInfo/appInfo.selector";
import {updateProfileAction} from "services/profile/profile.actions";
import {setModalStatusAction} from "services/modals/modals.action";
import {MODALS_ID} from "constants/modals";
import InputPhoneField from "components/InputPhoneField/InputPhoneField";
import {requiredFieldsValidate} from "utils/validation";
import Styled from './styled/StyledProfile';
import StyledButtonRevert from "components/Button/styled/StyledButtonRevert";
import {logoutAction} from "services/logout/logout.action";

const { Option } = AntSelect;

const requiredFields = [
  "email",
  "password",
  "speciality",
  "country",
  "mobileNumber",
  "fullName",
  "confirmPassword",
  "accept",
  "placeOfWork",
  "city"
];

const ProfileForm = React.memo(({ handleSubmit }) => {
  const specialities = useSelector(appInfoSpecialitiesSelector);
  const countries = useSelector(appInfoCountriesSelector);
  const dispatch = useDispatch();
  return (
    <form onSubmit={handleSubmit}>
      <Styled.Subtitle>
        <FormattedMessage id="Profile.MainInformation" />
      </Styled.Subtitle>
        <Styled.ProfileRow>
            <Styled.ProfileCol>
                <InputField
                    name="fullName"
                    label="Profile.FullName"
                />
                <InputField
                    name="email"
                    label="Profile.Email"
                    disabled
                />
                <Styled.WrapperField>
                    <InputField
                        type="password"
                        label="Profile.Password"
                        disabled
                    />
                    <Styled.ChangePassword
                        onClick={() =>
                            dispatch(setModalStatusAction(MODALS_ID.CHANGE_PASSWORD_MODAL))
                        }
                    >
                        <FormattedMessage id="Profile.ChangePassword" />
                    </Styled.ChangePassword>
                </Styled.WrapperField>

            </Styled.ProfileCol>

            <Styled.ProfileCol>
                <InputPhoneField
                    name="mobileNumber"
                    label="Profile.Phone"
                />

            </Styled.ProfileCol>
        </Styled.ProfileRow>

      <Styled.Subtitle>
        <FormattedMessage id="Profile.AdditionalInformation" />
      </Styled.Subtitle>
        <Styled.ProfileRow>

            <Styled.ProfileCol>
                <SelectField
                    name="speciality"
                    label="Profile.Speciality"
                >
                    {specialities?.map(speciality => (
                        <Option
                            key={speciality.id}
                            value={speciality.id}
                            children={localizeText(speciality.title)}
                        />
                    ))}
                </SelectField>

                <SelectField
                    name="country"
                    label="Profile.Country"
                    showSearch
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {sortBy(countries,country => (localizeText(country.title)) )?.map((country) => (
                        <Option
                            value={country.id}
                            key={country.id}
                            children={localizeText(country.title)}
                        />
                    ))}
                </SelectField>

            </Styled.ProfileCol>


            <Styled.ProfileCol>

                <InputField
                    name="placeOfWork"
                    label="Profile.PlaceOfWork"
                />

            </Styled.ProfileCol>
        </Styled.ProfileRow>

        <Styled.ButtonsRow>

            <Styled.ProfileCol>
                <Styled.Logout onClick={() => dispatch(logoutAction())}>
                    <FormattedMessage id="Profile.Logout" />
                </Styled.Logout>
            </Styled.ProfileCol>

            <Styled.ProfileCol>
                <StyledButtonRevert htmlType="submit">
                    <FormattedMessage id="Profile.Button" />
                </StyledButtonRevert>
            </Styled.ProfileCol>

        </Styled.ButtonsRow>


    </form>
  );
});

export default reduxForm({
  form: "profileForm",
  enableReinitialize: true,
  validate: v => requiredFieldsValidate(v, requiredFields),
  onSubmit: (value, dispatch) => dispatch(updateProfileAction(value))
})(ProfileForm);
