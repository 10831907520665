import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClampLines from "react-clamp-lines";
import { useIntl } from "react-intl";
import { CaretRightOutlined } from "@ant-design/icons";

import { localizeText } from "utils/lang";
import { podcastEpisodeSelector } from "services/podcasts/podcasts.selectors";
import PodcastEpisodes from "containers/Podcast/Podcast.Episodes";
import Styled from "./styled";
import {
  playPlayerStatusAction,
  setPlayableEpisodeAction,
} from "services/podcasts/podcasts.actions";
import Input from "components/InputSearch/Input";
import StyledSearchIcon from "components/Search/styled/StyledSearchIcon";

const Podcast = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const { formatMessage } = useIntl();
  const episodes = useSelector(podcastEpisodeSelector);

  const filteredEpisodes = !!filter
    ? episodes?.episodes.filter((e) =>
        localizeText(e?.title).toLowerCase().includes(filter)
      )
    : episodes?.episodes;

  const onFilter = ({ target }) => {
    setFilter(target.value?.toLowerCase());
  };

  return (
    <>
      {episodes && (
        <div>
          <Styled.Header>
            <Styled.Cover>
              <img src={episodes?.image?.url} alt="" />
            </Styled.Cover>
            <Styled.PodcastInfoWrapper>
              <Styled.WrapperMobile>
                <Styled.CoverMobile>
                  <img src={episodes?.image?.url} alt="" />
                </Styled.CoverMobile>

                <Styled.MobileLeft>
                  <Styled.Title>{localizeText(episodes?.title)}</Styled.Title>
                  <Styled.Speaker>
                    {localizeText(episodes?.episodes?.[0]?.speaker)}
                  </Styled.Speaker>
                </Styled.MobileLeft>
              </Styled.WrapperMobile>
              <Styled.Desc>
                <ClampLines
                  text={localizeText(episodes?.shortDesc)}
                  className={"clamp"}
                  id={"podcast-desc"}
                  lines={2}
                  ellipsis={"..."}
                  moreText={formatMessage({ id: "Podcasts.More" })}
                  lessText={formatMessage({ id: "Podcasts.Less" })}
                />
              </Styled.Desc>
              <Styled.Tags>
                {episodes?.tags?.map((tag) => (
                  <Styled.Tag key={localizeText(tag?.titleDefault)}>
                    {localizeText(tag?.titleDefault)}
                  </Styled.Tag>
                ))}
              </Styled.Tags>
              <Styled.PlayButton
                onClick={() => {
                  dispatch(playPlayerStatusAction(true));
                  dispatch(setPlayableEpisodeAction(episodes?.episodes?.[0]));
                }}
              >
                <CaretRightOutlined />
                {formatMessage({ id: "Podcasts.PlayFirstEpisode" })}
              </Styled.PlayButton>
            </Styled.PodcastInfoWrapper>
          </Styled.Header>

          <Styled.Search>
            <Input
              placeholder={formatMessage({ id: "Podcasts.Search" })}
              onChange={onFilter}
              value={filter}
              prefix={<StyledSearchIcon />}
              allowClear
            />
          </Styled.Search>
          <PodcastEpisodes episodes={filteredEpisodes} />
        </div>
      )}
    </>
  );
};

export default Podcast;
