import React from "react";
import {FormattedMessage} from "react-intl";
import {reduxForm} from "redux-form";

import Button from "components/Button/Button";
import InputField from "components/InputField/InputField";
import {resetPasswordAction} from "services/password/password.action";
import {requiredFieldsValidate} from "utils/validation";
import StyledAuth from "../styled/StyledAuth";

const requiredFields = ["password", "passwordConfirmation"];

const SetNewPasswordForm = React.memo(({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <InputField
          greenborderfocus
        label="NewPassword.NewPassword"
        name="password"
        type="password"
      />
      <InputField
          greenborderfocus
        name="passwordConfirmation"
        label="NewPassword.ConfirmPassword"
        type="password"
      />

      <StyledAuth.FieldColButton>
          <Button htmlType="submit">
              <FormattedMessage id="NewPassword.Button" />
          </Button>
      </StyledAuth.FieldColButton>
    </form>
  );
});

export default reduxForm({
  form: "setNewPassword",
  validate: v => requiredFieldsValidate(v, requiredFields),
  onSubmit: (value, dispatch, { code }) =>
    dispatch(resetPasswordAction({ ...value, code }))
})(SetNewPasswordForm);
