import styled from "styled-components";
import Icon from 'assets/images/Favorites.svg';
import BurgerIcon from 'assets/images/Burger.svg';
import React from "react";
import {Link} from "react-router-dom";

const Header = styled.div`
	height: 72px;
	background: var(--white);
	display: flex;
	align-items: center;
	
	position: fixed;
	top: 0;
	z-index: 99;
	width: 100%;
	padding: 0 15px;
	
	@media (min-width: 768px) {
		width: calc(100% - 320px);
		padding-left: 56px;	
  	}
`;

const HeaderContainer = styled.div`
	width: 100%;
	max-width: 1068px;
	height: 72px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	padding-left: 32px;
	@media (min-width: 768px) {
		padding-left:0;	
  	}
`;

const FavoritesIcon = styled.i`
	width: 22px;
	height: 22px;
	background: url(${Icon}) 0 0 no-repeat;
	background-size: contain;
	cursor: pointer;
	display: inline-block;
`;

const Burger = styled.div`
	width: 72px;
	height: 72px;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	display: block;
	position: absolute;
	left: 0;
	top: 23px;
	z-index: 99;
	
	@media (min-width: 768px) {
		display: none;
  	}
	
	&:after {
		content: '';
		width: 25px;
		height: 25px;
		background: url(${BurgerIcon}) 0 0 no-repeat;
		background-size: contain;
		cursor: pointer;
		display: inline-block;	
	}
`;


const Favorites = styled(Link)`
  	width: 72px;
	height: 72px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	border-right: 1px solid var(--lightGray);
	border-left: 1px solid var(--lightGray);
	&:active,
	&:focus {
		text-decoration: none;
		background: var(--lightGray);
	}
`;

const Notification = styled.div`
  
`;
const UserBlock = styled.div`
  
  max-width: 500px;
  height: 72px;
  display: inline-flex;
  align-items: center;
  position: relative;
  @media (min-width: 768px) {
		min-width: 222px;
  }
  a {
  	font-weight: 400;
	font-size: 15px;
	line-height: 24px;
	color: var(--dark);
	&:hover {
		color: var(--dark);
		text-decoration: none;
	}
  }
`;

export default {
    Header,
    Favorites,
    Notification,
    UserBlock,
    FavoritesIcon,
    HeaderContainer,
    Burger
};
