import styled from "styled-components";

const WrapperCenter = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 24px;
`;

const Content = styled.div`
	h2 {
		margin-bottom: 16px;
	}
`;

export default {
	WrapperCenter,
	Content
};
