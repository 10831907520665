import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

import * as trainTheTrainerSelectors from "services/trainTheTrainers/trainTheTrainer.selectors";
import * as profileSelectors from "services/profile/profile.selectors";
import TrainTheTrainersCourse from "components/FavoriteItems/TrainTheTrainers.Item";
import { setModalStatusAction } from "services/modals/modals.action";
import StyledPage from "../styled/StyledPages";
import Styled from "./styled/StyledTrainTheTrainer";
import { MODALS_ID } from "constants/modals";
import TrainTheTrainersModal from "./TrainTheTrainers.Modal";

const TrainTheTrainer = React.memo(() => {
  const courses = useSelector(trainTheTrainerSelectors.coursesSelector);
  const profile = useSelector(profileSelectors.profileSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(profile) && !profile.trainTheTrainerAllowed) {
      dispatch(setModalStatusAction(MODALS_ID.TRAIN_THE_TRAINER_MODAL));
    }
  }, [profile]);

  return (
    <div>
      <StyledPage.PageTitle>
        <FormattedMessage id="TrainTheTrainer.Title" />
      </StyledPage.PageTitle>
      <Styled.List>
        {courses?.map((course) => (
          <TrainTheTrainersCourse key={course.id} {...course} />
        ))}
      </Styled.List>
      <TrainTheTrainersModal />
    </div>
  );
});

export default TrainTheTrainer;
