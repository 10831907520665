import { all } from "redux-saga/effects";

import loginSaga from "services/login/login.saga";
import registrationSaga from "services/registration/registration.saga";
import profileSaga from "services/profile/profile.saga";
import librarySaga from "services/library/library.saga";
import auditoriumSaga from "services/auditorium/auditorium.saga";
import expertSaga from "services/expert/expert.saga";
import trainTheTrainerSaga from "services/trainTheTrainers/trainTheTrainer.saga";
import riskSaga from "services/risk/risk.saga";
import appInfoSaga from "services/appInfo/appInfo.saga";
import passwordSaga from "services/password/password.saga";
import logoutSaga from "services/logout/logout.saga";
import modalsSaga from "services/modals/modals.saga";
import loaderSaga from "services/loader/loader.saga";
import favoritesSaga from "services/favorites/favorites.saga";
import podcastsSaga from "services/podcasts/podcasts.saga";

export default function* rootSaga() {
  yield all([
    loginSaga(),
    registrationSaga(),
    profileSaga(),
    librarySaga(),
    auditoriumSaga(),
    expertSaga(),
    trainTheTrainerSaga(),
    riskSaga(),
    appInfoSaga(),
    passwordSaga(),
    logoutSaga(),
    modalsSaga(),
    loaderSaga(),
    favoritesSaga(),
    podcastsSaga(),
  ]);
}
