import { notification } from "antd";
import { createIntl } from "react-intl";
import arDictionary from "../locales/ar";
import enDictionary from "../locales/en";

const lang = localStorage.getItem("lang");

const APPDictionary = {
  ar: arDictionary,
  en: enDictionary
};

export const prepareErrorMessages = ({ message, data } = {}) => {
  if (!message) return "Notifications.SomethingWentWrong";
  if (Array.isArray(message)) {
    return message
      .map(m =>
        m.messages?.map(m2 => {
          return m2?.id;
        })
      )
      .join("\n");
  } else {
    return message;
  }
};

const intl = createIntl({ locale: lang, messages: APPDictionary[lang] });

const openNotification = ({
  message,
  description,
  type = "info",
  duration
}) => {
  notification[type]({
    duration: duration ? duration : type === "error" ? 9999999999 : 4.5,
    message: intl.formatMessage({
      id: message
    }),
    description: intl.formatMessage({
      id:
        APPDictionary[lang]?.[description] && type !== "error"
          ? description
          : "Notifications.SomethingWentWrong"
    })
  });
};

export default openNotification;
