import styled from "styled-components";
import { Link } from "react-router-dom";

const Title = styled.div`
  padding-bottom: 16px;
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
`;
const TitleLogo = styled.img`
  width: 220px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 50px;
`;

const Col = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 150px;
  min-width: 150px;
  color: rgb(66, 78, 111);
  margin-right: 60px;
  &:hover {
    text-decoration: none;
  }
  img {
    width: 100%;
  }
`;

const FolderName = styled.div`
  font-weight: bold;
  text-align: center;
`;

export default {
  Row,
  Col,
  Title,
  FolderName,
  TitleLogo,
};
