import instance from "../root.api";

export const getPodcastPlaylistsRequest = () =>
  instance.get(`/playlists`).then((response) => ({ response }));

export const getPodcastEpisodesRequest = (id) =>
  instance.get(`/playlists/${id}`).then((response) => ({ response }));

export const getPodcastEpisodesProgressRequest = () =>
  instance.get(`/podcast-episodes/progress`).then((response) => ({ response }));

export const updateEpisodeProgressRequest = ({ id, progressSeconds }) =>
  instance
    .put(`/podcast-episodes/${id}/progress`, {
      episode: id,
      progressSeconds,
    })
    .then((response) => ({ response }));
