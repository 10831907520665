import React from "react";
import styled from "styled-components";
import {Button} from "antd";

const StyledButtonRevert = styled(({ ...props }) => <Button { ...props } />)`
  && {
    display: block;
    width: 100%;
    max-width: 368px;
    height: 48px;
    background: #fff;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #1B728B;
    border: 1px solid #1B728B;
    transition: all 0.3s ease-in;
   

    &:hover {
    }

    &:disabled {
      opacity: 0.7;
    }

    &.ant-btn-sm {
      width: 264px;
      height: 48px;
    }

    }
`;

export default StyledButtonRevert;
