import styled from "styled-components";
import {Select} from "antd";

const { Option } = Select;

const StyledLangControl = styled.div`
    &&{
        min-width: 130px; 
        @media (max-width: 374px) {
		  min-width: 90px; 
  		}
        height: 72px;   
    }
`;

const OptionList = styled(Select)`
    &&{
       background: #FFFFFF;
       width: 100%;
       height: 72px;
       border: none;
       &.ant-select-open {
			background: var(--lightGray) !important;
		}
       
       &:not(.ant-select-customize-input) .ant-select-selector {
		    position: relative;
		    background-color: transparent;
		    border: none;
		    height: 72px;
		    border-radius: 0;
		    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
		}
		.ant-select-selection-item {
			line-height: 70px;
			font-size: 14px;
			color: var(--dark);
			padding-right: 0;
			text-align: center;
			text-transform: uppercase;
		}
		.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
			line-height: 72px;
		}
		&.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
			border-color: transparent;
			box-shadow: none;
		}
		.ant-select-arrow {
			display: none;
		}
		
    }
`;
const OptionItem = styled(Option)`
    && {
    	font-size: 14px;
    	font-weight: 400;
		line-height: 18px;
		text-transform: uppercase;
		color: var(--dark);
		    .ant-select-item-option-content {
		    
		    }   
    }
`;

const Icon = styled.i`
    width: 20px;
    height: 15px;
    display: inline-block;
    margin-right: 10px;
    @media (max-width: 374px) {
		 display: none;
  	}
     img {
     	object-fit: cover;
     	width: 100%;
     	height: 100%;
     }
`;

export default {
	StyledLangControl,
	OptionList,
	OptionItem,
	Icon
};
