import styled, {css} from "styled-components";


const List = styled.ul`
	list-style: none;
	margin-bottom: 16px;
	padding: 0;
	margin-top: 24px;
	${({section}) => {
			if (section === 'videoItems') {
				return css`
							display: flex;
							flex-wrap: wrap;
							width: 100%;
							padding-left: 0;
							@media (min-width: 768px) {
								 margin: 0 -15px;
							}
							@media (min-width: 1024px) {
								margin: 0 -10px;
						    }
							@media (min-width: 1440px) {
								margin: 0 -15px;
						    }	
						    @media (min-width: 1430px) {
								width: 1080px;
						    }
							
			             `
			}
		}}
`;
const ListItem = styled.li`
	${({section}) => {
		if (section === 'videoItems') {
			return css`
						width: 100%;
						margin-bottom: 20px;
						@media (min-width: 767px) {
							width: 100%;
							margin: 0 10px 20px 10px;
					    }
						@media (min-width: 921px) {
							width: 43%;
							margin: 0 10px 20px 10px;
					    }
						 @media (min-width: 1340px) {
							width: 28%;
							margin: 0 15px 20px 15px;	
					    }
					    
					     @media (min-width: 1440px) {
							width: 328px;
							margin: 0 15px 20px 15px;
							
					    }
								
				        `
		}
	}}
`;
const Tabs = styled.ul`
	display: flex;
	max-width: 800px;
	list-style: none;
	align-items: center;
	border-radius: 5px;
	border: 1px solid #1B728B;
	padding: 0;
	overflow: hidden;
	background: #FFFFFF;
	@media (max-width: 480px) {
		flex-wrap: wrap;
		flex-direction: column;
	}
	@media (min-width: 920px) {
		display: inline-flex;
	}
`;

const TabItem = styled.li`
	display: inline-flex;
	width: 34%;
	min-width: 100px;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	align-items: center;
	justify-content: center;
	background: #FFFFFF;
	height: 32px;	
	cursor: pointer;
	border-right: 1px solid #1B728B;
	&:first-child {		
	}
	&:last-child {
		border-right: none;
	}
	@media (max-width: 480px) {
		width: 100%;
	}
	@media (min-width: 920px) {
		width: 150px;
	}
	
	a {
		color: #1B728B;
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
	    justify-content: center;
		
		&:hover {
			text-decoration: none;
			color: #1B728B;
		}
		
	}
	
	${({ isactive }) => {
		if(isactive) {
		return css`
	                    background: var(--gradientAside); 
	                    color: #fff;
	                    a {
							color: #fff;
							&:hover,
							&:active,
							&:focus {
								color: #fff;
							}
							
						}       
	                `
	}
}}	
`;


export default {
	List,
	Tabs,
	TabItem,
	ListItem
};
