import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import next from "../../assets/images/iconnext.svg";
import prev from "../../assets/images/iconprev.svg";

const Title = styled.h1`
  font-size: 32px;
  padding: 0;
`;

const PlaylistsHeader = styled.div`
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PlaylistsCaption = styled.div`
  color: #9a9fad;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-size: 14px;
`;

const PlaylistsViewAll = styled(Link)`
  color: #9a9fad;
  font-size: 15px;
  text-decoration: underline;
`;

const WrapperCarousel = styled.div`
  display: flex;
  
  @media (max-width: 768px) {  
    padding-left: 20px;
  }
`;

const Carousel = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  //margin: 0 -15px;
  margin-left: -15px;
  margin-right: -15px;
  //justify-content: space-between;
  
  @media (max-width: 999px) {
    width: 100%;
  }
  @media (min-width: 1000px) and (max-width: 1340px) {
    width: 100%;
    max-width: 928px;
  }

  @media (min-width: 1341px) {
    min-width: 928px;
  }

  @media (max-width: 1199px) {
    //overflow: scroll;
    padding-bottom: 10px;
  }
  .anticon {
    position: absolute;
    left: 102%;
    font-size: 25px;
  }
  //.anticon-left {
  //  right: 102%;
  //  left: initial;
  //}
`;

const TopEpisodes = styled.div`
  padding-bottom: 50px;
  @media (max-width: 767px) {
    overflow: visible;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 16px;
`;

const Item = styled(Link)`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(127, 141, 163, 0.1);
  border-radius: 8px;
  padding: 16px;
  width: 48%;
  margin-bottom: 32px;
  &:hover {
    text-decoration: none;
  }

  @media (max-width: 1199px) {
    width: 100%;
  }
`;

const ItemCover = styled.div`
  width: 116px;
  height: 116px;
  box-shadow: 0px 5px 10px rgba(127, 141, 163, 0.1);
  border-radius: 8px;
  margin-right: 16px;
  overflow: hidden;
  float: left;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const ItemTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #424e6f;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  max-height: 54px;
  padding-right: 40px;
`;

const ItemsEpisodes = styled.div`
  margin: 8px 0;
  font-size: 15px;
  color: #9a9fad;
`;

const Tag = styled.span`
  display: inline-block;
  color: #9a9fad;
  margin-right: 15px;
`;

const Tags = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  white-space: nowrap;
`;

const Status = styled.div`
  width: 20px;
  height: 20px;
  border: 0px solid #9A9FAD;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  top: 16px;
  .anticon {
    position: absolute;
    font-size: 10px;
    color: #fff;
    left: 50%;
    top: 25%;
    transform: translateX(-50%);
    visibility: hidden;
  }
  ${(props) =>
    props.isFinished &&
    css`
      background: linear-gradient(267.93deg, #1b728b 13.19%, #07546d 80.29%);
      border: none;
      .anticon {
        visibility: initial;
      }
    `}
`;

const IconNext = styled.div`
  position: absolute;
  top: 140px;
  right: -10px;
  width: 12px;
  height: 24px;
  background: url(${next}) 0 0 no-repeat;
  background-size: contain;
  cursor: pointer;
  transition: all 0.3ms ease-in;
  &:hover {
    opacity: 0.9;
  }
  @media (max-width: 640px) {
    right: -12px;
    top: 70px;
    width: 12px;
    height: 24px;
  }
  @media (max-width: 767px) {
    right: -12px;
    top: 75px;
  }

  @media (min-width: 768px) and (max-width: 1000px)  {
    right: -10px;
    top: 75px;
  }
`;

const IconPrev = styled.div`
  position: absolute;
  top: 144px;
  left: -10px;
  width: 12px;
  height: 24px;
  background: url(${prev}) 0 0 no-repeat;
  background-size: contain;
  cursor: pointer;
  transition: all 0.3ms ease-in;
  &:hover {
    opacity: 0.9;
  }
  @media (max-width: 640px) {
    top: 70px;
  }
  @media (max-width: 767px) {
    top: 75px;
    left: -9px;
  }

  @media (min-width: 768px) and (max-width: 1000px)  {
    top: 75px;
  }
`;

const Search = styled.div`
  margin-top: 20px;
  max-width: 928px;
  width: 100%;
  height: 72px;
  background: #fff;
  margin-bottom: 16px;
`;

export default {
  Title,
  PlaylistsHeader,
  PlaylistsCaption,
  PlaylistsViewAll,
  Carousel,
  Item,
  TopEpisodes,
  List,
  Tag,
  Tags,
  ItemCover,
  ItemTitle,
  ItemsEpisodes,
  Status,
  WrapperCarousel,
  Search,
  IconNext,
  IconPrev,
};
