import React from "react";
import Styled from "layouts/StaticLayout/styled/StyledStaticLayout";
import AuthFooter from "components/Footer/AuthFooter";
import StyledText from "containers/styled/StyledTextPage";

const StaticLayout = ({children}) => {
    return (<Styled.Page>
        <Styled.Logo to={"/"}></Styled.Logo>
        <Styled.PageContent>
            <StyledText.PageTextContainer>{children}</StyledText.PageTextContainer>
        </Styled.PageContent>
        <AuthFooter/>
    </Styled.Page>);
};

export default StaticLayout;
