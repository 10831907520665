import styled, {css} from "styled-components";
import {Button} from "antd";

const StyledButton = styled(({ ...props }) => <Button { ...props } />)`
  && {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: ${({ width }) => width ? width : "368px;"};
    margin-top: ${({ top }) => (top ? top :'0;')}; 	
		
	${({aligntype}) => {
      if (aligntype && aligntype === 'center') {
        return css`
                              margin-left: auto;
                              margin-right: auto;
                            `
      }
    }};
   @media (max-width: 480px) {
		 width: 100%;
  }
    height: 48px;
    line-height: 48px;
    background: var(--gradientButton);
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #fff;
    border: none;
    transition: all 0.3s ease-in;

    &:hover {
      color: var(--white)
    }

    &:disabled {
      opacity: 0.7;
    }

    &.ant-btn-sm {
      width: 264px;
      height: 48px;
    }

    }
`;

export default StyledButton;
