import {createAction} from "redux-actions";

export const GET_FAVORITES = "GET_FAVORITES";
export const GET_FAVORITES_SUCCESS = "GET_FAVORITES_SUCCESS";
export const GET_FAVORITES_FAILURE = "GET_FAVORITES_FAILURE";

export const GET_FAVORITES_IDS = "GET_FAVORITES_IDS";
export const GET_FAVORITES_IDS_SUCCESS = "GET_FAVORITES_IDS_SUCCESS";
export const GET_FAVORITES_IDS_FAILURE = "GET_FAVORITES_IDS_FAILURE";

export const ADD_TO_FAVORITES = "ADD_TO_FAVORITES";
export const ADD_TO_FAVORITES_SUCCESS = "ADD_TO_FAVORITES_SUCCESS";
export const ADD_TO_FAVORITES_FAILURE = "ADD_TO_FAVORITES_FAILURE";

export const REMOVE_FROM_FAVORITES = "REMOVE_FROM_FAVORITES";
export const REMOVE_FROM_FAVORITES_SUCCESS = "REMOVE_FROM_FAVORITES_SUCCESS";
export const REMOVE_FROM_FAVORITES_FAILURE = "REMOVE_FROM_FAVORITES_FAILURE";

export const addToFavoritesAction = createAction(ADD_TO_FAVORITES);
export const removeFormFavoritesAction = createAction(REMOVE_FROM_FAVORITES);
