import { call, put, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import { urlLocations } from "routes/urlLocations";
import { isUrlMatch } from "services/router/router.utils";

import {
  addToFavoritesRequest,
  getFavoritesIdsRequest,
  getFavoritesRequest,
  removeFromFavoritesRequest
} from "./favorites.api";
import * as favoritesActions from "./favorites.actions";

import {
  finishLoaderAction,
  startLoaderAction
} from "services/loader/loader.action";

function* getFavoritesOnLocationChangeData({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.favoritesSection)) {
      yield put({ type: favoritesActions.GET_FAVORITES });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getFavoritesIdsOnLocationChangeData({ payload }) {
  try {
    if (
      isUrlMatch(payload, urlLocations.library) ||
      isUrlMatch(payload, urlLocations.auditorium) ||
      isUrlMatch(payload, urlLocations.train) ||
      isUrlMatch(payload, urlLocations.favoritesSection)
    ) {
      yield put({ type: favoritesActions.GET_FAVORITES_IDS });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchFavorites() {
  try {
    yield put(startLoaderAction());

    const { response } = yield call(getFavoritesRequest);

    yield put({
      type: favoritesActions.GET_FAVORITES_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: favoritesActions.GET_FAVORITES_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* fetchFavoritesIds() {
  try {
    yield put(startLoaderAction());

    const { response } = yield call(getFavoritesIdsRequest);

    yield put({
      type: favoritesActions.GET_FAVORITES_IDS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: favoritesActions.GET_FAVORITES_IDS_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* addToFavorites({ payload }) {
  try {
    yield put(startLoaderAction());

    const { response } = yield call(addToFavoritesRequest, payload);

    yield put({
      type: favoritesActions.ADD_TO_FAVORITES_SUCCESS,
      payload: response.data
    });
    yield put({ type: favoritesActions.GET_FAVORITES_IDS });
  } catch (error) {
    yield put({ type: favoritesActions.ADD_TO_FAVORITES_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* removeFromFavorites({ payload }) {
  try {
    yield put(startLoaderAction());

    const { response } = yield call(removeFromFavoritesRequest, payload);

    yield put({
      type: favoritesActions.REMOVE_FROM_FAVORITES_SUCCESS,
      payload: response.data
    });
    yield put({ type: favoritesActions.GET_FAVORITES_IDS });
  } catch (error) {
    yield put({ type: favoritesActions.REMOVE_FROM_FAVORITES_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* favoritesSaga() {
  yield takeEvery(LOCATION_CHANGE, getFavoritesOnLocationChangeData);
  yield takeEvery(LOCATION_CHANGE, getFavoritesIdsOnLocationChangeData);
  yield takeEvery(favoritesActions.GET_FAVORITES, fetchFavorites);
  yield takeEvery(favoritesActions.GET_FAVORITES_IDS, fetchFavoritesIds);
  yield takeEvery(favoritesActions.ADD_TO_FAVORITES, addToFavorites);
  yield takeEvery(favoritesActions.REMOVE_FROM_FAVORITES, removeFromFavorites);
}
