import styled from "styled-components";
import {Input} from "antd";

const SearchBlock = styled.div`
	max-width: 928px;
	width: 100%;
	height: 72px; 
	background: #fff;
	margin-bottom: 24px;	
`;

const SearchInput = styled(Input)`
	max-width: 928px;
	width: 100%;
	height: 72px; 
	border: 1px solid transparent;
	background: #fff;
`;

export default {
	SearchBlock,
	SearchInput
}
