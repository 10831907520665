import styled from "styled-components";
import LogoAuth from '../../assets/images/logo-auth.svg';
import Bg from '../../assets/images/Vectorbg.svg';
import {Link} from "react-router-dom";

const Page = styled.div`
	background-image: var(--gradientAside);
	background-position: center;
    position: relative;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    padding-top:  90px;
    
     @media (max-width: 930px) {
		padding-left: 10px;
		padding-right: 10px;
  	}
    
    &:after {
    	content: '';
    	position: absolute;
	    left: 0;
	    right: 0;
	    top: 0;
	    bottom: 0;
    	width: 100%;
    	height: 100%;
    	z-index: 1;
    	background: url(${Bg}) center center no-repeat;
    	background-size: cover;
    	pointer-events: none;
    }
`;
const Title = styled.h1`
	font-style: normal;
	font-weight: bold;
	font-size: 40px;
	line-height: 48px;
	text-align: center;
	color: #FFFFFF;
	margin-top: 40px;
	margin-bottom: 0;
	padding: 0;
`;

const Subtitle = styled.h3`
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	text-align: center;
	color: #FFFFFF;
	padding: 0;
	max-width: 450px;
	margin: 40px auto 0 auto;
`;

const Logo = styled(Link)`
	width: 116px;
	height: 94px;
	display: block;
	background: url(${LogoAuth}) center center no-repeat;
  	background-size: contain;
  	margin: 0 auto 0 auto;	
  	padding-top: 97px;
`;

const FieldCol = styled.div`
	position: relative;
    margin-bottom: 12px;
`;

const FieldColButton = styled.div`
	position: relative;
    margin-top: 24px;
`;

const AuthLink  = styled(Link)`
  padding-top: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  display: inline-block;
  color: var(--blue);
  
  &:hover {
  	color: var(--blue);
  	text-decoration: underline !important;
  }
  &:focus {
  	color: var(--blue);
  }
  &[disabled] {
  	color: var(--blue);
  	opacity: 0.8;
  }
`;

const CenterLinkWrap  = styled.div`
	width: 100%;
	a{
		display: inline-block;
		width: auto;
	}
	text-align: center;
`;

const ForgotLink  = styled(AuthLink)`
	padding-top: 4px;
	width: auto;
	display: inline-block;
	margin: 0 auto 24px auto;
`;

const WrapperForm = styled.div`
  max-width: ${({ width }) => width ? width : "448px;"};
  min-height: ${({ height }) => height ? height : "375px;"};
  margin: 0 auto;
  padding: 32px 40px;
  @media (max-width: 767px) {
		padding: 32px 15px;
  }
  display: flex;
  justify-content: center;
  background: #fff;
  border-radius: 8px;
  position: relative;
  top: 40px;
  z-index: 2;
  form {
  	width: 100%;
  }
`;

export default {
	Logo,
	Subtitle,
	ForgotLink,
	Title,
	AuthLink,
	Page,
	WrapperForm,
	FieldCol,
	FieldColButton,
	CenterLinkWrap
};
