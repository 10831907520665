import {GET_APP_INFO_SUCCESS} from "./appInfo.action";
import initialState from "../../store/initialState";

export default (state = initialState.appInfo, action) => {
    switch (action.type) {
        case GET_APP_INFO_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};
