import styled from "styled-components";
import {Checkbox} from "antd";

export default styled(Checkbox)`
  &&& {
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: var(--dark);
    padding-left: 2px;
    display: flex;
    border-radius: 2px;
    align-items: center;

    &.ant-checkbox-wrapper-checked {
      color: var(--dark);
    }

    input[type="checkbox"] {
      width: 18px;
      height: 18px;
    }

    &.ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled {
      color: var(--gray);
    }

    &.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
      color: var(--dark);
    }

    &:hover {
      border-color: var(--blue);
      .ant-checkbox-inner {
        border-color: var(--blue);
      }
    }

    .ant-checkbox {
      width: 18px;
      height: 18px;
      margin-right: 5px;
      border: 1px solid var(--blue);
      border-radius: 2px;

      &.ant-checkbox-checked .ant-checkbox-inner::after {
        opacity: 1;
 		width: 10px;
	    height: 10px;
	    left: 3px;
	    top: 3px;
        transform: none;
        border: none;
        background: var(--blue);
      }
       &.ant-checkbox-checked::after {
        border: none;
      }

      &.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner::after {
        //background: url() center center no-repeat;
      }

      &.ant-checkbox-checked .ant-checkbox-inner {
        //border: 1px solid var(--blue);
        //color: var(--dark);
        //background: var(--blue);
        //border-radius: 2px;
      }     //border: 1px solid var(--blue);
        //color: var(--dark);
        //background: var(--blue);
        //border-radius: 2px;

      & + span {
        width: 100%;
        font-size: 14px;
        display: inline-block;
      }
    }

    .ant-checkbox-inner {
      width: 18px;
      height: 18px;
      top: 0;
      background: none;
      border: none;
      position: relative;
      border-radius: 2px;
    }
    .ant-checkbox-checked.ant-checkbox-inner {
      //border: 1px solid var(--blue);
    }
    .ant-checkbox-checked.ant-checkbox-disabled {
    }
  }
`;
