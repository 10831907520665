import styled from "styled-components";
import { Link } from "react-router-dom";

const BackButton = styled(Link)`
  color: #424e6f;
  display: inline-block;
  margin-bottom: 25px;
  span {
    display: inline-block;
    vertical-align: middle;
  }
  .anticon {
    margin-right: 18px;
    font-size: 12px;
  }
  &:hover {
    text-decoration: none;
  }
`;

const Title = styled.div`
  margin-bottom: 32px;
  color: #424e6f;
  font-size: 32px;
  font-weight: bold;
`;

const List = styled.div`
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 1000px) {
    justify-content: center;
  }
`;

const Item = styled.div`
  max-width: 33%;
  padding: 0 15px 15px;
  display: inline-flex;
  
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 33%;
  }
  @media (min-width: 1000px) and (max-width: 1199px){
    width: 45%;
    max-width: 45%;
  }

  @media (max-width: 999px){
    width: 288px;
    max-width: 288px;
  }
  
`;

export default {
  BackButton,
  Title,
  List,
  Item,
};
