import styled, {css} from "styled-components";
import LogoImg from "assets/images/Logoleft.svg";
import Arrow from "assets/images/arrDashboard.svg";
import ArrowClose from "assets/images/Close.svg";
import {Link} from "react-router-dom";

const Aside = styled.aside`
  width: 320px;
  height: 100vh;
  @media (max-width: 767px) { 
  }
  min-height: 750px;
  display: flex;
  background: var(--gradientAside);
  flex-direction: column;
  position: relative;
  
   @media (max-width: 767px) {
		//display: none;
		position: absolute;
        left: 0;
        top: 0;
        z-index: 100;
        transition: all .3s ease-in-out;
        transform: translateX(-100%);
        ${({visibilityAside}) => {
    if (visibilityAside) {
        return css`
                            transform: translateX(0);
                          `
    }
}}
  	}
  @media (min-width: 768px) {
		display: flex;
  	}
`;

const LogoAside = styled.div`
  height: 96px;
  width: 106px;
  margin-left: 58px;
  display: inline-block;
  margin-top: 16px;
  background: url(${LogoImg}) center center no-repeat;
  background-size: contain;
`;

const CloseIcon = styled.div`
  height: 20px;
  width: 20px;
  position: absolute;
  right: 15px;
  top: 20px;
  background: url(${ArrowClose}) center center no-repeat;
  background-size: contain;
  
    @media (min-width: 768px) {
		display: none;
  	}
  	
`;

const Nav = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  padding: 0;
`;

const ItemNav = styled.li`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 55px;
  align-items: center;
  padding-left: 58px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #FFFFFF;
  opacity: 0.7;
  transition: all 0.25s ease-in;
  
   &:after {
    content: '';
    position: absolute;
    width: 5px;
    height: 10px;
    right: 23px;
    top: 50%;
    margin-top: -6px;
    background: url(${Arrow}) center center no-repeat;
    background-size: cover; 
    cursor: pointer; 
    opacity: 0;
    transition: all 0.2s ease-in;
  }
  
  ${({isactive}) => {
    if (isactive) {
        return css`
                  font-weight: 500;
                  opacity: 1;
                  background: rgba(255,255,255,0.05); 
                  &:after {
                    opacity: 1;
                  }
              `
    }
}};
  
 
  &:hover {
     opacity: 1;
     background: rgba(255,255,255,0.05);
     &:after {
        opacity: 1;
     }
  }
  
    ${({disabled}) => {
    if (disabled) {
        return css`
                        opacity: 0.5;
                        &:hover {
                          opacity: 0.5;
                          background: transparent;
                         &:after {
                            opacity: 0;
                         }
  }
                      `
    }
}};
 
`;

const LinkNav = styled(Link)`
  width: 100%;
  color: #FFFFFF;
  min-height: 55px;
  align-items: center;
  display: inline-flex;
  transition: all 0.25s ease-in;
  &:hover {
    color: #FFFFFF;
    text-decoration: none;
    
  }
  &[disabled] {
    color: rgba(255,255,255,0.5) !important;
  }
  &:disabled {
    color: rgba(255,255,255,0.5) !important;
      &:hover {
         color: rgba(255,255,255,0.5);
      }
  }
  
`;

const Icon = styled.i`
  display: inline-block;
  margin-right: 18px; 
  width: 24px;
  height: 24px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Logo = styled.img`
  width: 50%;
`;

const Version = styled.p`
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    opacity: 0.4;
    position: absolute;
    bottom: 42px;
    left: 56px;
    @media (min-height: 751px) and (max-height: 850px) {
		 bottom: 25px;
    }
    
    @media (max-height: 750px) {
		 bottom: 15px;
    }
    
    
  
`;


export default {
    Aside,
    Nav,
    ItemNav,
    LogoAside,
    Icon,
    LinkNav,
    Version,
    Logo,
    CloseIcon
};
