import React from "react";
import {useParams} from "react-router";
import {useSelector} from "react-redux";

import TrainTheTrainerItem from "components/FavoriteItems/TrainTheTrainers.Item";
import AuditoriumItem from "components/FavoriteItems/Auditorium.Item/Auditorium.Item";
import LibraryItem from "components/FavoriteItems/Library.Item";
import {FAVORITES_TYPES} from "constants/favorites";
import {favoritesSelector} from "services/favorites/favorites.selector";
import Styled from './styled/StyledFavorites';

const ITEMS = {
  [FAVORITES_TYPES.VIDEO]: AuditoriumItem,
  [FAVORITES_TYPES.COURSES]: TrainTheTrainerItem,
  [FAVORITES_TYPES.LIBRARY]: LibraryItem
};

const FavoritesContent = React.memo(() => {
  const { section } = useParams();
  const favorites = useSelector(favoritesSelector,);
  return (
    <div>
      <Styled.List section={section}>
        {favorites[section]?.map(item => (
          <Styled.ListItem section={section} key={item.id}>{React.createElement(ITEMS[section], item)}</Styled.ListItem>
        ))}
      </Styled.List>
    </div>
  );
});

export default FavoritesContent;
