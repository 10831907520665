import instance from "../root.api";

export const getFavoritesRequest = () =>
    instance.get(`/favorites/my-favorites`).then((response) => ({response}));

export const getFavoritesIdsRequest = () =>
    instance
        .get(`/favorites/my-favorites-ids`)
        .then((response) => ({response}));

export const addToFavoritesRequest = ({id, path}) =>
    instance.post(`/favorites/${path}/${id}`).then((response) => ({response}));

export const removeFromFavoritesRequest = ({id, path}) =>
    instance
        .delete(`/favorites/${path}/${id}`)
        .then((response) => ({response}));
