import { call, put, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import { history, urlLocations } from "routes/urlLocations";
import { isUrlMatch } from "services/router/router.utils";

import { getCoursesRequest, submitAnswerRequest } from "./trainTheTrainer.api";
import * as trainTheTrainerActions from "./trainTheTrainer.actions";
import {
  finishLoaderAction,
  startLoaderAction
} from "services/loader/loader.action";

function* getCoursesOnLocationChangeData({ payload }) {
  try {
    if (
      isUrlMatch(payload, urlLocations.train) ||
      isUrlMatch(payload, urlLocations.courseInfo) ||
      isUrlMatch(payload, urlLocations.lessonInfo) ||
      isUrlMatch(payload, urlLocations.quizInfo)
    ) {
      yield put({ type: trainTheTrainerActions.GET_COURSES });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchCourses() {
  try {
    yield put(startLoaderAction());

    const { response } = yield call(getCoursesRequest);

    yield put({
      type: trainTheTrainerActions.GET_COURSES_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: trainTheTrainerActions.GET_COURSES_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* submitAnswer({ payload: data }) {
  try {
    yield put(startLoaderAction());

    const { response } = yield call(submitAnswerRequest, data);

    yield put({
      type: trainTheTrainerActions.SUBMIT_ANSWER_SUCCESS,
      payload: response.data
    });

    yield history.push(urlLocations.quizResult);
  } catch (error) {
    yield put({ type: trainTheTrainerActions.SUBMIT_ANSWER_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* trainTheTrainerSaga() {
  yield takeEvery(LOCATION_CHANGE, getCoursesOnLocationChangeData);
  yield takeEvery(trainTheTrainerActions.GET_COURSES, fetchCourses);
  yield takeEvery(trainTheTrainerActions.SUBMIT_ANSWER, submitAnswer);
}
