import styled from "styled-components";

const ModalContentCenter = styled.div`
	text-align: center;
	padding-top: 20px;
	.ant-btn {
		margin-top: 20px !important;
	}
  	
`;
export default ModalContentCenter;
