import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";

import {getRiskTestSelector} from "services/risk/risk.selectors";
import {clearResults, submitRisk} from "services/risk/risk.actions";
import {localizeText} from "utils/lang";
import Button from "components/Button/Button";
import RiskResult from "./Risk.Result";
import Styled from './styled/StyledRisk';
import pull from 'lodash/pull';

const RiskTest = React.memo(() => {
  const dispatch = useDispatch();
  const test = useSelector(getRiskTestSelector);
  const [answers, setAnswer] = useState({});

  const onOptionClick = useCallback(
    (id, answer, multiSelect) => {
        let selections = answers[id] || []
        if (multiSelect) {
            const isSelectedBefore = selections.includes(answer) ?? false
            if(isSelectedBefore) {
                // deselect
                pull(selections, answer)
            } else {
                selections.push(answer)
            }
        } else {
            selections = [answer]
        }
      setAnswer({ ...answers, [id]: selections });
    },
    [answers]
  );

  const onTryAgainClick = useCallback(() => {
    dispatch(clearResults());
    setAnswer({});
  }, []);

  return (
    <div>
      <Styled.QuestionList>
        {test.questions?.map((item, index) => (
          <Styled.QuestionItem key={item.id}>

              <Styled.QuestionTxt>
              {index + 1}.{localizeText(item.title)}
            </Styled.QuestionTxt>

              <Styled.AnswersList>
              {item?.options.map(option => (
                <Styled.Answer
                    isactive={ answers[item.id]?.includes(option.id)  ?? false }
                    onClick={onOptionClick.bind(null, item.id, option.id, item.multiSelect)}
                    key={option.id}
                >
                  {localizeText(option)}
                </Styled.Answer>
              ))}
            </Styled.AnswersList>
          </Styled.QuestionItem>
        ))}
      </Styled.QuestionList>
      <Button
        onClick={() => dispatch(submitRisk({ answers }))}
        width={'380px;'}
      >
        <FormattedMessage id="RiskCalculator.Button" />
      </Button>

      <RiskResult onTryAgainClick={onTryAgainClick} />
    </div>
  );
});

export default RiskTest;
