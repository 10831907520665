import { urlLocations } from "routes/urlLocations";
import DiaSVGImg from "assets/images/DiaVoiceSVG.svg";

export const DASHBOARD = [
  {
    title: "Library",
    path: urlLocations.library
  },
  {
    title: "Auditorium",
    path: urlLocations.auditoriumVideos
  },
  {
    title: "Experts",
    path: urlLocations.experts
  },
  {
    title: "TrainTheTrainer",
    path: urlLocations.train
  },
  {
    title: "Podcasts",
    logo: DiaSVGImg,
    path: urlLocations.podcastsFolders
  }
];
