import { call, put, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import { urlLocations } from "routes/urlLocations";
import { isUrlMatch } from "services/router/router.utils";
import { getRiskTestRequest, submitRiskRequest } from "./risk.api";
import * as riskActions from "./risk.actions";
import values from "lodash/values";
import {
  finishLoaderAction,
  startLoaderAction,
} from "services/loader/loader.action";

function* getRiskTestOnLocationChangeData({ payload }) {
  try {
    if (
      isUrlMatch(payload, urlLocations.riskTest) ||
      isUrlMatch(payload, urlLocations.publicRiskTest)
    ) {
      yield put({ type: riskActions.GET_RISK });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchRiskTest() {
  try {
    yield put(startLoaderAction());

    const { response } = yield call(getRiskTestRequest);

    yield put({
      type: riskActions.GET_RISK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: riskActions.GET_RISK_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* submitRiskTest({ payload: data }) {
  try {
    yield put(startLoaderAction());

    const selections = [];
    values(data.answers).forEach((questionSelection) => {
      selections.push(...questionSelection);
    });
    const { response } = yield call(submitRiskRequest, { answers: selections });

    yield put({
      type: riskActions.SUBMIT_RISK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: riskActions.SUBMIT_RISK_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* riskSaga() {
  yield takeEvery(LOCATION_CHANGE, getRiskTestOnLocationChangeData);
  yield takeEvery(riskActions.GET_RISK, fetchRiskTest);
  yield takeEvery(riskActions.SUBMIT_RISK, submitRiskTest);
}
