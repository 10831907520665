import styled from "styled-components";
import {Input} from "antd";

const StyledInput = styled(Input)`
  && {
	    background-color: #fff;
	    position: relative;
	    padding: 14px 14px 9px 14px;
	    color: var(--dark);
	    height: 72px;
	    width: 100%;
	    border-radius: 0;
	    border: 1px solid transparent;
	    font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 72px;
	    font-stretch: normal;
	    letter-spacing: normal;
	    
	    &:focus {
	      border: 1px solid transparent;
	    }
	    &:hover {
	      border: 1px solid transparent;
	    }
	    .ant-input-focused {
	      border: 1px solid transparent;
	    }
	
	    &[disabled] {
	      border: 1px solid transparent;
	      opacity: 0.9;
	    }
	    .ant-input-prefix {
	      width: 22px;
	      height: 22px;
	      display: flex;
	      top: 11px;
	      position: relative;
	      justify-content: center;
	      align-items: center;
	      margin-right: 8px;
	    }
	    .ant-input-affix-wrapper:hover {
	    	border-color: transparent;
	    	box-shadow: none;
	    } 
	    .ant-input-affix-wrapper {
	    	box-shadow: none;
	    } 
	    &.ant-input-affix-wrapper:focus, 
	    &.ant-input-affix-wrapper-focused {
	    	border-color: transparent;
	    	box-shadow: none;
	    }
	}
`;


export default StyledInput;
