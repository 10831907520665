export const coursesSelector = (state) => state.trainTheTrainer.courses;
export const resultSelector = (state) => state.trainTheTrainer.result;

export const courseSelector = (state, id) =>
  state.trainTheTrainer.courses?.find((course) => course.id === parseInt(id));

export const lessonSelector = (state, courseId, lessonId) =>
  state.trainTheTrainer.courses
    ?.find((course) => course.id === parseInt(courseId))
    ?.courseLessons?.find((lesson) => lesson.id === parseInt(lessonId));
