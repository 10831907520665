import Login from "./Login/Login";
import Registration from "./Registration/Registration";
import Dashboard from "./Dashboard/Dashboard";
import Library from "./Library/Library";
import Auditorium from "./Auditorium/Auditorium";
import Experts from "./Experts/Experts";
import Expert from "./Expert/Expert";
import TrainTheTrainer from "./TrainTheTrainer/TrainTheTrainer";
import Course from "./Course/Course";
import Lesson from "./Lesson/Lesson";
import Quiz from "./Quiz/Quiz";
import QuizResult from "./QuizResult/QuizResult";
import Risk from "./Risk/Risk";
import Profile from "./Profile/Profile";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import SetNewPassword from "./SetNewPassword/SetNewPassword";
import About from "./About/About";
import Help from "./Help/Help";
import TermsOfUse from "./TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import Favorites from "./Favorites/Favorites";
import RiskInfo from "./RiskInfo/RiskInfo";
import Podcasts from "containers/Podcasts/Podcasts";
import TopPodcasts from "containers/TopPodcasts/TopPodcasts";
import Podcast from "containers/Podcast/Podcast";
import PodcastsFolders from "./PodcastsFolders/PodcastsFolders";

const Containers = {
  Login,
  Registration,
  Dashboard,
  Library,
  Auditorium,
  Experts,
  Expert,
  Course,
  Lesson,
  Quiz,
  QuizResult,
  Risk,
  About,
  Profile,
  TrainTheTrainer,
  ForgotPassword,
  SetNewPassword,
  Help,
  Favorites,
  TermsOfUse,
  PrivacyPolicy,
  RiskInfo,
  Podcasts,
  TopPodcasts,
  Podcast,
  PodcastsFolders,
};

export default Containers;
