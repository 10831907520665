import React from "react";
import {FormattedMessage} from "react-intl";
import {useParams} from "react-router";

import {EXPERTS} from "constants/experts";
import {urlLocations} from "routes/urlLocations";
import ExpertForm from "containers/Expert/Expert.Form";
import StyledPage from '../styled/StyledPages';
import StyledPages from '../styled/StyledPages';
import Styled from './styled/Expert';

const Expert = React.memo(() => {
  const { section } = useParams();
  const expert = EXPERTS.find(expert => expert.type === section);
  return (
      <>
          <StyledPages.BackBtn to={urlLocations.experts}>
              <FormattedMessage id="Expert.Back" />
          </StyledPages.BackBtn>
          <StyledPage.PageBgContainer>
              <StyledPage.PageTitle>
                  <FormattedMessage id={`Experts.${expert.dataKey}.Title`} />
              </StyledPage.PageTitle>
              <ExpertForm
                  initialValues={{
                      email: "",
                      subject: "",
                      question: ""
                  }}
                  section={section}
              />
              <Styled.FormTxt>
                  <FormattedMessage id="Expert.Desc" />
              </Styled.FormTxt>
          </StyledPage.PageBgContainer>
      </>
  );
});

export default Expert;
