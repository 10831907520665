export default {
  "About.Content": "<h1> DaR Academy Hakkında </h1> <h2> Kütüphane </h2> <p> Videolara, kılavuzlara, makalelere ve yayınlara göz atmak ve görüntülemek için kütüphaneyi açın\nSık kullanılanlara öğe ekleyebilir ve daha sonra sık kullanılanlar bölümünden izleyebilirsiniz </p> <h2> Konferans salonu <h2> <p> Arşivlenmiş dersler, web yayınları ve canlı akış videoları izlemek için </p> <h2> Uzmanlar </h2> <p> Uzmanlarımız size yardımcı olabilir, sadece kategori seçin, formu doldurun ve sorunuzu gönderin ve cevapları e-posta ile almalısınız </br> Her kategorideki uzmanlar hakkında daha fazla bilgi edinmek için kategoriyi açın ve sorunuzu yazın </p> <h2> Hesabım </h2> <p> Hesap profilinizi yönetebilir ve parolanızı değiştirebilirsiniz </p> </br> </br> <p> Bu Web Portalının hazırlanması, Sanofi Körfez Ülkeleri tarafından sağlanan sınırsız bir eğitim hibesi ile mümkün kılındı </br> &kopya; 2018 DAR ittifak. Tüm hakları saklıdır. </p>",
  "Aside.About": "Hakkında",
  "Aside.AdditionalInfo": "Ek bilgiler",
  "Aside.Auditorium": "Konferans salonu",
  "Aside.Dashboard": "Gösterge paneli",
  "Aside.Experts": "Uzmanlar",
  "Aside.Help": "Yardım",
  "Aside.Podcasts": "Podcast'ler",
  "Aside.Library": "Kütüphane",
  "Aside.RiskCalculator": "Risk Hesaplayıcı",
  "Aside.TrainTheTrainer": "Eğitmeni Eğit",
  "Aside.Version": "Sürüm 3.5.10",
  "Auditorium.By": "yoluyla",
  "Auditorium.Tabs.Lectures": "Düz anlatım",
  "Auditorium.Tabs.Videos": "Videolar",
  "Auditorium.Title": "Konferans salonu",
  "Auth.form.error.email.format": "Lütfen geçerli bir e-posta adresi girin.",
  "Auth.form.error.email.taken": "E-posta zaten alınmış",
  "Auth.form.error.invalid": "E-posta veya şifre geçersiz",
  "Auth.form.error.user.not-exist": "Bu e-posta mevcut değil",
  "ChangePassword.Success.Desc": "Başarılı",
  "ChangePassword.Success.Title": "Başarılı",
  "Dashboard.Auditorium.Desc": "Arşivlenmiş dersler, web yayınları ve canlı izleyin.",
  "Dashboard.Auditorium.Title": "Konferans salonu",
  "Dashboard.Desc": "Ramazan'daki kişisel diyabet asistanınız",
  "Dashboard.Experts.Desc": "Uzmanlarımız size yardımcı olabilir, sadece sorunuzu gönderin.",
  "Dashboard.Experts.Title": "Uzmanlar",
  "Dashboard.Library.Desc": "Kılavuzlara, makalelere ve yayınlara göz atın.",
  "Dashboard.Library.Title": "Kütüphane",
  "Dashboard.Title": "Dar Akademi'ye Hoşgeldiniz",
  "Dashboard.TrainTheTrainer.Desc": "Çok daha fazla bilgiye maruz kalalım.",
  "Dashboard.TrainTheTrainer.Title": "Eğitmeni Eğit",
  "Dashboard.Podcasts.Title":"Podcast'ler",
  "Dashboard.Podcasts.Desc": "Podcast'ler",
  "Expert.Back": "Tüm kategorilere geri dön",
  "Expert.Desc": "Diyetisyenler heyetimiz, Ramazan ile ilgili soru sırasında yemeğinize e-posta yoluyla cevap verecektir.",
  "Expert.Email": "E-posta",
  "Expert.Question": "Lütfen soru bilgilerini girin",
  "Expert.Send": "İstek gönder",
  "Expert.Subject": "Konu",
  "Expert.Success.Desc": "Başarılı",
  "Expert.Success.Title": "Başarılı",
  "Experts.Desc": "Uzmanlarımız size yardımcı olabilir, Sadece kategoriyi seçin ve uzmanlarımıza sorun",
  "Experts.DiabetesConsultant.Desc": "Diyabet danışmanları panelimiz tıbbi sorularınıza e-posta yoluyla cevap verecektir.",
  "Experts.DiabetesConsultant.Title": "Diyabet Danışmanı",
  "Experts.DiabetesNurseEducator.Desc": "Diyabet hemşiresi eğitimcileri grubumuz sorularınızı e-posta yoluyla cevaplayacaktır.",
  "Experts.DiabetesNurseEducator.Title": "Diyabet Hemşire Eğitimcisi",
  "Experts.Dietician.Desc": "Diyetisyenler heyetimiz, Ramazan ile ilgili sorularınız sırasında yemeğinize e-posta yoluyla cevap verecektir.",
  "Experts.Dietician.Title": "Diyetisyen",
  "Experts.ReligiousScholar.Desc": "Saygın din akademisyenlerinden oluşan heyetimiz, oruç tutmayla ilgili sorularınıza e-posta yoluyla cevap verecektir.",
  "Experts.ReligiousScholar.Title": "Din Bilgini",
  "Experts.Title": "Ofisler Uzmanlar",
  "Favorites.Tabs.Auditorium": "Konferans salonu",
  "Favorites.Tabs.Library": "Kütüphane",
  "Favorites.Tabs.TrainTheTrainer": "Eğitmeni Eğit",
  "Footer.Desc": "Bu portal sınırsız bir eğitim ödeneği ile hazırlanmıştır Sanofi Körfez Ülkeleri\n© 2018 DAR alliance. Bütün hakları saklıdır",
  "Footer.Desc.Servier":
    "The preparation of this PODCAST was made possible through an unrestricted education support provided by SERVIER GCC\n© 2020 DAR alliance. All rights reserved",
  "ForgotPassword.Button": "Şifre sıfırlama",
  "ForgotPassword.Desc": "E-posta adresinizi girin ve size şifrenizi sıfırlamak için bir bağlantı göndereceğiz",
  "ForgotPassword.Email": "E-posta",
  "ForgotPassword.Success.Desc": "Başarılı",
  "ForgotPassword.Success.Title": "Başarılı",
  "ForgotPassword.Title": "Şifrenizi mi unuttunuz?",
  "Header.ItemLogout": "Çıkış Yap",
  "Header.ItemProfile": "Profil Ayarı",
  "Help.Content": "<h1> Yardım </h1> <h2> Kütüphane </h2> <p> Videolara, kılavuzlara, makalelere ve yayınlara göz atmak ve görüntülemek için kütüphaneyi açın\nSık kullanılanlara öğe ekleyebilir ve daha sonra sık kullanılanlar bölümünden izleyebilirsiniz </p> <h2> Konferans salonu <h2> <p> Arşivlenmiş dersler, web yayınları ve canlı akış videoları izlemek için </p> <h2> Uzmanlar </h2> <p> Uzmanlarımız size yardımcı olabilir, sadece kategori seçin, formu doldurun ve sorunuzu gönderin ve cevapları e-posta ile almalısınız </br> Her kategorideki uzmanlar hakkında daha fazla bilgi edinmek için kategoriyi açın ve sorunuzu yazın </p> <h2> Hesabım </h2> <p> Hesap profilinizi yönetebilir ve parolanızı değiştirebilirsiniz </p> </br> </br> <p> Bu Web Portalının hazırlanması, Sanofi Körfez Ülkeleri tarafından sağlanan sınırsız bir eğitim hibesi ile mümkün kılındı </br> &kopya; 2018 DAR ittifak. Tüm hakları saklıdır. </p>",
  "Help.Text": 'Develop by OTB Life Science & Technology Consultancy FZ-LLC.',
  "Lang.Ar": "العربية",
  "Lang.Eng": "English",
  "Lang.Fr": "Français",
  "Lang.Tr": "Türk",
  "Lang.Ur": "اردو",
  "Library.Author": "Yazar",
  "Library.Explore": "Daha fazlasını keşfet",
  "Library.Title": "Kütüphane & Referanslar",
  "Login.Button": "Giriş",
  "Login.Desc": "Seni tekrar görmek çok güzel.",
  "Login.Email": "E-posta",
  "Login.ForgotPassword": "Şifrenizi mi unuttunuz?",
  "Login.Info": "Kabul ediyorum { terms } & { privacy }",
  "Login.Password": "Şifre",
  "Login.PrivacyPolicy": "Gizlilik Politikası",
  "Login.RememberMe": "Beni Hatırla",
  "Login.SignUp": "Hesabın yok mu? Kaydol.",
  "Login.Success.Desc": "Başarılı",
  "Login.Success.Title": "Başarılı",
  "Login.TermsOfUse": "Kullanım Şartları",
  "Login.Title": "Hesabınızda oturum açın",
  "NewPassword.Button": "Şifre sıfırlama",
  "NewPassword.ConfirmPassword": "Şifreyi onayla",
  "NewPassword.Desc": "Yeni bir şifre oluşturun ve onaylayın",
  "NewPassword.NewPassword": "Yeni Şifre",
  "NewPassword.Title": "Şifrenizi mi unuttunuz?",
  "Notifications.Error": "Hata",
  "Notifications.SomethingWentWrong": "Oops! Bir şeyler ters gitti!\nLütfen daha sonra tekrar deneyin veya bu sorunla karşı karşıyaysanız sistem yöneticisine başvurun",
  "Profile.AdditionalInformation": "ek bilgi",
  "Profile.Button": "Bilgileri Güncelle?",
  "Profile.ChangePassword": "Şifreyi değiştir",
  "Profile.ChangePassword.Button": "Değiştir",
  "Profile.ChangePassword.Title": "Şifreyi değiştir",
  "Profile.ConfirmPassword": "Yeni şifreyi onayla",
  "Profile.Country": "Ülke",
  "Profile.CurrentPassword": "Şimdiki Şifre",
  "Profile.Email": "E-posta",
  "Profile.FullName": "Tam İsim",
  "Profile.Logout": "Çıkış Yap",
  "Profile.MainInformation": "Ana Bilgiler",
  "Profile.NewPassword": "Yeni Şifre",
  "Profile.Password": "Şifre",
  "Profile.Phone": "Telefon",
  "Profile.PlaceOfWork": "İş yeri",
  "Profile.Speciality": "Uzmanlaşma",
  "Profile.Success.Desc": "Başarılı",
  "Profile.Success.Title": "Başarılı",
  "Profile.Title": "Profil",
  "Registration.Button": "Kaydol",
  "Registration.City": "Şehir",
  "Registration.ConfirmPassword": "Şifreyi onayla",
  "Registration.Country": "Ülke",
  "Registration.Desc": "Hesap oluşturmak için lütfen formu doldurunuz",
  "Registration.Email": "E-posta",
  "Registration.FullName": "Tam İsim",
  "Registration.Login": "Hesabınız var mı? Giriş",
  "Registration.Password": "Şifre",
  "Registration.Phone": "İletişim numarası/Telefon *",
  "Registration.PlaceOfWork": "İş yeri",
  "Registration.SignIn": "Hesabın yok mu? Kaydol.",
  "Registration.Speciality": "Uzmanlaşma",
  "Registration.Success.Desc": "Başarılı",
  "Registration.Success.Title": "Başarılı",
  "Registration.Title": "Hesabınızda oturum açın",
  "RiskCalculator.Button": "Riskin sonucunu göster",
  "RiskCalculator.Desc": "Hızlı seçmeden önce diyabetli yaşayan bir kişinin riskini hesaplayın.",
  "RiskCalculator.Result": "Skorun",
  "RiskCalculator.Result.High.Desc": "Yüksek risk kategorisinde olan bireyler hızlı olmamalıdır.\nBu bireyler Ramazan döneminde oruç tutarken komplikasyon gelişme riski yüksektir. Bu bireylerin hızlı olmamasını öneririz. Hala oruç tutmakta ısrar ediyorlarsa, yukarıda ve bu yönergelerin diğer bölümlerinde belirtilen strateji ve önerilerin yanında azami özen ve izleme sağlanmalıdır.",
  "RiskCalculator.Result.High.Title": "yüksek risk",
  "RiskCalculator.Result.Low.Desc": "Düşük risk seviyesindekiler hızlı olmalıdır.\nBu bireyler Ramazan döneminde oruç tutarken ortaya çıkan komplikasyonlar açısından daha düşük bir risk altındadır. Ancak belirtildiği gibi, koşullar risk puanlamasında bir değişikliğe yol açarak değişebilir. Bu nedenle Ramazan öncesinde risk düzeyinin gözden geçirilmesi için risk tabakalaşması yıllık olarak yapılmalıdır.",
  "RiskCalculator.Result.Low.Title": "DÜŞÜK RİSK",
  "RiskCalculator.Result.Medium.Desc": "Orta düzeyde risk seviyesindekiler oruç tutmamaları tavsiye edilir.\nDaha önce de belirtildiği gibi, bu hastaların çoğu yine de oruç tutmayı seçecektir. Bu önemli kişisel karar, HCP'lerle istişarede ilişkili riskleri değerlendirdikten sonra yapılmalıdır. Ayrıca bu riski azaltmak için tekniklerin veya stratejilerin farkında olmaları gerekir. Bireyler oruç tutmayı seçerlerse, o zaman temkinli olmalılar ve herhangi bir sorun ortaya çıkarsa oruç tutmayı bırakmaları gerekir.",
  "RiskCalculator.Result.Medium.Title": "orta risk",
  "RiskCalculator.Title": "Risk Hesaplayıcı",
  "RiskCalculator.TryAgain": "Tekrar dene",
  "RiskInfo.Button": "Şimdi başla",
  "RiskInfo.Desc": "<p> Hızlı kullanarak tercih etmeden önce diyabetli yaşayan bir kişinin riskini hesaplayın <a target='_blank' href='https://www.idf.org/our-activities/education/diabetes-and-ramadan/healthcare-professionals.html'> 2021, IDF-DAR Pratik Kılavuzlarında yayınlanan IDF-DAR risk tabakalaşma algoritması </a> , bölüm 5: Ramazan öncesi diyabetli kişilerin risk tabakalaşması. </p>",
  "RiskInfo.High.Desc": "yüksek risk",
  "RiskInfo.High.Title": "Puan > 6",
  "RiskInfo.List": "<ul> <li> Yeni IDF-DAR risk tabakalaşması, üç risk kategorisini tanımlar ve her kategori için oruç önerisinde önemli rol oynayan birden fazla faktörü içeren bir risk puanı sağlar; örneğin düşük risk, orta risk ve yüksek risk </li>  <li> Puanlama sistemi oruç tutmayı etkileyen farklı faktörler dikkate alınarak tasarlandı. </li> <li> Belirli bir birey için, her bir risk unsuru değerlendirilmeli ve puan toplam alınmalıdır. </li> <li> Ortaya çıkan skorlar, Ramazan döneminde oruç tutmaya çalışan diyabetli bir bireyin genel risk düzeyini belirleyecektir. </li> </ul>",
  "RiskInfo.Low.Desc": "DÜŞÜK RİSK",
  "RiskInfo.Low.Title": "Puan 0'dan 3'e",
  "RiskInfo.Medium.Desc": "ORTA RİSK",
  "RiskInfo.Medium.Title": "Puan 3.5 - 6",
  "RiskInfo.Title": "IDF - DAR Risk Hesaplama:\nSadece Sağlık Sektörü Profesyonelleri İçin",
  Search: "Ara…",
  "SetNewPassword.Success.Desc": "Başarılı",
  "SetNewPassword.Success.Title": "Başarılı",
  "TrainTheTrainer.AdditionalMaterial": "Ek malzeme",
  "TrainTheTrainer.Author": "Yazar",
  "TrainTheTrainer.Back": "Geri",
  "TrainTheTrainer.Congratulation": "Tebrikler",
  "TrainTheTrainer.Explore": "Daha fazlasını keşfet",
  "TrainTheTrainer.Lesson": "Ders",
  "TrainTheTrainer.Modal.Button": "Geri",
  "TrainTheTrainer.Modal.Desc": "Bu bölümü ziyaret etmeye davet edilmediniz",
  "TrainTheTrainer.Modal.Title": "Davet edilmedi",
  "TrainTheTrainer.QuestionsCount": "Soru { current } / { count }",
  "TrainTheTrainer.Quiz": "Yeni sınav!",
  "TrainTheTrainer.Result.Desc": "Sınavı geçtin",
  "TrainTheTrainer.Skip": "Atla",
  "TrainTheTrainer.Title": "Eğitmeni Eğit",
  "Validation.FieldIsRequired": "* Zorunlu alan",
  "Podcasts.folder.FRENCH": "Fransızca",
  "Podcasts.folder.OTHER": "Diğerleri"
} ;
