import React from "react";
import { FormattedMessage } from "react-intl";
import { LeftOutlined } from "@ant-design/icons";

import { urlLocations } from "routes/urlLocations";
import Styled from "./styled";
import { useSelector } from "react-redux";
import { podcastsPlaylistsSelector } from "services/podcasts/podcasts.selectors";
import PlaylistTile from "components/PlaylistTile/PlaylistTile";

const TopPodcasts = () => {
  const podcasts = useSelector(podcastsPlaylistsSelector);
  const topPodcasts = podcasts?.filter((podcast) => !!podcast.featured);
  return (
    <div>
      <Styled.BackButton to={urlLocations.podcasts}>
        <LeftOutlined />
        <span>
          <FormattedMessage id="Podcasts.Back" />
        </span>
      </Styled.BackButton>
      <Styled.Title>
        <FormattedMessage id="Podcasts.TopPodcasts" />
      </Styled.Title>

      <Styled.List>
        {topPodcasts?.map((podcast) => (
          <Styled.Item>
            <PlaylistTile iscarousel={false} playlist={podcast} />
          </Styled.Item>
        ))}
      </Styled.List>
    </div>
  );
};

export default TopPodcasts;
