import styled, {css} from "styled-components";
import React from "react";


const RiskInfo = styled.div`
	width: 100%;
	max-width: 800px;
	margin: 30px auto 30px auto;
`;
const RiskInfoRow = styled.div`
	display: flex;
	max-width: 800px;
	
	margin-bottom: 5px;	
	justify-content: space-between;	
`;

const ButtonsRow = styled.div`
	display: flex;
	justify-content: center;
`;

const RiskInfoColLeft = styled.div`
	display: inline-flex;
	width: 30%;
	//max-width: 300px;
	margin-left: 10px;
	@media (max-width: 767px) {
		width: 100%;
  	}
`;

const RiskInfoColRight = styled.div`
	display: inline-flex;
	width: 64%;	
	@media (max-width: 767px) {
		width: 100%;
  	}
`;

const RiskInfoItem = styled.div`
	display: flex;
	width: 100%;
	
	justify-content: center;
	align-items: center;
	height: 48px;
	font-weight: 700;
    font-size: 15px;
    color: #fff;
	${({bgColor}) => {
		if (bgColor) {
			return css`
		           background: ${bgColor}; 
`
		}
	}}
`;


export default {
	RiskInfoColLeft,
	RiskInfoColRight,
	RiskInfoItem,
	RiskInfoRow,
	RiskInfo,
	ButtonsRow
};
