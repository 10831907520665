import React from "react";

import Styled from "./styled";
import { localizeText } from "utils/lang";
import { FormattedMessage } from "react-intl";
import { CheckOutlined } from "@ant-design/icons";
import { pathBuilder } from "routes/urlLocations";
import { useSelector } from "react-redux";
import { podcastEpisodeProgress } from "services/podcasts/podcasts.selectors";

const PodcastsItem = ({ playlist }) => {
  const { title, image, episodesCount, tags, id, episodesIds } = playlist;
  const progress = useSelector(podcastEpisodeProgress);

  const isFinished =
    episodesIds.length &&
    episodesIds.every((episodeId) => progress[episodeId]?.finished);
  return (
    <Styled.Item to={pathBuilder.podcast(id)}>
      <Styled.Status isFinished={isFinished}>
        <CheckOutlined />
      </Styled.Status>
      <Styled.ItemCover>
        <img src={image.url} alt="" />
      </Styled.ItemCover>
      <Styled.ItemTitle>{localizeText(title)}</Styled.ItemTitle>
      <Styled.ItemsEpisodes>
        <FormattedMessage id="Podcasts.Episodes" /> : {episodesCount}
      </Styled.ItemsEpisodes>
      <Styled.Tags>
        {tags.map((tag) => (
          <Styled.Tag key={tag?.id}>{`#${localizeText(
            tag.titleDefault
          )}`}</Styled.Tag>
        ))}
      </Styled.Tags>
    </Styled.Item>
  );
};

export default PodcastsItem;
