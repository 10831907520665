import {createAction} from "redux-actions";

export const GET_RISK = "GET_RISK";
export const GET_RISK_SUCCESS = "GET_RISK_SUCCESS";
export const GET_RISK_FAILURE = "GET_RISK_FAILURE";

export const SUBMIT_RISK = "SUBMIT_RISK";
export const SUBMIT_RISK_SUCCESS = "SUBMIT_RISK_SUCCESS";
export const SUBMIT_RISK_FAILURE = "SUBMIT_RISK_FAILURE";

export const CLEAR_RESULTS = "CLEAR_RESULTS";

export const submitRisk = createAction(SUBMIT_RISK);
export const clearResults = createAction(CLEAR_RESULTS);
