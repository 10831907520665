import styled from "styled-components";
import LogoutImg from "../../../assets/images/LogOut.svg"

const Profile = styled.div`
	margin-top: 32px;
	background: #FFFFFF;
	border-radius: 8px;
	padding: 48px 15px;
	
		@media (min-width: 767px) {
			padding: 48px 64px;
  		}
`;

const Title = styled.h1`
	font-weight: bold;
	font-size: 32px;
	line-height: 40px;
	padding-bottom: 0;
`;

const ProfileRow = styled.div`
	display: flex;
	margin: 0 -15px;
	@media (max-width: 1023px) {
		 flex-wrap: wrap;
	}
`;

const ButtonsRow = styled.div`
	display: flex;
	margin: 40px -15px 0 -15px;
	align-items: center;
	flex-wrap: wrap;
		@media (min-width: 767px) {
			flex-wrap: nowrap;
  		}
	
`;
const ProfileCol = styled.div`
	max-width: 384px;
	width: 100%;
	margin: 0 15px;
	@media (min-width: 1024px) {
		 width: 48%; 
	}
`;

const Subtitle = styled.div`
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	color: #9A9FAD;
	margin-top: 32px;
	margin-bottom: 16px;
`;

const ChangePassword = styled.div`
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	padding-top: 4px;
	cursor: pointer;
	position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
    text-decoration: none;
	color: var(--blue);
`;

const WrapperField = styled.div`
	position: relative;
	`;
const Logout = styled.div`
	width: 60px;
	height: 20px;
	display: flex;
	background: url(${LogoutImg}) 0 center no-repeat;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: #F23D3D;
	padding: 0 0 0 40px;
	margin-bottom: 20px;
	cursor: pointer;
	@media (min-width: 767px) {
		margin-bottom: 0;
  	}
`;



export default {
	Profile,
	ProfileCol,
	Subtitle,
	ProfileRow,
	Title,
	ButtonsRow,
	Logout,
	ChangePassword,WrapperField
};
