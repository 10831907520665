import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";

import {isModalVisibleSelector} from "services/modals/modals.selectors";
import {setModalStatusAction} from "services/modals/modals.action";
import ModalContent from './styled/StyledModal';
import StyledModal from "components/ModalWrapper/styled/StyledModalContent.js";

const ModalWrapper = ({
  component: Component,
  footer = null,
  modalId,
  ...props
}) => {
  const dispatch = useDispatch();
  const isModalVisible = useSelector(state =>
    isModalVisibleSelector(state, modalId)
  );
  return (
      <ModalContent>

        <StyledModal
            centered
            width="700px"
            destroyOnClose
            visible={isModalVisible}
            footer={footer}
            onCancel={() => dispatch(setModalStatusAction(null))}
            {...props}
        >
          <Component
              {...props}
              setModalStatus={status => dispatch(setModalStatusAction(status))}
          />
        </StyledModal>

      </ModalContent>
  );
};

ModalWrapper.propTypes = {
  modalId: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired
};

export default ModalWrapper;
