import React from "react";

import {localizeText} from "utils/lang";
import {FormattedMessage} from "react-intl";
import Styled from './styled/StyledQuiz';

const QuizOptions = React.memo(({ options, onOptionSelect, onSkip }) => {
  const preparedOptions = options?.map((option, index) => ({
    label: localizeText(option),
    value: index
  }));

  return (
    <>
        <Styled.QuizItem>
            <Styled.AnswersList>
                {preparedOptions?.map(option => (
                    <Styled.AnswerItem
                        onClick={onOptionSelect.bind(null, option.value)}
                        key={option.value}
                        children={option.label}
                        //TODO: add state active
                    />
                ))}
            </Styled.AnswersList>
        </Styled.QuizItem>

        <Styled.QuizFooter>
            <Styled.SkipButton onClick={onSkip}>
                <FormattedMessage id="TrainTheTrainer.Skip" />
            </Styled.SkipButton>
        </Styled.QuizFooter>

    </>
  );
});

export default QuizOptions;
