import styled from "styled-components";
import {Link} from "react-router-dom";
import Icon1 from '../../../assets/images/Consultation.svg';
import Icon2 from '../../../assets/images/Diabetessvg.svg';
import Icon3 from '../../../assets/images/Dietician.svg';
import Icon4 from '../../../assets/images/Religious.svg';

const List = styled.ul`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	margin: 0 -16px;
	padding: 0;
`;
const ItemIconCol = styled.div`
	width: 64px;
	height: 100%;
	border-radius: 8px 0 0 8px;
	position: relative;
	&:before {
		position: relative;
		display: inline-block;
		content: ' ';
		width: 30px;
		height: 30px;
		margin-top: 17px;
		margin-left: 17px;
	}
`;
const ListItem = styled.li`
	display: inline-flex;
	width: 100%;
	max-width: 430px;
	min-height: 112px;
	margin: 16px;	
	background: #FFFFFF;
	border-radius: 8px;
	@media (min-width: 1340px) {
		height: 112px;
		width: 48%;	
	}
	&:nth-child(1){
		${ItemIconCol} {
			background: var(--gradientGreen);
			&:before {

				background: url(${Icon1}) 0 0 no-repeat;
				background-size: contain;
			}
		}
		
	}
	&:nth-child(2){
		${ItemIconCol} {
			background: var(--gradientOrange);
			&:before {
				background: url(${Icon2}) 0 0 no-repeat;
				background-size: contain;
			}
		}
		
	}
	&:nth-child(3){
		${ItemIconCol} {
			background: var(--gradientPurple);
			&:before {
				background: url(${Icon3}) 0 0 no-repeat;
				background-size: contain;
			}
		}
		
	}
	&:nth-child(4){
		${ItemIconCol} {
			background: var(--gradientBlue);
			&:before {
				background: url(${Icon4}) 0 0 no-repeat;
				background-size: contain;
			}
		}
		
	}
`;

const Item = styled(Link)`
	display: flex;
	width: 100%;
	min-height: 112px;
	border-radius: 8px;
	&:hover {
		* {
			text-decoration: none;
		}
		text-decoration: none;
	}
`;
const ItemTitle = styled.h3`
	font-weight: bold;
	font-size: 15px;
	line-height: 18px;
	padding-bottom: 8px;
	padding-top: 20px;
`;

const ItemTxt = styled.p`
	font-weight: normal;
	font-size: 15px;
	line-height: 24px;
`;


const ItemRightCol = styled.div`
	width: calc(100% - 64px);
	padding-left: 20px;
	padding-bottom: 10px;
	padding-right: 8px;
`;

export default {
	ListItem,
	List,
	Item,
	ItemTitle,
	ItemTxt,
	ItemIconCol,
	ItemRightCol
};
