import React from "react";
import {FormattedMessage} from "react-intl";
import Styled from './styled/StyledDashboard';

import {DASHBOARD} from "./Dashboard.constants";
import DiaSVGImg from "assets/images/DiaVoiceSVG.svg";

const Dashboard = React.memo(() => {
  return (
    <Styled.DashboardPage>
      <Styled.PageTitle>
        <FormattedMessage id="Dashboard.Title"/>
      </Styled.PageTitle>
      <Styled.DashboardSubtitle>
        <FormattedMessage id="Dashboard.Desc"/>
      </Styled.DashboardSubtitle>

      <Styled.DashboardList>
        {DASHBOARD.map(item => (
          <Styled.DashboardItem key={item.title}>
            <Styled.LinkItem disabled={!item.path} to={item.path}>
              {item.logo ?
                <Styled.DashboardLogo src={item.logo}/>
                :
                <>
                  <Styled.DashboardIcon></Styled.DashboardIcon>
                  <Styled.TxtBlock>
                    <Styled.ItemTitle>
                      <FormattedMessage id={`Dashboard.${item.title}.Title`}/>
                    </Styled.ItemTitle>
                    <Styled.Txt>
                      <FormattedMessage id={`Dashboard.${item.title}.Desc`}/>
                    </Styled.Txt>
                  </Styled.TxtBlock>
                </>
              }

            </Styled.LinkItem>
          </Styled.DashboardItem>
        ))}
      </Styled.DashboardList>
    </Styled.DashboardPage>
  );
});

export default Dashboard;
