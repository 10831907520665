import React from "react";
import { FormattedMessage } from "react-intl";
import {
  CheckOutlined,
  CaretRightOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import { pathBuilder } from "routes/urlLocations";
import Styled from "./styled";
import { localizeText } from "utils/lang";
import {
  playPlayerStatusAction,
  playSelectedPodcastAction,
} from "services/podcasts/podcasts.actions";
import {
  isPlayerPlayingSelector,
  playablePodcastSelector,
  podcastEpisodeProgress,
} from "services/podcasts/podcasts.selectors";

const PlaylistTile = ({ playlist, iscarousel }) => {
  const { image, title, tags, episodesCount, id, episodesIds } = playlist;
  const dispatch = useDispatch();
  const isPlaying = useSelector(isPlayerPlayingSelector);
  const playablePodcast = useSelector(playablePodcastSelector);
  const progress = useSelector(podcastEpisodeProgress);

  const isFinished =
    episodesIds.length &&
    episodesIds.every((episodeId) => progress[episodeId]?.finished);

  const onActionIconClick = (podcastId) => {
    dispatch(playSelectedPodcastAction(podcastId));

    if (podcastId === playablePodcast?.id) {
      dispatch(playPlayerStatusAction(!isPlaying));
      return;
    }
    if (podcastId !== playablePodcast?.id) {
      dispatch(playPlayerStatusAction(true));
    }
  };

  return (
    <Styled.Playlist iscarousel={iscarousel} to={pathBuilder.podcast(id)}>
      <Styled.Tile iscarousel={iscarousel}>
        <img src={image?.url} alt="" />
        <Styled.Status isFinished={isFinished}>
          <CheckOutlined />
        </Styled.Status>
        <Styled.ActionIcon
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onActionIconClick(id);
          }}
        >
          {isPlaying && playablePodcast?.id === id ? (
            <PauseOutlined />
          ) : (
            <CaretRightOutlined />
          )}
        </Styled.ActionIcon>
      </Styled.Tile>
      <Styled.Title>{localizeText(title)}</Styled.Title>
      <Styled.Tags>
        {tags.map((tag) => (
          <Styled.Tag key={tag?.id}>{`#${localizeText(
            tag.titleDefault
          )}`}</Styled.Tag>
        ))}
      </Styled.Tags>
      <Styled.EpisodesCount>
        <FormattedMessage id="Podcasts.Episodes" /> : {episodesCount}
      </Styled.EpisodesCount>
    </Styled.Playlist>
  );
};

export default PlaylistTile;
