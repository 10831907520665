import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import sortBy from "lodash/sortBy";

import { librarySelector } from "services/library/library.selector";
import { localizeText } from "utils/lang";
import LibraryItem from "components/FavoriteItems/Library.Item";
import Search from "components/Search/Search";
import Styled from "./styled/StyledLibrary";

const Library = React.memo(() => {
  const library = useSelector(librarySelector);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredLibrary = (list) =>
    list.filter((item) =>
      localizeText(item.title).toLowerCase().includes(searchTerm)
    );

  return (
    <Styled.LibraryPage>
      <Search
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value.toLowerCase())}
      />
      <Styled.PageTitle>
        <FormattedMessage id="Library.Title" />
      </Styled.PageTitle>
      <Styled.LibraryList>
        {library?.map(({ id, title, libraryItems }) => (
          <>
            {!!filteredLibrary(libraryItems).length && (
              <Styled.LibraryListItems key={id}>
                <Styled.ListTitle>{localizeText(title)}</Styled.ListTitle>
                <Styled.LibraryListItem>
                  {sortBy(filteredLibrary(libraryItems), "id").map(
                    ({
                      id: itemId,
                      title: itemTitle,
                      thumbnail,
                      author,
                      itemFile,
                    }) => (
                      <LibraryItem
                        key={itemId}
                        title={itemTitle}
                        thumbnail={thumbnail}
                        author={author}
                        itemFile={itemFile}
                        id={itemId}
                      />
                    )
                  )}
                </Styled.LibraryListItem>
              </Styled.LibraryListItems>
            )}
          </>
        ))}
      </Styled.LibraryList>
    </Styled.LibraryPage>
  );
});

export default Library;
