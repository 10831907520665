import * as favoritesActions from "./favorites.actions";
import initialState from "store/initialState";

export default (state = initialState.favorites, action) => {
    switch (action.type) {
        case favoritesActions.GET_FAVORITES_SUCCESS:
        case favoritesActions.ADD_TO_FAVORITES_SUCCESS:
        case favoritesActions.REMOVE_FROM_FAVORITES_SUCCESS:
            return {
                ...state,
                list: action.payload,
            };
        case favoritesActions.GET_FAVORITES_IDS_SUCCESS:
            return {
                ...state,
                ids: action.payload,
            };
        default:
            return state;
    }
};
