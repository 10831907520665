import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { favoritesIdsSelector } from "services/favorites/favorites.selector";
import {
  addToFavoritesAction,
  removeFormFavoritesAction,
} from "services/favorites/favorites.actions";
import Styled from "./styled/StyledFavoriteIcon";

const TYPE_TO_PATH = {
  libraryItems: "library",
  videoItems: "video",
  courses: "course",
};

const FavoriteIcon = React.memo(({ type, id, ...props }) => {
  const dispatch = useDispatch();
  const ids = useSelector(favoritesIdsSelector)?.[type] || [];

  return (
    <>
      {!ids.includes(id) ? (
        <Styled.FavoriteIcon
          {...props}
          onClick={(event) => {
            event.preventDefault();
            dispatch(addToFavoritesAction({ path: TYPE_TO_PATH[type], id }));
          }}
        />
      ) : (
        <Styled.FavoriteIconFiled
          {...props}
          onClick={(event) => {
            event.preventDefault();
            dispatch(
              removeFormFavoritesAction({ path: TYPE_TO_PATH[type], id })
            );
          }}
        />
      )}
    </>
  );
});

export default FavoriteIcon;
