import styled from "styled-components";
import IconUser from "assets/images/Account.svg";
import SettingsIcon from "assets/images/settings.svg";
import LogoutImg from "assets/images/LogOut.svg"
import {Link} from "react-router-dom";
import React from "react";

const UserBlock = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;

  .ant-dropdown-link {
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
    align-items: center;
    width: 100%;
    height: 100%;
    display: inline-flex;
    padding-left: 24px;
    padding-right: 20px;
  }
  .ant-dropdown-open {
    background: var(--lightGray);
    img {
      transform: rotate(-180deg);
    }
  }

  .ant-avatar {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    border: none;
    border-radius: 10px;
  }

  .ant-dropdown-link {
    p {
      display: inline-block;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      padding: 0;
    }
    img {
        position: relative;
    }
    
    
  }

`;

const UserIcon = styled.i`
  width: 22px;
  height: 22px; 
  margin-right: 10px;
  display: inline-block;
  background: url(${IconUser}) 0 0 no-repeat;
  background-size: contain;
  cursor: pointer;
  position: relative;
`;

const SettingIcon = styled.i`
  position: relative;
  width: 16px;
  height: 15px;
  margin-right: 15px;
  display: inline-block;
  background: url(${SettingsIcon}) 0 0 no-repeat;
  background-size: cover;
  cursor: pointer;
  left: -1px;
`;

const UserName = styled.p`
  font-size: 15px;
  line-height: 24px;
  padding: 0;
  margin: 0 11px 0 0;
`;

const ProfileLink = styled(Link)`
  &&& {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: var(--dark);
    display: inline-block;
    &:hover {
      color: var(--dark);
      text-decoration: none;
    }
  }
`;


const LogoutIcon = styled.i`
	width: 20px;
	height: 20px;
	margin-right: 12px;
	display: inline-block;
	background: url(${LogoutImg}) 0 center no-repeat;
	background-size: cover;
`;

const LogoutLink = styled(Link)`
  && {
    font-size: 14px;
    line-height: 20px;
    color: #F23D3D;
    font-weight: 400;    
    
 
    &:hover {
      text-decoration: none;
      color: #F23D3D;
    }
  }
`;


export default {
    UserBlock,
    SettingIcon,
    UserIcon,
    UserName,
    LogoutLink,
    LogoutIcon,
    ProfileLink
};
