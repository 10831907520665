import styled, {css} from "styled-components";
import {Select} from "antd";

const StyledSelect = styled(({ ...props }) => (
	<Select {...props} />
))`

  width: 100%;
  background-color: var(--bgInput);
  height: 48px;
  border-radius: 8px;
  border: none;

	 ${({value}) => {
		if (value!=='""' && typeof(value) !=='undefined') {
			return css`
				        & + label {
					        top: 6px !important;
					        font-size: 9px !important;
					    }
			      `;
		}
	}}}
	 
  &.ant-select-selector .ant-select-selection-search-input {
    height: 48px;
  }
  &.ant-select-disabled {
    background-color: var(--bgInput);
    opacity: 0.9;
  }

  &.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 48px;
    border-radius: 8px;
    background: transparent;
  }
  &.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    color: var(--dark);
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 15px;
    height: 48px;
    border-radius: 8px;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px;
    border-radius: 8px;
    background: transparent;
  }
  &.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 48px;
    color: var(--dark);
    border: none;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid var(--inputBorder);
  }
  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--inputBorder);
  }
  &.ant-select-selector {
    height: 48px;
    border-radius: 8px;
  }
  &:hover {
    .ant-select-selector {
      border: solid 1px inherit;
    }
  }
  .ant-select-selector {
    border: solid 1px var(--inputBorder);
    border-radius: 8px;
    height: 48px;
    color: var(--dark);
    padding-left: 15px;
    font-size: 15px;
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-search-input {
    line-height: 53px !important;
    font-size: 14px !important;
  }
  &.ant-select-single .ant-select-selector .ant-select-selection-item, 
  &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  	line-height: 57px !important;
  }

  .ant-select {
    color: var(--dark);
    font-size: 14px !important;
  }

  &.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color:  ${({greenborderfocus}) => greenborderfocus ? 'var(--inputBorderFocus);' : "var(--gray);"};
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none;
    padding: 0 15px;
  }
  &.ant-select-focused {
    .ant-select-arrow {
    }
  }

  .ant-select-arrow .anticon {
  }

  .ant-select-arrow {
    cursor: pointer !important;
    transition: all 0.1s ease-in;
  }
  &.ant-select-open .ant-select-arrow {
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 56px !important;
    color: var(--dark) !important;
    font-size: 14px;
  }

  &.ant-select-sm {
    height: 32px;
    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 32px;
    }
    &.ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 32px;
    }
    &.ant-select-single .ant-select-selector .ant-select-selection-item,
    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 32px !important;
    }
    &.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
      .ant-select-selector {
      padding: 0 15px;
    }
    .ant-select-arrow {
    }
  }
`;

export default StyledSelect;
