import styled from "styled-components";
import LogoImg from "assets/images/sanofi-new.png";
import ServierImg from "assets/images/servier-logo.png";
import ServierImgNew from "assets/images/servier-logo-new.png";

const Footer = styled.div`
  //position: absolute;
  //top: 100%;
  bottom: 0;
  height: 100px;
  align-items: center;
  left: 0;
  width: 100%;
  display: flex;
  
  @media (max-width: 767px) {
    padding-left: 15px;
    padding-bottom: 65;  
      padding-top: 20px;
  }
  @media (min-width: 768px) {
    padding-left: 56px;
  }
`;

const FooterLogo = styled.div`
  height: 24px;
  width: 115px;
  opacity: 0.5;
  background: url(${props => (props.isServier ? ServierImgNew : LogoImg)}) center center no-repeat;
  background-size: contain;
  
`;

const FooterTxt = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  padding-left: 24px;
  color: var(--gray);
  max-width: 620px;
`;


export default {
    Footer,
    FooterLogo,
    FooterTxt
};
