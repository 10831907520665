import styled from "styled-components";

const List = styled.div`
	display: flex;
	list-style: none;
	padding: 0;
	margin-top: 20px;
	flex-direction: column;	
`;

const Item = styled.div`
	display: flex;
`;

export default {
	List,
	Item
};
