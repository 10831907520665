import React from "react";
import {MODALS_ID} from "constants/modals";

import ModalWrapper from "components/ModalWrapper/ModalWrapper";
import ProfileChangePassword from "./Profile.ChangePassword";

const ProfileModal = React.memo(() => {
  return (
    <ModalWrapper
      modalId={MODALS_ID.CHANGE_PASSWORD_MODAL}
      component={ProfileChangePassword}
      initialValues={{
        currentPassword: null,
        newPassword: null,
        confirmNewPassword: null
      }}
    />
  );
});

export default ProfileModal;
