import {createAction} from "redux-actions";

export const GET_COURSES = "GET_COURSES";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_FAILURE = "GET_COURSES_FAILURE";

export const SUBMIT_ANSWER = "SUBMIT_ANSWER";
export const SUBMIT_ANSWER_SUCCESS = "SUBMIT_ANSWER_SUCCESS";
export const SUBMIT_ANSWER_FAILURE = "SUBMIT_ANSWER_FAILURE";

export const submitAnswerAction = createAction(SUBMIT_ANSWER);
