import { createAction } from "redux-actions";

export const GET_PLAYLISTS = "GET_PLAYLISTS";
export const GET_PLAYLISTS_SUCCESS = "GET_PLAYLISTS_SUCCESS";
export const GET_PLAYLISTS_FAILURE = "GET_PLAYLISTS_FAILURE";

export const GET_EPISODES = "GET_EPISODES";
export const GET_EPISODES_SUCCESS = "GET_EPISODES_SUCCESS";
export const GET_EPISODES_FAILURE = "GET_EPISODES_FAILURE";

export const GET_EPISODE_PROGRESS = "GET_EPISODE_PROGRESS";
export const GET_EPISODE_PROGRESS_SUCCESS = "GET_EPISODE_PROGRESS_SUCCESS";
export const GET_EPISODE_PROGRESS_FAILURE = "GET_EPISODE_PROGRESS_FAILURE";

export const UPDATE_EPISODE_PROGRESS = "UPDATE_EPISODE_PROGRESS";
export const UPDATE_EPISODE_PROGRESS_SUCCESS =
  "UPDATE_EPISODE_PROGRESS_SUCCESS";
export const UPDATE_EPISODE_PROGRESS_FAILURE =
  "UPDATE_EPISODE_PROGRESS_FAILURE";

export const SET_PLAYABLE_EPISODE = "SET_PLAYABLE_EPISODE";
export const SET_PLAYABLE_EPISODE_SUCCESS = "SET_PLAYABLE_EPISODE_SUCCESS";

export const SET_PLAYABLE_PODCAST = "SET_PLAYABLE_PODCAST";

export const GET_PLAYABLE_EPISODE = "GET_PLAYABLE_EPISODE";
export const GET_PLAYABLE_EPISODE_SUCCESS = "GET_PLAYABLE_EPISODE_SUCCESS";
export const GET_PLAYABLE_EPISODE_FAILURE = "GET_PLAYABLE_EPISODE_FAILURE";

export const SET_PLAYER_STATUS = "SET_PLAYER_STATUS";

export const PLAY_SELECTED_PODCAST = "PLAY_SELECTED_PODCAST";

export const SET_PLAYABLE_EPISODE_PROGRESS = "SET_PLAYABLE_PODCAST_PROGRESS";

export const setPlayableEpisodeAction = createAction(SET_PLAYABLE_EPISODE);
export const getPlayableEpisodeAction = createAction(GET_PLAYABLE_EPISODE);
export const setPlayablePodcastAction = createAction(SET_PLAYABLE_PODCAST);
export const setPlayerStatusAction = createAction(SET_PLAYER_STATUS);
export const playSelectedPodcastAction = createAction(PLAY_SELECTED_PODCAST);
export const playPlayerStatusAction = createAction(SET_PLAYER_STATUS);
export const setPlayableEpisodeProgressAction = createAction(
  SET_PLAYABLE_EPISODE_PROGRESS
);
export const updateEpisodeProgressAction = createAction(
  UPDATE_EPISODE_PROGRESS
);
