import React from "react";
import {useIntl} from "react-intl";

const RichHtmlWrapper = ({ id }) => {
  const intl = useIntl();

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: intl.formatMessage({
          id
        })
      }}
    />
  );
};

export default RichHtmlWrapper;
