import * as podcastsActions from "./podcasts.actions";
import initialState from "store/initialState";

export default (state = initialState.podcasts, action) => {
  switch (action.type) {
    case podcastsActions.GET_PLAYLISTS_SUCCESS:
      return {
        ...state,
        playlists: action.payload,
      };
    case podcastsActions.GET_EPISODES_SUCCESS:
      return {
        ...state,
        episodes: action.payload,
      };
    case podcastsActions.GET_EPISODE_PROGRESS_SUCCESS:
    case podcastsActions.UPDATE_EPISODE_PROGRESS_SUCCESS:
      return {
        ...state,
        progress: action.payload,
      };
    case podcastsActions.GET_PLAYABLE_EPISODE_SUCCESS:
    case podcastsActions.SET_PLAYABLE_EPISODE:
      return {
        ...state,
        playableEpisode: action.payload,
      };
    case podcastsActions.SET_PLAYABLE_PODCAST:
      return {
        ...state,
        playablePodcast: action.payload,
      };
    case podcastsActions.SET_PLAYER_STATUS:
      return {
        ...state,
        isPlaying: action.payload,
      };
    case podcastsActions.SET_PLAYABLE_EPISODE_PROGRESS:
      return {
        ...state,
        playableEpisodeProgress: action.payload,
      };
    default:
      return state;
  }
};
