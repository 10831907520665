import { call, put, takeEvery } from "redux-saga/effects";

import * as loginActions from "./login.action";
import { STORAGE_TYPE } from "constants/constants";
import instance from "../root.api";
import { loginRequest } from "./login.api";
import { history, urlLocations } from "routes/urlLocations";
import {
  finishLoaderAction,
  startLoaderAction,
} from "services/loader/loader.action";
import openNotification, { prepareErrorMessages } from "utils/notifications";

function* login({ payload: { identifier, password, rememberMe = false } }) {
  try {
    yield put(startLoaderAction());
    const lowerCaseIdentifier = identifier?.trim().toLowerCase();

    const { response } = yield call(loginRequest, {
      identifier: lowerCaseIdentifier,
      password,
    });

    localStorage.setItem(
      "storageTyp",
      rememberMe ? STORAGE_TYPE.LOCAL_STORAGE : STORAGE_TYPE.SESSION_STORAGE
    );
    const storage = localStorage.getItem("storageTyp");
    window[storage].setItem("jwt", response.data.jwt);
    instance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.jwt}`;

    const redirectPath =
      response.data.user?.userClass === "DIA_VOICE"
        ? urlLocations.podcastsFolders
        : urlLocations.dashboard;

    yield history.push(redirectPath);
    yield put({
      type: loginActions.LOGIN_SUCCESS,
    });
    yield call(openNotification, {
      message: "Login.Success.Title",
      description: "Login.Success.Desc",
      type: "success",
    });
  } catch ({ response, data }) {
    yield put({ type: loginActions.LOGIN_FAILURE });
    yield call(openNotification, {
      message: "Notifications.Error",
      description: prepareErrorMessages(response.data),
      type: "error",
    });
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* loginSaga() {
  yield takeEvery(loginActions.LOGIN, login);
}
