import React from "react";

import RichHtmlWrapper from "components/RichHTMLWrapper/RichHTMLWrapper";
import Styled from "containers/styled/StyledTextPage";

const Help = React.memo(() => {
    return <Styled.PageTextContainer><RichHtmlWrapper id="Help.Content"/></Styled.PageTextContainer>;
});

export default Help;
