import React from "react";
import {FormattedMessage} from "react-intl";
import Styled from "./styled/StyledFooterAuth";

const AuthFooter  = ({type}) => (
	<Styled.Footer type={type}>

		<Styled.FooterTxt>
			<FormattedMessage id="Footer.Desc" />
		</Styled.FooterTxt>

		<Styled.FooterLogo/>

	</Styled.Footer>
);

export default AuthFooter;