import styled from "styled-components";
import StyledButton from '../../../components/Button/styled/StyledButton'

const ForgotContent = styled.div`
	max-width: 448px;
	min-height: 300px;
	background: #FFFFFF;
	border-radius: 8px;
	margin: 0 auto;
	padding: 32px 40px;
	position: relative;
	top: 35px;
	z-index: 2;
`;

const AuthButton = styled(StyledButton)`
	margin-bottom: 24px;
	margin-top: 24px !important;
`;


export default {
	ForgotContent,
	AuthButton
};
