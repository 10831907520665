import styled from "styled-components";
import {Link} from "react-router-dom";

const PageTitle = styled.h1`
	padding-bottom: 16px;
	font-size: 32px;
	line-height: 40px;
`;

const SubTitle = styled.h2`
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
`;


const BackBtn = styled(Link)`
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	color: var(--dark);
	display: inline-block;
	padding-left: 25px;
	margin-bottom: 20px;
	&:hover,
	&:active {
		color: var(--dark);
		text-decoration: none;
	}
	
	position: relative;
	
	&:before {
		content:'';
		position: relative;
		top: -2px;
		left: -18px;
		border: solid var(--gray);
		border-width: 0 1px 1px 0;
		display: inline-block;
		padding: 4px;
		transform: rotate(135deg);	
		cursor: pointer;
	}
`;

const PageBgContainer = styled.div`
	background: #FFFFFF;
	box-shadow: 0px 5px 10px rgba(127, 141, 163, 0.1);
	border-radius: 8px;
	padding: 46px 64px 40px 64px;
	
	@media (max-width: 1023px) {
		 padding: 46px 15px 40px 15px;
	}
`;

export default {
	PageTitle,
	SubTitle,
	BackBtn,
	PageBgContainer
};
