import {FAVORITES_TYPES} from "constants/favorites";

export const TABS = [
  {
    title: "Auditorium",
    type: FAVORITES_TYPES.VIDEO,
  },
  {
    title: "Library",
    type: FAVORITES_TYPES.LIBRARY,
  },
  {
    title: "TrainTheTrainer",
    type: FAVORITES_TYPES.COURSES,
  },
];
