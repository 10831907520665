import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  padding: 0 40px;

  @media (max-width: 767px) {
    padding: 0;
  }
`;

const FormCol50 = styled.div`
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;

  @media (min-width: 900px) {
    width: 368px;
  }
`;

const FormRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 900px) {
    flex-wrap: nowrap;
  }
`;

const Terms = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;
  top: 8px;
`;

const ButtonRow = styled.div`
  padding-top: 10px;
  padding-bottom: 22px;
  text-align: center;
`;

const Links = styled.a`
  padding-top: 24px;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: var(--blue);

  &:hover {
    color: var(--blue);
  }
  &:focus {
    color: var(--blue);
  }
  &[disabled] {
    color: var(--blue);
    opacity: 0.8;
  }
`;

export default {
  Wrapper,
  FormCol50,
  FormRow,
  Links,
  Terms,
  ButtonRow,
};
