export const EXPERTS = [
  {
    type: "diabetesConsultant",
    dataKey: "DiabetesConsultant",
  },
  {
    type: "diabetesNurseEducator",
    dataKey: "DiabetesNurseEducator",
  },
  {
    type: "dietitian",
    dataKey: "Dietician",
  },
  {
    type: "religiousScholar",
    dataKey: "ReligiousScholar",
  },
];
