import React from "react";
import {useSelector} from "react-redux";

import {profileSelector} from "services/profile/profile.selectors";
import LangSwitcher from "components/LangSwitcher/LangSwitcher";
import {pathBuilder} from "routes/urlLocations";
import {FAVORITES_TYPES} from "constants/favorites";
import Styled from "./styled/StyledHeader";
import UserBlock from "layouts/AuthorizedLayout/UserBlock/UserBlock";

const Header = React.memo(({visibilityAside, onChangeVisibility}) => {
    const {fullName, username} = useSelector(profileSelector);
    return (
        <Styled.Header>
            <Styled.HeaderContainer>
                <Styled.Burger onClick={() => onChangeVisibility(visibilityAside)}/>
                <LangSwitcher/>
                <Styled.Favorites to={pathBuilder.favorites(FAVORITES_TYPES.VIDEO)}>
                    <Styled.FavoritesIcon/>
                </Styled.Favorites>
                <Styled.UserBlock>
                    <UserBlock fullName={fullName} username={username}/>
                </Styled.UserBlock>
            </Styled.HeaderContainer>

        </Styled.Header>
    );
});

export default Header;
