import styled, {css} from "styled-components";
import {Input} from "antd";
import StyledLabel from "components/ControlWrapper/styled/StyledLabel";

const StyledTextarea = styled(Input.TextArea)`
  && {
	    background-color: var(--bgInput);
	    position: relative;
	    padding: 20px 14px 9px 14px;
	    color: var(--dark);
	    min-height: 150px;
	    max-height: 200px;
	    border-radius: 8px;
	    border: 1px solid var(--inputBorder);
	    font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
	    font-stretch: normal;
	    letter-spacing: normal;
	    label {
	        transition: all 0.2s ease-in;
	    }
	        ${StyledLabel} {
				    	top: 6px !important;
				        font-size: 10px !important;
				    }
	    
	      ${(props) => {
			if (!!props.value) {
				return css`
			        & + label {
				        top: 6px !important;
				        font-size: 10px !important;
				    }
				
				    label {
				        top: 6px !important;
				        font-size: 10px !important;
				    }
		      `;
			}
		}}}

	    &:focus,
	    &:active {
	      border: 1px solid var(--gray);
	      & + label {
	        top: 6px !important;
	        font-size: 9px !important;
	      }
	    }
	    &:hover {
	      border: 1px solid var(--gray);
	    }
	    &.ant-input-focused {
	      border: 1px solid var(--gray);
	    }
	
	    &[disabled] {
	      border: 1px solid var(--inputBorder);
	      opacity: 0.9;
	    }
	    .ant-input-prefix {
	      width: 22px;
	      height: 22px;
	      display: flex;
	      justify-content: center;
	      align-items: center;
	      margin-right: 8px;
	    }
	
	    &.ant-input-sm {
	      height: 32px;
	      padding: 8px 16px;
	    }   
	}
`;


export default StyledTextarea;
