import arDict from "locales/ar";
import enDict from "locales/en";
import frDict from "locales/fr";
import trDict from "locales/tr";
import urDict from "locales/ur";

export const APPDictionary = {
  ar: arDict,
  en: enDict,
  fr: frDict,
  tr: trDict,
  ur: urDict
};
