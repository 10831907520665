import styled from "styled-components";

const StyledError = styled.div`
  font-size: 9px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--red);
  padding-top: 3px;
  padding-left: 2px;
  font-weight: 400;
  position: absolute;
  left: 0;
`;
export default StyledError;
