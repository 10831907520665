import React from "react";
import {reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";

import Button from "components/Button/Button";
import TextareaField from "components/TextareaField/TextareaField";
import InputField from "components/InputField/InputField";
import {questionExpertAction} from "services/expert/expert.actions";
import {requiredFieldsValidate} from "utils/validation";
import Styled from './styled/Expert';

const requiredFields = ["email", "subject", "question"];

const ExpertForm = React.memo(({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
        <Styled.FormRow>
            <Styled.FormCol50>
                <InputField
                    name="email"
                    label="Expert.Email"
                />
            </Styled.FormCol50>
            <Styled.FormCol50>
                <InputField
                    name="subject"
                    label="Expert.Subject"
                />
            </Styled.FormCol50>
        </Styled.FormRow>

            <Styled.FormRow>
                <Styled.FormCol100>
                    <TextareaField
                        name="question"
                        label="Expert.Question"
                    />
                </Styled.FormCol100>
            </Styled.FormRow>
            <Styled.FormRow>
                <Styled.FormCol100>
                    <Button htmlType="submit">
                        <FormattedMessage id="Expert.Send" />
                    </Button>
                </Styled.FormCol100>
            </Styled.FormRow>

    </form>
  );
});

export default reduxForm({
  form: "expertForm",
  validate: v => requiredFieldsValidate(v, requiredFields),
  onSubmit: (value, dispatch, { section }) =>
    dispatch(questionExpertAction({ ...value, section }))
})(ExpertForm);
