import styled, { css } from "styled-components";

const Layout = styled.div`
  position: relative;
  background: var(--bg);
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
`;

const MainContent = styled.div`
  width: 100%;
  position: relative;
  min-height: 750px;
  height: 100vh;
  overflow-y: scroll;
  ${({ withPlayer }) =>
    withPlayer &&
    css`
      padding-bottom: 90px;
      @media (max-width: 768px) {
        padding-bottom: 170px;
      }
    `};

  @media (min-width: 768px) {
    width: calc(100% - 320px);
  }
`;

const PageContent = styled.div`
  padding-left: 15px;
  width: 100%;
  max-width: 928px;
  min-height: 100vh;
  position: relative;
  padding-top: 127px;

  @media (min-width: 768px) {
    padding-left: 56px;
    padding-right: 15px;
  }

  @media (max-width: 1339px) {
    padding-right: 15px;
  }
  @media (min-width: 1341px) {
    min-width: 928px;
    box-sizing: content-box;
  }
`;

// const Title = styled.h1`
//   font-family: var(--fontProximaBold);
//   font-size: 40px;
//   color: #fff;
//   padding-bottom: 10px;
//   text-align: center;
// `;
// const Subtitle = styled.h2`
//   font-family: var(--fontProximaRegular);
//   font-size: 16px;
//   line-height: 24px;
//   color: #3161ac;
//   padding-bottom: 10px;
//   text-align: center;
//   margin-bottom: 42px;
// `;

export default {
  Layout,
  MainContent,
  PageContent,
};
