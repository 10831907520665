import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import useMedia from 'use-media';
import PlaylistTile from "components/PlaylistTile/PlaylistTile";
import Styled from "containers/Podcasts/styled";
import { urlLocations } from "routes/urlLocations";

const PodcastsTopPodcasts = ({ topPodcasts }) => {
  const [currentPosition, setPosition] = useState(0);
  const isMobile = useMedia({minWidth: '320px'});
  const isTablet = useMedia({minWidth: '768px'});
  const isTabletLarge = useMedia({minWidth: '1000px'});
  const STEP = (isMobile && !isTabletLarge) ? 2 : 3 ;

  return (
    <Styled.TopEpisodes>
      <Styled.PlaylistsHeader>
        <Styled.PlaylistsCaption>
          <FormattedMessage id="Podcasts.TopPodcasts" />
        </Styled.PlaylistsCaption>
        <Styled.PlaylistsViewAll to={urlLocations.topPodcasts}>
          <FormattedMessage id="Podcasts.ViewAll" />
        </Styled.PlaylistsViewAll>
      </Styled.PlaylistsHeader>

        <Styled.WrapperCarousel>
          <Styled.Carousel>
            <Styled.IconPrev
              style={{
                ...(currentPosition === 0 && { visibility: "hidden" }),
              }}
              onClick={() => setPosition(currentPosition - STEP)}
            />
            {
                  topPodcasts?.slice(currentPosition, currentPosition + STEP)
                      ?.map((playlist) => (
                          <PlaylistTile iscarousel={true} key={playlist?.id} playlist={playlist} />
                      ))
            }
            <Styled.IconNext
              style={{
                ...(currentPosition + STEP >= topPodcasts?.length && {
                  visibility: "hidden",
                }),
              }}
              onClick={() => setPosition(currentPosition + STEP)}
            />
          </Styled.Carousel>
        </Styled.WrapperCarousel>
    </Styled.TopEpisodes>
  );
};

export default PodcastsTopPodcasts;
